import { Component, OnDestroy, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { WebsiteConfigService } from '../../services/website-config.service';
import { StoreLocatorPageService } from 'src/app/modules/pages/store-locator-page/store-locator-page.service';
import { CmsService } from 'src/app/modules/cms/services/cms.service';
import {ProjectService} from '../../services/project.service';
import { MobileService } from '../../services/mobile.service';

import {ServicesProject} from "../../models/services-project.enum";
import {Networks} from "../../models/networks.enum";

import {Agenzia} from "../../../pages/store-locator-page/interfaces/agenzia.interface";
import Swal from 'sweetalert2';
import { textPopups } from 'src/app/enums/text-popups';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss']
})
export class FooterBarComponent implements OnInit, OnDestroy {

  agencyData: any = null;
  hasMultipleStores: boolean = false;
  vacanzeAssicurateImgUrl: string = '';
  hasVacanzeAssicurate: boolean = false;
  isWTStore: boolean = false;
  isWTShop: boolean = false;
  isWtgNetworkSite: boolean = false; // sito network WTG
  isIstituzionale: boolean = false;
  showGovernance: boolean = false;
  showOrganization: boolean = false;

  config = {
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  };
  index: number;

  brand: any;
  storeList: Agenzia[];
  networks = Networks;
  isNetworkSite: boolean = false;
  isMobile$: Observable<boolean>;

  hasExtraFooter: boolean = false;
  extraFooter$: Observable<string>;
  extraFooterTextSanitazer: any;

  private unsubscribe$: Subject<any> = new Subject();

  constructor(
    private _projectService: ProjectService,
    private _websiteConfigService: WebsiteConfigService,
    private _storeLocatorService: StoreLocatorPageService,
    private _cmsService: CmsService,
    private sanitizer: DomSanitizer,
    private _mobileService: MobileService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isMobile$ = this._mobileService.isMobile();
    this.extraFooter$ = this._websiteConfigService.getExtraFooter();
    this.extraFooter$.subscribe((data) => {

      if(data) {
        // necessario il sanitizer affinchè lo stile inline venga visualizzato nel dom ed eseguito
        this.extraFooterTextSanitazer = this.sanitizer.bypassSecurityTrustHtml(data)
        this.hasExtraFooter = true
      }
    });

    this._storeLocatorService.storesList$.pipe(takeUntil(this.unsubscribe$)).subscribe(storeList => {
      if(!storeList?.length) return;
      this.hasMultipleStores = storeList?.length > 1;
      const agency = storeList.find(a => (a.isMaster && a.isAgencySite) || (!a.isMaster && a.isAgencySite));

      // se l'agenzia principale è stata trovata, procedo a collegare i dati in view
      if (agency) {

        if(textPopups[agency.id] && this.router.url === '/'){
          Swal.fire({
            title: "Attenzione",
            icon: "info" ,
            text: textPopups[agency.id],
            confirmButtonColor: "#00528c",
            iconColor: "#4ea4d7",
          })
        }

        const { denomination, businessName, address, city, province, piva, email1, email2, tel1, tel2, fax, whatsapp, facebook, twitter, youtube, instagram, skype } = agency;
        this.agencyData = {
          id: agency.id,
          denomination,
          businessName,
          pIva: piva, // , {{agency.city.trim()}}{{(agency.province?.trim()) ? ' (' + agency.province.trim() + ')' : ''}}
          address: ((address.trim() && city.trim()) || province.trim()) ? `${address}, ${city}${(province.trim()) ? (', ' + province) : ''}` : '',
          contacts: {
            phone: tel1?.length > 2 ? tel1 : null,
            phone2: tel2?.length > 2 ? tel2 : null,
            mail: email1?.length > 2 ? email1 : null,
            mail2: email2?.length > 2 ? email2 : null,
            fax: fax?.length > 2 ? fax : null
          },
          social: {
            whatsapp: whatsapp?.length > 2 ? whatsapp.replace(/\s/g, '') : null,
            facebook: facebook?.length > 2 ? facebook : null,
            twitter: twitter?.length > 2 ? twitter : null,
            youtube: youtube?.length > 2 ? youtube : null,
            instagram: instagram?.length > 2 ? instagram : null,
            skype: skype?.length > 2 ? this.sanitizer.bypassSecurityTrustUrl('skype:' + skype + '?chat') : null,
          }
        }

        //  Se l'agenzia non ha nemmeno un profilo social l'intero oggetto social sarà impostato a null
        if(!this.agencyData.social.whatsapp && !this.agencyData.social.facebook && !this.agencyData.social.twitter && !this.agencyData.social.youtube && !this.agencyData.social.instagram && !this.agencyData.social.skype) {
          this.agencyData.social = null;
        }
      } else {
        // altrimenti, collego ugualmente i dati in view ma solo alcuni e recuperati dal "profile"
        this._projectService.getProfile().toPromise()
            .then(
                (profile) => {


                  if (!profile) {
                    throw 'Errore durante il recupero delle info profilo agenzia';
                  }

                  this.agencyData = {
                    id: profile.id,
                    denomination: profile.denominazione,
                    businessName: profile.ragione_sociale,
                    pIva: profile.piva,
                    address: ((profile.indirizzo?.length > 2 && profile.citta?.length > 2) || profile.provincia?.length > 2) ? `${profile.indirizzo}, ${profile.citta}${(profile.provincia?.length > 2) ? (', ' + profile.provincia) : ''}` : '',
                    contacts: {
                      phone: profile.tel_1?.length > 2 ? profile.tel_1 : null,
                      mail: profile.email_1?.length > 2 ? profile.email_1 : null,
                      fax: profile.fax?.length > 2 ? profile.fax : null
                    },
                    social: {
                      whatsapp: profile.whatsapp?.length > 2 ? profile.whatsapp : null,
                      facebook: profile.facebook?.length > 2 ? profile.facebook : null,
                      twitter: profile.twitter?.length > 2 ? profile.twitter : null,
                      youtube: profile.youtube?.length > 2 ? profile.youtube : null,
                      instagram: profile.instagram?.length > 2 ? profile.instagram : null,
                      skype: profile.skype?.length > 2 ? this.sanitizer.bypassSecurityTrustUrl('skype:' + profile.skype + '?chat') : null
                    }
                }

                  //  Se l'agenzia non ha nemmeno un profilo social l'intero oggetto social sarà impostato a null
                  if(!this.agencyData.social.whatsapp && !this.agencyData.social.facebook && !this.agencyData.social.twitter && !this.agencyData.social.youtube && !this.agencyData.social.instagram && !this.agencyData.social.skype) {
                    this.agencyData.social = null;
                  }
                },
                (error) => console.error(error)
            );
      }

      // ordino gli stores posizionando per prima le sedi/e principali/e
      this.storeList = (storeList || []).sort((a, b) => {
        if (a.isMaster && a.isAgencySite) {
          return Number(b.isMaster) - Number(a.isMaster);
        } else if (!a.isMaster && a.isAgencySite) {
          return Number(b.isAgencySite) - Number(a.isAgencySite);
        }
      });
    });

    this._cmsService.siteUrl$.pipe(takeUntil(this.unsubscribe$)).subscribe(siteUrl => {
      if (siteUrl) {
        if(siteUrl.includes('geotn.it') || siteUrl.includes('wtstore.it')
          || siteUrl.includes('welcometravelshop.it') || siteUrl.includes('vacanzewelcometravel.it')
        ) {
          this.isIstituzionale = true;
        }
        if (siteUrl.includes('geotn.it')) {
          this.isNetworkSite = true;
        }
        if (siteUrl.includes('wtstore.it')) {
          this.isWTStore = true;
        }
        if (siteUrl.includes('welcometravelshop.it')) {
          this.isWTShop = true;
        }
        if (siteUrl.includes('vacanzewelcometravel.it')) {
          this.isWtgNetworkSite = true;
        }
      }
    });

    this._cmsService.brandConfig$.pipe(takeUntil(this.unsubscribe$)).subscribe(brand => {
      if (brand) {
        this.brand = brand;

        if (brand.networkId === this.networks.WTG) {
          this.vacanzeAssicurateImgUrl = './assets/images/vacanze_assicurate_wtg.png';
        } else if (brand.networkId === this.networks.GEO) {
          this.vacanzeAssicurateImgUrl = './assets/images/vacanze_assicurate_geo.png';
        }
      }
    });

    this.initServicesFromProject();


  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public sanitize(url: string) : any{
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  private initServicesFromProject(): void {
    this._projectService.getServicesFromProject()
        .toPromise()
        .then((services) => {
          if (!services) {
            throw 'Nessun servizio sito trovato';
          }

          this.hasVacanzeAssicurate = !!services.find((service) => service.service_code === ServicesProject.GARANZIA_VIAGGI);
        })
        .catch(err => console.info(err));
  }

  explodeGovernance() {
    this.showOrganization = false;
    this.showGovernance = !this.showGovernance;
  }

}
