import {Component, OnInit, OnDestroy} from '@angular/core';
import {ProjectService} from '../../services/project.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {IRemoteProfile} from '../../interfaces/iremote-profile';
import { takeUntil, map } from 'rxjs/operators';
import { CmsService } from 'src/app/modules/cms/services/cms.service';
import {StoreLocatorPageService} from "../../../pages/store-locator-page/store-locator-page.service";
import {Agenzia} from "../../../pages/store-locator-page/interfaces/agenzia.interface";
import {Networks} from "../../models/networks.enum";
import { MobileService } from '../../services/mobile.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {

  profile$: Observable<IRemoteProfile>;
  showDevPanel = false;
  isPanelVisibile = false;
  isNetworkSite = false;
  isWTStore = false;
  isWTShop = false;
  isWtgNetworkSite = false; // sito network WTG

  public brand: any;
  public storeList: Agenzia[];
  public networks = Networks;
  public isMobile$: Observable<boolean>;

  private unsubscribe$ = new Subject<any>();
  private brandConfigSubscription: Subscription;

  storesSubscription : Subscription;
  hasMultipleStores : boolean = false;

  config = {
    direction: 'vertical',
    freeMode: true,
    slidesPerView: 1,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  };
  index;


  //#region test sites DEV ONLY
  public testSites = [
    { label: "GEO Istituzionale", domain: "www.geotn.it", isEnabled: true, isWidget: false, isWtg: false},
    { label: "GEO Advanced - agenzia singola", domain: "www.nostopviaggi.it", isEnabled: true, isWidget: false, isWtg: false},
    { label: "GEO Advanced - multiagenzia", domain: "www.istanteviaggi.it", isEnabled: true, isWidget: false, isWtg: false},
    { label: "GEO Basic - agenzia singola", domain: "www.katiusciaviaggi.com", isEnabled: true, isWidget: false, isWtg: false},
    { label: "GEO Widget Advanced", domain: "www.pansepol.it", isEnabled: true, isWidget: true, isWtg: false},
    { label: "WTG Istituzionale", domain: "www.vacanzewelcometravel.it", isEnabled: true, isWidget: false, isWtg: true},
    { label: "WTG Advanced - agenzia singola", domain: "www.vomerotravel.it", isEnabled: true, isWidget: false, isWtg: true},
    { label: "WTG Advanced - multiagenzia", domain: "www.etlitn.it", isEnabled: true, isWidget: false, isWtg: true},
    { label: "WT Store", domain: "www.wtstore.it", isEnabled: true, isWidget: false, isWtg: true},
    { label: "WT Shop", domain: "www.welcometravelshop.it", isEnabled: true, isWidget: false, isWtg: true},
    { label: "WTG Widget Advanced", domain: "www.onesatravel.it", isEnabled: true, isWidget: true, isWtg: true}
  ]
  //#endregion

  constructor(
    private _projectService: ProjectService,
    private _cmsService: CmsService,
    private _storeLocatorService: StoreLocatorPageService,
    private _mobileService: MobileService,
    private sanitizer:DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.isMobile$ = this._mobileService.isMobile();
    this.profile$ = this._projectService.getProfile().pipe(
      map((profile) => {
        profile.whatsapp = profile.whatsapp?.trim()?.replace(/\s/g, '');
        return profile;
      }),
    );

    this._cmsService.showDevPanel$.pipe(takeUntil(this.unsubscribe$)).subscribe(devPanel => {
      if (devPanel) {
        this.showDevPanel = devPanel;
      }
    });
    this._cmsService.siteUrl$.pipe(takeUntil(this.unsubscribe$)).subscribe(siteUrl => {
      if (siteUrl) {
        if (siteUrl.includes('geotn.it')) {
          this.isNetworkSite = true;
        }
        if (siteUrl.includes('wtstore.it')) {
          this.isWTStore = true;
        }
        if (siteUrl.includes('welcometravelshop.it')) {
          this.isWTShop = true;
        }
        if (siteUrl.includes('vacanzewelcometravel.it')) {
          this.isWtgNetworkSite = true;
        }
      }
    });

    this.storesSubscription = this._storeLocatorService.storesList$.subscribe(storeList => {
      this.hasMultipleStores = storeList?.length > 1;

      // ordino gli stores posizionando per prima le sedi/e principali/e
      this.storeList = (storeList || []).sort((a, b) => {
        if (a.isMaster && a.isAgencySite) {
          return Number(b.isMaster) - Number(a.isMaster);
        } else if (!a.isMaster && a.isAgencySite) {
          return Number(b.isAgencySite) - Number(a.isAgencySite);
        }
      });
    });

    this.brandConfigSubscription = this._cmsService.brandConfig$.subscribe(brand => {
      if (brand) this.brand = brand;
    });
  }

  ngOnDestroy(): void {
    this.brandConfigSubscription?.unsubscribe();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  public sanitize(url: string) : any{
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public showTestSite(domain: string, isWidget: boolean = false, isWtg: boolean = false): void {
    try {
      if (domain && isWidget) {
        // se c'è il domain e si tratta di un widget, imposto il domain del sito che conterrà un possibile widget ed effettuo un redirect all'html di test widget dedicato
        localStorage.setItem('oto-fe-testingwebsite', domain);
        window.open(`${window.location.href}${(isWtg) ? '/widgetWTG.html' : '/widgetGEO.html'}`);
      } else if (!domain && isWidget) {
        // se non c'è il domain ma si tratta di un widget, apro solo una nuova tab del browser che punta all'html di widget generico di test (test sulla base di un domain già impostato in precedenza)
        window.open(`${window.location.href}/widget.html`);
      } else if (domain && !isWidget) {
        // se c'è il domain e non si tratta di un widget, imposto il domain del sito nel localStorage ed effettuo il reload della pagina (sito wtg/geo)
        localStorage.setItem('oto-fe-testingwebsite', domain);
        window.location.reload();
      }
    } catch (e) {
      console.error('Errore durante recupero item su localStorage. Possibile utilizzo della navigazione anonima oppure cookie e dati dei siti bloccati (vedere impostazioni del browser).');
    }
  }

}
