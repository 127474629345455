import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'adaptCruiseDate'
})
export class AdaptCruiseDatePipe implements PipeTransform {

  constructor() {
  }

  transform(value: string[]): any[] {
    return value ? value.map(e => {
      const date = dayjs(e, 'MM-YYYY');
      return {
        date,
        label: date.format('MMMM YYYY'),
        dateString: e
      };
    }) : value;
  }

}
