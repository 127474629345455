export default function minBy<T>(inputArray: T[], predicate: ((value: T) => any) | string | number): T {
  if (!Array.isArray(inputArray) || inputArray.length < 1) {
    return null;
  }

  const cb = typeof predicate === 'function' ? predicate : (element) => element[predicate];

  const minByObject = inputArray
    .filter(item => item)
    .reduce((acc, item) => {
      const itemCB = cb(item);
      const accCB = cb(acc);
      return itemCB > accCB ? acc : item;
    }, inputArray[0]);

  return minByObject;
}
