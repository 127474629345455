import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'experience-detail',
  templateUrl: './experience-detail.component.html',
  styleUrls: ['./experience-detail.component.scss']
})
export class ExperienceDetailComponent implements OnInit {
  @Input() dataSrc;

  constructor(
    private _modalService: ModalService
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this._modalService.closeActiveModal();
  }

}
