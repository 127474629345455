import { Injectable, NgZone } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Params,
  Router,
  RouterEvent
} from '@angular/router';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, mergeMap, shareReplay } from 'rxjs/operators';
import { WidgetService } from './widget.service';

@Injectable({
  providedIn: 'root'
})

export class PostMessageService {
  private isInitialized = false;
  private website$ = new ReplaySubject<string>(1);
  private isB2B$ = new ReplaySubject<boolean>(1);
  private isStoreLocatorWidget$ = new ReplaySubject<boolean>(1);
  private api$ = new ReplaySubject<string>(1);
  private href$ = new ReplaySubject<string>(1);
  private origin$ = new ReplaySubject<string>(1);
  private token$ = new ReplaySubject<string>(1);
  private merlinxToken$ = new ReplaySubject<string>(1);

  constructor(private _widgetService: WidgetService,
              private _ngZone: NgZone,
              private _router: Router) {
    this._widgetService.isWidget().subscribe(e => {
      if (e) {
        this.initService();
      }
    });
  }

  public isB2B(): Observable<boolean> {
    return this._widgetService.isWidget().pipe(
      mergeMap((isWidget) => (isWidget) ? this.isB2B$ : of(false)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  public isStoreLocatorWidget(): Observable<boolean> {
    return this._widgetService.isWidget().pipe(
        mergeMap((isWidget) => (isWidget) ? this.isStoreLocatorWidget$ : of(false)),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
    );
  }

  public getApi(): Observable<string> {
    return this.api$;
  }

  public getHref(): Observable<string> {
    this.sendMessage({href: true});
    return this.href$;
  }

  public redirect(url: string): void {
    this.sendMessage({redirect: url});
  }

  public getWebsite(): Observable<string> {
    this.sendMessage({website: true});
    return this.website$;
  }

  public getMerlinxToken(): Observable<string> {
    this.sendMessage({ merlinxToken: true });
    return this.merlinxToken$;
  }

  public getOrigin(): Observable<string> {
    this.sendMessage({origin: true});
    return this.origin$;
  }

  public getQueryParam(queryParam: string): Promise<string> {
    this.sendMessage({queryParam});
    return this.token$.toPromise();
  }
  
  public scrollToElementPos(elementPos: number): void {
    this.sendMessage({scrollToElementPos: elementPos});
  }

  public sendHeight(height: number): void {
    this.sendMessage({height});
  }

  private initService(): void {
    if (!this.isInitialized) {
      this.isInitialized = true;
      this.initPM();
      this.initResizer();
      this.initNavigator();
    }
  }

  private sendMessage(message: any): void {
    parent.postMessage(JSON.stringify(message), '*');
  }

  private sendHash(hash: string): void {
    this.sendMessage({hash});
  }

  private initResizer(): void {
    const targetNode = document.body;
    let bodyHeight = document.body.scrollHeight;
    const config = {attributes: true, childList: true, subtree: true};
    const callback = (mutationsList?) => {
      if (bodyHeight !== document.body.scrollHeight) {
        bodyHeight = document.body.scrollHeight;
        this.sendHeight(bodyHeight);
      }
    };
    this._ngZone.runOutsideAngular(() => {
      const observer = new MutationObserver(callback);
      observer.observe(targetNode, config);
    });
    window.addEventListener('resize', callback, false);
  }

  private initNavigator(): void {
    this._router.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          break;
        }
        case event instanceof NavigationEnd: {
          this.sendHash(event.url);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  private initPM(): void {
    // FIXME: "isB2B$" da rimuovere durante la fase di pulizia (al momento viene forzato a false dato che non esiste piu il b2b in questo progetto)
    this.isB2B$.next(false);
    this.isB2B$.complete();
    
    const listener = (event: MessageEvent) => {
      let data;
      try {
        data = JSON.parse(event.data);
      } catch (err) {
        // donothing
      }
      if (data) {
        if (data.hasOwnProperty('hash')) {
          this.isStoreLocatorWidget().toPromise()
            .then((isStoreLocatorWidget) => {
              if (!isStoreLocatorWidget) {
                // se non sono nello store locator widget, allora provvedo a navigare verso l'url impostato nell'hash
                this.navigateTo(data.hash); 
              }
            });
        }
        else if (data.hasOwnProperty('href')) {
          this.href$.next(data.href);
          this.href$.complete();
        }
        else if (data.hasOwnProperty('website')) {
          this.website$.next(data.website);
          this.website$.complete();
        }
        else if (data.hasOwnProperty('origin')) {
          this.origin$.next(data.origin);
          this.origin$.complete();
        }
        else if (data.hasOwnProperty('api')) {
          this.api$.next(data.api);
          this.api$.complete();
        }
        else if (data.hasOwnProperty('isStoreLocatorWidget')) {
          this.isStoreLocatorWidget$.next(data.isStoreLocatorWidget);
          this.isStoreLocatorWidget$.complete();
        }
        else if (data.hasOwnProperty('customStyle')) {
          this.addCustomStyle(data.customStyle);
        }
        else if (data.hasOwnProperty('queryParam')) {
          this.token$.next(data.queryParam);
          this.token$.complete();
        } else if (data.hasOwnProperty('merlinxToken')) {
          this.merlinxToken$.next(data.merlinxToken);
          this.merlinxToken$.complete();
        }
      }
    };
    window.addEventListener('message', listener, false);
    this.sendMessage({isReady: true});
  }

  private addCustomStyle(styleUrl: string): void {
    const linkElm = document.createElement('link');
    linkElm.type = 'text/css';
    linkElm.rel = 'stylesheet';
    linkElm.href = styleUrl;
    document.querySelector('head').append(linkElm);
  }

  private navigateTo(hash: string): void {
    const fullPath = hash.replace('#', '');
    const [pathname, search] = fullPath.split('?');
    const queryParams: Params = {};
    if (search) {
      search.split('&').forEach(x => {
        const [key, value] = x.split('=');
        queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
      });
    }
    if (location.pathname !== pathname || (search && location.search !== `?${search}`)) {
      this._router.navigate([`${pathname}`], {queryParams});
    }
  }

}
