import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { LoadingItem } from '../../interfaces/loading-item';
import { LoadingService } from '../../services/loading.service';
import { SEOService } from '../../services/seo.service';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent implements OnInit, OnDestroy {

  loadingStatus$: Observable<boolean>;
  loadingMessage$: Observable<string>;
  routerEventsSubscription: Subscription;

  constructor(private _loadingService: LoadingService,
              private _seoService: SEOService,
              private _router: Router) { }

  ngOnInit(): void {
    this.loadingStatus$ = this._loadingService.getLoadingStatus();
    this.loadingMessage$ = this._loadingService.getLoadingMessage();
    this.manageRouteLoading();
  }

  manageRouteLoading(): void {
    const loadingItem: LoadingItem = {
      id: 'page-load',
      message: 'Caricamento in corso...'
    };
    const delay = 600;
    this._router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this._loadingService.addLoad(loadingItem);
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          // SEO SERVICE SHOULD NOT BE CALLED ON ERROR OR CANCEL - Adding landing condition as a temporary workaround...
          if (!window.location.pathname.toLowerCase().includes("/landing/social") &&
              !window.location.pathname.toLowerCase().includes('/cataloghi/player')) { // porkaround
            this._seoService.setDefaults(); // DA EVITARE????!
          }
          window.scroll(0, 0);
          setTimeout(() => {
            this._loadingService.removeLoad(loadingItem);
          }, delay);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
  }

}
