import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'ui-modal',
  templateUrl: './ui-modal.component.html',
  styleUrls: ['./ui-modal.component.scss']
})
export class UiModalComponent implements OnInit, OnDestroy {
  activeModalSub: Subscription;
  activeModal;

  @ViewChild('uiModalContent') uiModalContent: ElementRef;

  @HostListener('click', ['$event'])
  clickListener(e) {
    if (this.activeModal && this.activeModal.visible && !this.uiModalContent.nativeElement.contains(e.target)) {
      this.close();
    }
  }

  constructor(
    private _modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.activeModalSub = this._modalService.activeModal$.subscribe(activeModal => {
      this.activeModal = activeModal;
      if (activeModal) {
        document.body.style.overflow = this.activeModal.visible ? 'hidden' : 'auto';
      }
    });
  }

  ngOnDestroy(): void {
    this.activeModalSub.unsubscribe();
  }
  close(): void {
    this._modalService.closeActiveModal();
  }

}
