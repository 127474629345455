import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor() { }

  getMeta = (name: string) => {
    const allMetaElements = document.getElementsByTagName('meta');
    for (const i in allMetaElements) {
      if (allMetaElements[i].getAttribute('name') === name) {
        return allMetaElements[i].getAttribute('content');
      }
    }
    return '';
  }

  addMeta = (name: string, content: string) => {
    if (!name || !content) {
      return;
    }
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('name', name);
    metaTag.content = content;
    document.getElementsByTagName('head')[0].append(metaTag);
  }

  changeMeta = (name: string, content: string) => {
    if (!name || !content) {
      return;
    }
    const allMetaElements = document.getElementsByTagName('meta');
    for (const i in allMetaElements) {
      if (allMetaElements[i].getAttribute('name') === name) {
        allMetaElements[i].setAttribute('content', content);
        break;
      }
    }
  }

  changeOGCard = (name: string, content: string) => {
    if (!name || !content) {
      return;
    }
    const allMetaElements = document.getElementsByTagName('meta');
    for (const i in allMetaElements) {
      if (allMetaElements[i].getAttribute('property') === 'og:' + name) {
        allMetaElements[i].setAttribute('content', content);
        break;
      }
    }
  }

  changeTWCard = (name: string, content: string) => {
    if (!name || !content) {
      return;
    }
    const allMetaElements = document.getElementsByTagName('meta');
    for (const i in allMetaElements) {
      if (allMetaElements[i].getAttribute('name') === 'twitter:' + name) {
        allMetaElements[i].setAttribute('content', content);
        break;
      }
    }
  }

  setOGCard = (title: string, siteName: string, description: string, image: string, url: string, type: string, locale: string, width: string = '1200', height: string = '628') => {
    this.changeOGCard('title', title);
    this.changeOGCard('site_name', siteName);
    this.changeOGCard('description', description);
    this.changeOGCard('image', image);
    this.changeOGCard('url', this.removeParam('prerender', url));
    this.changeOGCard('type', type);
    this.changeOGCard('locale', locale);
    this.changeOGCard('image:width', width);
    this.changeOGCard('image:height', height);
  }

  setTWCard = (title: string, description: string, image: string, url: string, card: string, site: string) => {
    this.changeTWCard('title', title);
    if (url) {
      this.changeTWCard('description', description + ' ' + this.removeParam('prerender', url));
    } else {
      this.changeTWCard('description', description);
    }
    this.changeTWCard('image', image);
    this.changeTWCard('card', card);
    this.changeTWCard('site', site);
  }

  removeParam = (key, url) => {
    let rtn = url.split('?')[0];
    let param;
    let paramsArray = [];
    const queryString = (url.indexOf('?') !== -1) ? url.split('?')[1] : '';

    if (queryString !== '') {
      paramsArray = queryString.split('&');
      for (let i = paramsArray.length - 1; i >= 0; i -= 1) {
        param = paramsArray[i].split('=')[0];
        if (param === key) {
          paramsArray.splice(i, 1);
        }
      }
      if (paramsArray.length > 0) {
        rtn += '?' + paramsArray.join('&');
      }
    }
    return rtn;
  }

  public removeIndexingFromGoogle() {
    if (window.location.href.includes('website.offertetouroperator.com')) {
      this.addMeta('robots', 'noindex, nofollow');
    }
  }
}
