//  Fornita una data come parametro la restituisce formattata

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  
  transform(value: Date, formatType: string = 'simple'): any {
    
    if( !value?.getDate()) return ''; //  Se la data non è valida

    const days   = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
    const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']

    const dateYear = value.getFullYear().toString();
    const dateMonth = value.getMonth();
    const dateDayOfMonth = value.getDate().toString();
    const dateDayOfWeek = value.getDay().toString();
    const hour = value.getHours().toString().padStart(2,'0');
    const minutes = value.getMinutes().toString().padStart(2,'0');
    const seconds = value.getSeconds().toString().padStart(2,'0');
    
    const formatted = {
      'simple'                     : `${dateDayOfMonth.padStart(2,'0')}-${(dateMonth + 1).toString().padStart(2,'0')}-${dateYear}`,
      'simpleYearMonthDate'        : `${dateYear}-${(dateMonth + 1).toString().padStart(2,'0')}-${dateDayOfMonth.padStart(2,'0')}`,
      'shortDateDayMonth'          : `${days[dateDayOfWeek].substring(0,3)} ${dateDayOfMonth.padStart(2,'0')} ${months[dateMonth].substring(0,3)}`,
      'shortDayMonth'              : `${dateDayOfMonth.padStart(2,'0')} ${months[dateMonth].substring(0,3)}`,
      'shortDayMonthYear'          : `${dateDayOfMonth.padStart(2,'0')} ${months[dateMonth].substring(0,3)} ${dateYear}`,
      'extendedDateDayMonthYear'   : `${days[dateDayOfWeek]} ${dateDayOfMonth.padStart(2,'0')} ${months[dateMonth]} ${dateYear}`,
      'extendedDayMonthYear'       : `${dateDayOfMonth.padStart(2,'0')} ${months[dateMonth]} ${dateYear}`,
      'extendedDateDayMonth'       : `${days[dateDayOfWeek]} ${dateDayOfMonth.padStart(2,'0')} ${months[dateMonth]}`,
      'hhmmss'                     : `${hour}:${minutes}:${seconds}`,
      'hhmm'                       : `${hour}:${minutes}`,
      'hhmmNoColons'               : `${hour}${minutes}`,
    }

    return formatted[formatType];
  }

}
