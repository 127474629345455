import {Component, OnInit} from '@angular/core';
import {Occupancy} from '../../interfaces/occupancy';
import {BookingService} from '../../services/booking.service';

@Component({
  selector: 'app-booking-passengers',
  templateUrl: './booking-passengers.component.html',
  styleUrls: ['./booking-passengers.component.scss']
})
export class BookingPassengersComponent implements OnInit {

  maxAge = this._bookingService.maxAge;
  maxGuests = this._bookingService.maxGuests;

  occupancy: Occupancy = this._bookingService.occupancy;
  agesSelect: number[];

  constructor(protected _bookingService: BookingService) {
    this.agesSelect = Array(this.maxAge).fill(null).map((v, k) => k + 1);
  }

  getTotalGuestsNumber(): number {
    return this.occupancy.guests;
  }

  ngOnInit(): void {
  }

  agesTrackBy(index: number): number {
    return index;
  }

  removeGuest(): void {
    if (this.occupancy.guests > 1) {
      this.occupancy.guests--;
      this.occupancy.ages.pop();
    }
  }

  addGuest(): void {
    if (this.getTotalGuestsNumber() < this.maxGuests) {
      this.occupancy.guests++;
      this.occupancy.ages.push(35);
    }
  }

}
