import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import * as Countries from '../../../../assets/data/countries.json';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor() { }

  getFormattedCountries(): Observable<string[]> {
    return this.getCountries().pipe(
      map(e => e ? Object.values(e).map((c: any) => {
        if (c.italian_country_name_1) {
          return c.italian_country_name_1;
        } else if (c.italian_country_name_2) {
          return c.italian_country_name_2;
        }
        return c.english_country_name;
      }).filter(x => x) : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getCountries(): Observable<any> {
    return of(Countries).pipe(
      map((e: any) => e.default),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }
}
