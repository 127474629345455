import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, throwError } from 'rxjs';
import { EnvironmentService } from '../modules/shared/services/environment.service';
import { ProjectService } from '../modules/shared/services/project.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ContentTypes } from '../modules/shared/models/content-types.enum';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public activeModal$: BehaviorSubject<any>;
  public activeModal;

  constructor(
    private _http: HttpClient,
    private _environmentService: EnvironmentService,
    private _projectService: ProjectService
  ) { 
    this.activeModal$ = new BehaviorSubject<any>(this.activeModal);
  }

  public openModal(type, dataSource) {
    this.activeModal = {
      visible: true,
      type: type,
      dataSource: dataSource
    }
    this.activeModal$.next(this.activeModal);
  }

  public closeActiveModal() {
    this.activeModal.visible = false;
    this.activeModal$.next(this.activeModal);
  }

  public getContent(category: ContentTypes, selectedAgencyApikey?: string): Observable<any> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey()
    ]).pipe(
      mergeMap(env => {
        // TODO: da vedere se il baseUrl è corretto
        const baseUrl = env[0].otoApi2Url; // ??
        const apiKey = selectedAgencyApikey || env[1];
        const url = `${baseUrl}/oto-api/external-service/public/${apiKey}/BITCO_PRIVACY?category=${category}`;
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'text/html'
        });
        return this._http.get(url, { headers, responseType: 'text' });
      }),
      map((resp: any) => resp),
      catchError(err => throwError(err))
    );
  }
}