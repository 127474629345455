import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import fixArray from '../../../utils/fixArray';
import { IPrefetchDateParams } from '../interfaces/iprefetch-date-params';
import { IPrefetchDeparturePlacesParams } from '../interfaces/iprefetch-departure-places-params';
import { IPrefetchDestinationsParams } from '../interfaces/iprefetch-destinations-params';
import { Networks } from '../models/networks.enum';
import { EnvironmentService } from './environment.service';
import { PostMessageService } from './post-message.service';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root'
})
export class HolidayPrefetchService {
  public prefetchDates = new BehaviorSubject<string[]>(null);

  constructor(private _environmentService: EnvironmentService,
    private _projectService: ProjectService,
    private _postMessageService: PostMessageService,
    private _http: HttpClient) {
  }

  httpCache = new Map<string, Observable<any>>();

  setPrefetchDates(value): void {
    this.prefetchDates.next(value);
  }

  prefetchDestinations(params: IPrefetchDestinationsParams = {}): Observable<any> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
      this._projectService.getAgencyNetwork(),
      this._postMessageService.isB2B(),
      this._projectService.getProject(),
      this._projectService.hasOnlyNetQuotes()
    ]).pipe(
      mergeMap(e => {
        const hasOnlyNetQuotes = e[5];
        params.channel = 'B2C';
        if (Number(e[2].id_network) && Number(e[2].id_network) === Networks.WTG) {
          params.onlyNetQuotes = hasOnlyNetQuotes;
          params.onlyCustomHoliday = false;
          params.quoteTypology = 'Both';
        }
        params.onlyCustomHoliday = false;
        const url = `${e[0].searchUrl}/index.php/holiday/${e[1]}/prefetchDestinations`;
        const key = url + JSON.stringify(params);
        if (!this.httpCache.get(key)) {
          this.httpCache.set(key, this._http.post<any>(url, params).pipe(shareReplay({ bufferSize: 1, refCount: true })));
        }
        return this.httpCache.get(key);
      }),
      map(e => fixArray(e.prefetch.destinations).filter(x => x)),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => throwError(err))
    );
  }

  prefetchDeparturePlaces(params: IPrefetchDeparturePlacesParams): Observable<any> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
      this._projectService.getAgencyNetwork(),
      this._postMessageService.isB2B(),
      this._projectService.hasOnlyNetQuotes()
    ]).pipe(
      mergeMap(e => {
        const hasOnlyNetQuotes = e[4];
        params.channel = 'B2C';
        if (Number(e[2].id_network) && Number(e[2].id_network) === Networks.WTG) {
          params.onlyNetQuotes = hasOnlyNetQuotes;
          params.onlyCustomHoliday = false;
          params.quoteTypology = 'Both';
        }
        params.onlyCustomHoliday = false;
        const url = `${e[0].searchUrl}/index.php/holiday/${e[1]}/prefetchDeparturePlaces`;
        const key = url + JSON.stringify(params);
        if (!this.httpCache.get(key)) {
          this.httpCache.set(key, this._http.post<any>(url, params).pipe(shareReplay({ bufferSize: 1, refCount: true })));
        }
        return this.httpCache.get(key);
      }),
      map(e => fixArray(e.prefetch.departurePlaces).filter(x => x)),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => throwError(err))
    );
  }

  prefetchDate(params: IPrefetchDateParams): Observable<any> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
      this._projectService.getAgencyNetwork(),
      this._postMessageService.isB2B(),
      this._projectService.hasOnlyNetQuotes()
    ]).pipe(
      mergeMap(e => {
        const hasOnlyNetQuotes = e[4];
        params.channel =  'B2C';
        if (Number(e[2].id_network) && Number(e[2].id_network) === Networks.WTG) {
          params.onlyNetQuotes = hasOnlyNetQuotes;
          params.onlyCustomHoliday = false;
          params.quoteTypology = 'Both';
        }
        params.onlyCustomHoliday = false;
        const url = `${e[0].searchUrl}/index.php/holiday/${e[1]}/prefetchDate`;
        const key = url + JSON.stringify(params);
        if (!this.httpCache.get(key)) {
          this.httpCache.set(key, this._http.post<any>(url, params).pipe(shareReplay({ bufferSize: 1, refCount: true })));
        }
        return this.httpCache.get(key);
      }),
      map(e => fixArray(e.prefetch.departureDates).filter(x => x)),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => throwError(err))
    );
  }
}
