import {Pipe, PipeTransform} from '@angular/core';
import {HolidayCard} from '../interfaces/holiday-card';

@Pipe({
  name: 'orderByCustom'
})
export class OrderByCustomPipe implements PipeTransform {

  private AWTOP: number[] = [5013, 5035, 5090, 5089, 5052, 1108, 80, 4097, 4130, 4041];

  transform(value: HolidayCard[]): HolidayCard[] {
    if (value) {
      return value.sort((a, b) => {
        if (a.isCustom && b.isCustom) {
          if (a.tourOperator && b.tourOperator) {
            return this.AWTOP.includes(a.tourOperator) ? -1 : this.AWTOP.includes(b.tourOperator) ? 1 : 0;
          }
          return 0;
        }
        return a.isCustom ? -1 : b.isCustom ? 1 : 0;
      });
    }
    return [];
  }

}
