import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import * as dayjs from 'dayjs';
import { forkJoin, of, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { PostMessageService } from 'src/app/modules/shared/services/post-message.service';
import getHolidayTypesByAirports from '../../../../../../helpers/get-holiday-types-by-airports';
import fixArray from '../../../../../../utils/fixArray';
import uniqBy from '../../../../../../utils/uniqBy';
import { EngineTabTypes } from '../../../../../search-engine/models/engine-tab-types.enum';
import { BookingService } from '../../../../../shared/services/booking.service';
import { HolidayPrefetchService } from '../../../../../shared/services/holiday-prefetch.service';
import { MobileService } from '../../../../../shared/services/mobile.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ALL_HOLIDAY_TYPES, busHolidayTypeList } from '../../helpers/holiday-types';
import { HolidayCalendarItem } from '../../interfaces/holiday-calendar-item';
import { tourHolidayTypeList } from '../../helpers/holiday-types';

@Component({
  selector: 'app-holiday-search-calendar',
  templateUrl: './holiday-search-calendar.component.html',
  styleUrls: ['./holiday-search-calendar.component.scss']
})
export class HolidaySearchCalendarComponent implements OnInit, OnDestroy {

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _bookingService: BookingService,
              private _projectService: ProjectService,
              private _holidayPrefetchService: HolidayPrefetchService,
              private _postMessageService: PostMessageService,
              private _mobileService: MobileService) {
  }

  prefetchDateSubscription: Subscription;
  routeParamSubscription: Subscription;
  routeUrlSubscription: Subscription;
  mobileSubscription: Subscription;
  departureDatesSub: Subscription;
  currentDate: string;
  prefetchDates: string[];
  calendar: HolidayCalendarItem[];
  currentPage = 1;
  currentIndex: number;
  pageSize = 7;
  totalItems: number;
  totalPages: number;
  routeParts: UrlSegment[];
  engineTabType: EngineTabTypes;
  engineTabTypes = EngineTabTypes;
  departureDate: string;
  isB2B: boolean;
  onlyProposte = false;

  ngOnInit(): void {
    this._postMessageService.isB2B().subscribe(isB2B => {
      this.isB2B = isB2B;
    });
    this.engineTabType = this._route.snapshot.data.type;
    // if (this.engineTabType === EngineTabTypes.Bus) {
      // this.departureDatesSub = this._holidayPrefetchService.prefetchDates.subscribe(allDepartureDates => {
      //   if (allDepartureDates) {
          // const departureDates = allDepartureDates?.filter(date => {
          //   return (
          //     dayjs(date).isSame(dayjs(this._route.snapshot.paramMap.get('departureDate'), 'DD-MM-YYYY')) ||
          //     dayjs(date).isSame(dayjs(this._route.snapshot.paramMap.get('departureDate'), 'DD-MM-YYYY').endOf('month')) ||
          //     (
          //       dayjs(dayjs(date)).isAfter(dayjs(this._route.snapshot.paramMap.get('departureDate'), 'DD-MM-YYYY')) &&
          //       dayjs(dayjs(date)).isBefore(dayjs(this._route.snapshot.paramMap.get('departureDate'), 'DD-MM-YYYY').endOf('month'))
          //     )
          //   );
          // });

          // this.initializeComponent(allDepartureDates);
        // }
      // });
    // } else {
    this.initializeComponent();
    // }
  }

  getHolidayTypeList(): number[] {
    if (this.onlyProposte) {
      return ALL_HOLIDAY_TYPES;
    } else {
      switch (this.engineTabType) {
        case EngineTabTypes.Bus:
          return busHolidayTypeList;
        case EngineTabTypes.Tour:
          return tourHolidayTypeList;
        default:
          return null;
      }
    }
  }

  // initializeComponent(dates = null): void {
  initializeComponent(): void {
    this.mobileSubscription = this._mobileService.isMobile().subscribe(isMobile => {
      this.pageSize = (isMobile) ? 3 : 7;
      this.initPagination();
    });
    this.routeParamSubscription = this._route.paramMap.pipe(
      map(e => {
        this.engineTabType = this._route.snapshot.data.type;
        this.onlyProposte = this._route.snapshot.queryParamMap.get('proposte') === 'true';
        const onlyCustomHoliday = this.onlyProposte;
        const parsedDate = dayjs(e.get('departureDate'), 'DD-MM-YYYY');
        this.departureDate = e.get('departureDate');
        let dateFrom;
        let dateTo;
        const destinations = [];
        const airports = [];
        // let departureDates = [];
        const holidayTypeList = this.getHolidayTypeList();
        // if (this.engineTabType === EngineTabTypes.Bus) {
        //   departureDates = dates;
        // } else {
          // if (onlyCustomHoliday) {
          //   dateFrom = parsedDate.startOf('month').format('YYYY-MM-DD');
          //   dateTo = parsedDate.endOf('month').format('YYYY-MM-DD');
          // } else {
        dateFrom = parsedDate.format('YYYY-MM-DD');
        dateTo = dateFrom;
          // }
        // }
        const parsedDestinationId = parseInt(e.get('destinationId'), 10);
        if (!isNaN(parsedDestinationId)) {
          destinations.push(parsedDestinationId);
        }

        const airportsIdList = e.get('airportId').split(',');
        for (const value of airportsIdList) {
          const airportId = (value === "null" || value === null) ? null : parseInt(value, 10);
          if (!isNaN(airportId) && airportId !== null) {
            airports.push(airportId);
          }
        }
        const ages = this._bookingService.getAgesArray();
        return {
          dateFrom,
          dateTo,
          onlyCustomHoliday,
          onlyNetQuotes: this.isB2B,
          destinations,
          // departureDates,
          holidayTypeList,
          airports,
          ages
        };
      }),
      mergeMap(e => {
        if (e.onlyCustomHoliday && e.destinations.length === 0) {
          const prefetchParams: any = {
            onlyCustomHoliday: e.onlyCustomHoliday,
            onlyNetQuotes: e.onlyNetQuotes,
            ages: e.ages
          };
          if (e.holidayTypeList) {
            prefetchParams.holidayTypeList = e.holidayTypeList;
          }
          return forkJoin([
            of(e),
            this._holidayPrefetchService.prefetchDestinations(prefetchParams)
          ]).pipe(
            map(x => {
              x[0].destinations = x[1];
              return x[0];
            }),
            mergeMap(z => {
              if (z.onlyCustomHoliday && z.airports.length === 0) {
                const prefetchPlaceParams: any = {
                  onlyCustomHoliday: z.onlyCustomHoliday,
                  onlyNetQuotes: z.onlyNetQuotes,
                  destination: z.destinations,
                  ages: z.ages
                };
                if (e.holidayTypeList) {
                  prefetchPlaceParams.holidayTypeList = e.holidayTypeList;
                }
                return forkJoin([
                  of(z),
                  this._holidayPrefetchService.prefetchDeparturePlaces(prefetchPlaceParams)
                ]).pipe(
                  map(y => {
                    y[0].airports = y[1];
                    if (y[0].airports.length === 1 && y[0].airports[0] === 'null') {
                      y[0].airports = [];
                    }
                    return y[0];
                  })
                );
              }
              return of(z);
            })
          );
        }
        if (!e.onlyCustomHoliday) {
          return forkJoin([
            of(e),
            this._projectService.hasOnlyNetQuotes()
          ]).pipe(
            map(x => {
              x[0].onlyNetQuotes = x[1];
              return x[0];
            })
          );
        }
        return of(e);
      })
    ).subscribe(e => {
      this.currentDate = this.formatDate(e.dateFrom);
      const destinations = e.destinations;
      const airports = e.airports;
      this.doPrefetch(destinations, airports, e.onlyCustomHoliday, e.onlyNetQuotes, e.ages, this.getOverridedHolidayTypeList(), !this.onlyProposte);
    });
    this.routeUrlSubscription = this._route.url.subscribe(e => {
      this.routeParts = e;
    });
  }

  getOverridedHolidayTypeList(): number[] {
    if (this.onlyProposte) {
      return ALL_HOLIDAY_TYPES;
    } else if (this.engineTabType === EngineTabTypes.Bus) {
      return busHolidayTypeList;
    } else {
      return null;
    }
  }

  searchDate(calendarItem: HolidayCalendarItem): void {
    const formattedNavigationDate = dayjs(calendarItem.dateString, 'YYYY-MM-DD').format('DD-MM-YYYY');
    const finalPath = this.routeParts.map(e => e.path);
    finalPath[this.engineTabType === EngineTabTypes.Bus ? 4 : 8] = formattedNavigationDate;
    this._router.navigate([`/${this.engineTabType}/` + finalPath.join('/')], {
      queryParams: {
        proposte: this.onlyProposte
      }
    });
  }

  doPrefetch(destination, departurePlace, onlyCustomHoliday, onlyNetQuotes, ages, holidayTypeList = null, hasTypeList = true): void {
    if (!holidayTypeList && hasTypeList) {
      holidayTypeList = getHolidayTypesByAirports(departurePlace);
    }
    this.prefetchDateSubscription = this._holidayPrefetchService.prefetchDate({destination, departurePlace, onlyCustomHoliday, holidayTypeList, onlyNetQuotes, ages}).subscribe(e => {
      this.prefetchDates = fixArray(e);
      // this.currentDate = this.prefetchDates.indexOf(this.currentDate) >= 0 ? this.currentDate : this.prefetchDates[0];
      // this.fillCalendarMonths(this.prefetchDates)
      this.fillCalendarDays(this.prefetchDates);
      this.initPagination();
    });
  }

  initPagination(): void {
    this.totalItems = this.calendar?.length || 0;
    this.totalPages = (this.totalItems && this.pageSize) ? Math.ceil(this.totalItems / this.pageSize) : 0;
    this.currentPage = (this.currentIndex && this.pageSize) ? Math.ceil((this.currentIndex + 1) / this.pageSize) : 1;
  }

  goToNextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  fillCalendarDays(prefetchDates: string[]): void {
    this.calendar = [];
    const firstDate = prefetchDates[0];
    const lastDate = prefetchDates[prefetchDates.length - 1];
    let diff = dayjs(lastDate, 'YYYY-MM-DD').diff(dayjs(firstDate, 'YYYY-MM-DD'), 'day') + 1;
    if (diff < this.pageSize) {
      diff = this.pageSize;
    }
    if (diff % this.pageSize) {
      diff = this.pageSize * Math.ceil(diff / this.pageSize);
    }
    let dateToInsert = firstDate;
    for (let i = 0; i < diff; i++) {
      const calendarElement = {
        dateString: dateToInsert,
        disabled: true,
        active: false,
        link: ''
      };
      if (prefetchDates.includes(dateToInsert)) {
        calendarElement.disabled = false;
      }
      if (this.currentDate === dateToInsert) {
        calendarElement.active = true;
        this.currentIndex = i;
      }
      this.calendar.push(calendarElement);
      dateToInsert = dayjs(dateToInsert, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD');
    }
    this.calendar = [...this.calendar];
  }

  fillCalendarMonths(prefetchDates: string[]): void {
    this.calendar = [];
    const currentMonth = dayjs(this.currentDate, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD');
    const prefetchMonths = uniqBy(prefetchDates, (e) => dayjs(e, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD'))
      .map(e => dayjs(e, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD'));
    const firstMonth = prefetchMonths[0];
    const lastMonth = prefetchMonths[prefetchMonths.length - 1];
    let diff = dayjs(lastMonth, 'YYYY-MM').diff(dayjs(firstMonth, 'YYYY-MM'), 'month') + 1;
    if (diff < this.pageSize) {
      diff = this.pageSize;
    }
    if (diff % this.pageSize) {
      diff = this.pageSize * Math.ceil(diff / this.pageSize);
    }
    let monthToInsert = firstMonth;
    for (let i = 0; i < diff; i++) {
      const calendarElement = {
        dateString: monthToInsert,
        disabled: true,
        active: false,
        link: ''
      };
      if (prefetchMonths.includes(monthToInsert)) {
        calendarElement.disabled = false;
      }
      if (currentMonth === monthToInsert) {
        calendarElement.active = true;
        this.currentIndex = i;
      }
      this.calendar.push(calendarElement);
      monthToInsert = dayjs(monthToInsert, 'YYYY-MM-DD').add(1, 'month').format('YYYY-MM-DD');
    }
    this.calendar = [...this.calendar];
  }

  formatDate(date: Date): string {
    return dayjs(date).format('YYYY-MM-DD');
  }

  ngOnDestroy(): void {
    this.prefetchDateSubscription?.unsubscribe();
    this.routeParamSubscription?.unsubscribe();
    this.routeUrlSubscription?.unsubscribe();
    this.mobileSubscription?.unsubscribe();
    this.departureDatesSub?.unsubscribe();
  }

}
