import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompilerService {

  constructor() {
  }

  compile(template: string, scope: object): string {
    let compiledTemplate = template;
    for (let [key, value] of Object.entries(scope)) {
      if (value instanceof Object) {
        if (value.toString) {
          value = value.toString();
        } else {
          throw new Error('Nested objects not supported');
        }
      } else {
        compiledTemplate = compiledTemplate.replace(new RegExp(`{{${key}}}`, 'g'), value);
      }
    }
    return compiledTemplate;
  }

}
