import {Pipe, PipeTransform} from '@angular/core';
import {IHolidayLive} from '../../booking-flow/holiday/holiday-booking-page/interfaces/iholiday-live';
import {IHolidayLiveRoom} from '../../booking-flow/holiday/holiday-booking-page/interfaces/iholiday-live-room';

@Pipe({
  name: 'netQuote'
})
export class NetQuotePipe implements PipeTransform {

  transform(value: IHolidayLive): IHolidayLiveRoom {
    if (value && value.solutions && value.solutions.length > 0) {
      return value.solutions.map((x) => x.bestRoom).reduce((a, b) => {
        if (!a) {
          return b;
        }
        return b.price < a.price ? b : a;
      });
    }

    return undefined;
  }

}
