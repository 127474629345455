export enum EngineTabTypes {
  Holiday = 'vacanze',
  Cruise = 'cruise',
  Tour = 'tour',
  Bus = 'bus',
  Flight = 'flight',
  Hotel = 'hotel',
  CarRental = 'carrental',
  Parking = 'parking',
  SmartBox = 'smartbox',
  WelcomeToItaly = 'welcometoitaly',
}
