import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from '../../../../services/modal.service';

@Component({
  selector: 'structure-detail',
  templateUrl: './structure-detail.component.html',
  styleUrls: ['./structure-detail.component.scss']
})
export class StructureDetailComponent implements OnInit {
  @Input() dataSrc;

  constructor(
    private _modalService: ModalService
  ) { }

  ngOnInit(): void {
  }
}
