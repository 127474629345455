<div class="c-info-request">
  <app-element-loader [loadingMessage]="loadingMessage" [loadingStatus]="loadingStatus"></app-element-loader>

  <div class="c-info-request__header">
    <span class="c-info-request__title" (click)="openFormInfo = !openFormInfo">
      {{openFormInfo ? 'Richiedi informazioni' : 'Chiudi'}}
    </span>
  </div>

  <div class="c-info-request__content" [ngClass]="{'closed-form-info' : !openFormInfo}">

    <!-- Telefonaci -->
    <div class="c-info-request__sub-section"
         *ngIf="(profile$ | async)?.tel_1?.trim()?.length > 1 || agencyInfoForLanding?.tel1?.trim()?.length > 1">
      <h6 class="h6 c-info-request__sub-title">Chiama adesso!</h6>
      <div class="c-info-request__cta">
        <i class="icon-phone-shape"></i>
        <span>
          <a *ngIf="!isLanding" [href]="'tel:' + (profile$ | async)?.tel_1.trim()">
            {{(profile$ | async)?.tel_1.trim()}}
          </a>
          <a *ngIf="isLanding" [href]="'tel:' + agencyInfoForLanding.tel1.trim()">
            {{agencyInfoForLanding.tel1.trim()}}
          </a>
        </span>
      </div>
    </div>

    <div class="c-info-request__separator"></div>

    <!-- Mandaci una mail -->
    <div class="c-info-request__sub-section">

      <h6 class="h6 c-info-request__sub-title">Inviaci una mail</h6>

      <form class="search-form" name="infoForm" #formReq="ngForm" (ngSubmit)="submitRequest()">
        <div class="form-group">
          <input name="userName" [(ngModel)]="name" required type="text" class="form-control" id="input1"
            placeholder="Inserisci il tuo nome">
        </div>
        <div class="form-group">
          <input name="userLastName" [(ngModel)]="surname" required type="text" class="form-control" id="input2"
            placeholder="Inserisci il tuo cognome">
        </div>
        <div class="form-group">
          <input name="userPhone" [(ngModel)]="telephone" required type="number" class="form-control" id="input3"
            placeholder="+39">
        </div>
        <div class="form-group">
          <input name="userEmail" [(ngModel)]="email" required type="email" class="form-control" id="input4"
            placeholder="Inserisci il tuo indirizzo email">
        </div>

        <!-- Numero Pax -->
        <div class="row form-group">
          <div class="col-12">
            <label for="input5">Numero Passeggeri</label>
          </div>
          <div class="col-12">
            <input name="passengers" [(ngModel)]="passengers" required [max]="5" [min]="1"
              (ngModelChange)="setAgesArray($event)" type="number" class="form-control text-center" id="input5">
          </div>
        </div>

        <!-- Età Pax -->
        <div class="row">
          <div class="form-group col-12">
            <label>Età Passeggeri</label>
            <div class="passengers-input-group">
              <input *ngFor="let age of ages; let i = index; trackBy: trackByAges" [name]="'age-'+i"
                [(ngModel)]="ages[i]" required type="number" class="form-control" id="input6-{{i}}">
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="form-check form-check-inline privacy-check">
            <input class="form-check-input" type="checkbox" name="consenso" id="inlineCheckbox" ngModel required>
            <label class="form-check-label" for="inlineCheckbox">
              Dichiaro di aver letto e compreso <a (click)="openPrivacy($event)">l’informativa privacy.</a>
            </label>
          </div>
        </div>
        <button type="submit" class="btn btn--blue w-100" [disabled]="formReq.invalid">Invia</button>
      </form>
      <div class="alert" [ngClass]="messageClass" *ngIf="message">{{message}}</div>
    </div>
  </div>

</div>