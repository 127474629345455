import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LoadingItem} from '../interfaces/loading-item';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loadingMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private loadingStack: LoadingItem[] = [];

  // loading di tipo classico formato da solo array di stringhe
  // NB: è consigliato usare stringhe univoche altrimenti, utilizzare l'altro tipo di loader con oggetti di tipo "LoadingItem"
  private classicLoadingStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private classicLoadingStack: string[] = [];

  // loading di tipo classico formato da solo array di stringhe (dedicato esclusivamente agli skeleton loader)
  private skeletonLoadingStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private skeletonLoadingStack: string[] = [];

  constructor() {
  }

  private updateLoader(): void {
    this.loadingStatus.next(this.loadingStack.length > 0);
    if (this.loadingStack.length > 0) {
      this.loadingMessage.next(this.loadingStack[this.loadingStack.length - 1].message);
    } else {
      this.loadingMessage.next('');
    }
  }

  private updateClassicLoader(): void {
    this.classicLoadingStatus.next(this.classicLoadingStack.length > 0);
  }

  private updateSkeletonLoader(): void {
    this.skeletonLoadingStatus.next(this.skeletonLoadingStack.length > 0);
  }

  addLoad(loadingItem: LoadingItem): void {
    this.loadingStack.push(loadingItem);
    this.updateLoader();
  }

  removeLoad(loadingItem: LoadingItem): void {
    this.loadingStack = this.loadingStack.filter(e => e.id !== loadingItem.id);
    this.updateLoader();
  }

  getLoadingStatus(): Observable<boolean> {
    return this.loadingStatus;
  }

  getLoadingMessage(): Observable<string> {
    return this.loadingMessage;
  }

  addClassicLoad(loadingItem: string): void {
    this.classicLoadingStack.push(loadingItem);
    this.updateClassicLoader();
  }

  removeClassicLoad(loadingItem: string): void {
    this.classicLoadingStack = this.classicLoadingStack.filter(e => e !== loadingItem);
    this.updateClassicLoader();
  }

  getClassicLoadingStatus(): Observable<boolean> {
    return this.classicLoadingStatus;
  }

  addSkeletonLoad(loadingSkeletonItem: string = 'generic'): void {
    this.skeletonLoadingStack.push(`skeleton-${loadingSkeletonItem}`);
    this.updateSkeletonLoader();
  }

  removeSkeletonLoad(loadingSkeletonItem: string = 'generic'): void {
    this.skeletonLoadingStack = this.skeletonLoadingStack.filter(e => e !== `skeleton-${loadingSkeletonItem}`);
    this.updateSkeletonLoader();
  }

  getSkeletonLoadingStatus(): Observable<boolean> {
    return this.skeletonLoadingStatus;
  }
}
