export enum Extras {
  GEO_NEWSLETTER = 'newsletter',
  GEO_LISTE = 'liste_eventi',
  NEWSLETTER_B2C = 'NEWSLETTER_B2C',
  NEWSLETTER_B2C_CRM_GEO = 'NEWSLETTER_B2C_CRM_GEO',
  NEWSLETTER_B2C_WELCOME_TRAVEL = 'NEWSLETTER_B2C_WELCOME_TRAVEL',
  SERVIZI_DIGITALI_LISTE_IN_VIAGGIO = 'SERVIZI_DIGITALI_LISTE_IN_VIAGGIO',
  ASSICURATA = 'ASSICURATA',
  FONDOGARANZIA = 'FONDOGARANZIA'
}
