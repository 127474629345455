import {Pipe, PipeTransform} from '@angular/core';
import {HolidayCard} from '../interfaces/holiday-card';
import {ResultOrderTypes} from '../../../../shared/models/result-order-types.enum';
import {OrderByBrandAndPricePipe} from './order-by-brand-and-price.pipe';

@Pipe({
  name: 'holidayOrder'
})
export class HolidayOrderPipe implements PipeTransform {

  constructor(private _orderByBrandAndPricePipe: OrderByBrandAndPricePipe) {
  }


  transform(value: HolidayCard[], orderType: ResultOrderTypes): HolidayCard[] {
    if (value && orderType) {
      switch (orderType) {
        case ResultOrderTypes.RECOMMENDED:
          return this._orderByBrandAndPricePipe.transform(value);
        case ResultOrderTypes.DATE:
          return value.sort((a, b) => a.bestSolution.dateFrom > b.bestSolution.dateTo ? 1 : -1);
        case ResultOrderTypes.PRICE:
          return value.sort((a, b) => a.bestSolution.price > b.bestSolution.price ? 1 : -1);
      }
    }
    return [];
  }

}
