import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import {catchError, map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import { IRemoteAgencyNetwork } from '../interfaces/iremote-agency-network';
import { IRemoteAgencyNetworkProfile } from '../interfaces/iremote-agency-network-profile';
import { IRemoteProfile } from '../interfaces/iremote-profile';
import { Extras } from '../models/extras.enum';
import {Networks, WTGNetworkSite} from '../models/networks.enum';
import {OnlyNetProjectTypes} from '../models/only-net-project-types.enum';
import { ProjectTypes } from '../models/project-types.enum';
import { Sources } from '../models/sources.enum';
import { environment } from './../../../../environments/environment';
import { EnvironmentService } from './environment.service';
import { PostMessageService } from './post-message.service';
import {IRemoteServicesProject} from '../interfaces/iremote-services-project';
import fixArray from '../../../utils/fixArray';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private _parentUrl: string;
  get parentUrl() {
    return this._parentUrl;
  }
  set parentUrl(newParentUrl) {
    this._parentUrl = newParentUrl;
  }

  private _isB2B: boolean;
  get isB2B() {
    return this._isB2B;
  }
  set isB2B(newIsB2B) {
    this._isB2B = newIsB2B;
  }

  private _isWidget: boolean;
  get isWidget() {
    return this._isWidget;
  }
  set isWidget(newIsWidget) {
    this._isWidget = newIsWidget;
  }

  private _network: 'GEO' | 'WTG';
  get network() {
    return this._network;
  }
  set network(newNetwork) {
    this._network = newNetwork;
  }

  public networkProfile$: BehaviorSubject<any>;

  constructor(private _http: HttpClient,
              private _postMessageService: PostMessageService,
              private _environmentService: EnvironmentService) {
    this.networkProfile$ = new BehaviorSubject<any>(null);
  }

  httpCache: Map<string, Observable<any>> = new Map<string, Observable<any>>();

  formatProfile(profile: IRemoteProfile, agencyNetwork?: IRemoteAgencyNetwork): IRemoteProfile {
    if (profile) {
      profile.logo = profile.logo?.trim() || '';
      if (!profile.logo || !profile.logo.startsWith('http')) {
        // se la proprietà logo è vuota, imposto un placeholder a seconda del network di appartenenza
        // NB: se non viene passato "agencyNetwork", prendo un logo di default generico di oto-frontend
        profile.logo = 'assets/images/';
        if (agencyNetwork && (Number(agencyNetwork.id_network) === Networks.GEO)) {
          profile.logo += 'logo_g.png';
        } else if (agencyNetwork && (Number(agencyNetwork.id_network) === Networks.WTG || Number(agencyNetwork.id_network) === Networks.WTG_MASTER)) {
          profile.logo += 'logo_w.png';
        } else {
          profile.logo += 'logo.png';
        }
      }
      return profile;
    }
    return null;
  }

  formatWebsiteForProject(website: string): string {
    let finalUrl = website.trim();
    if (finalUrl) {
      if (finalUrl.startsWith('//')) {
        finalUrl = `https:${finalUrl}`;
      } else if (!finalUrl.startsWith('http')) {
        finalUrl = `https://${finalUrl}`;
      }
      return new URL(finalUrl).hostname;
    }
    return undefined;
  }

  getProject(): Observable<any> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(e => e ? this.getProfileFromApi().pipe(
        map(x => this.formatWebsiteForProject(x.sito))) : this._environmentService.getCurrentWebsite()
      ),
      mergeMap(e => forkJoin([
        this._environmentService.getEnvironment(),
        this._environmentService.getOtoApikey(),
        of(e)
      ]).pipe(
        mergeMap(x => {
          if (!x[2]) {
            throw 'Dominio non abilitato o riconosciuto';
          }
          const url = `${x[0].otoApiUrl}/index.php/admin/${x[1]}/get/clients/project/${x[2]}`;
          if (!this.httpCache.get(url)) {
            this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
          }
          return this.httpCache.get(url);
        }),
        tap(x => {
          if (!x || (x && x.error)) {
            throw 'Errore durante chiamata project';
          }
        }),
        map(x => {
          if (x.result && x.result.project.enabled !== "0") {
            // se esiste l'oggetto networks con l'id network che corrisponde a "WTG_MASTER" (12), reinizializzo lo stesso con
            // quello "WTG" (16)
            if (x.result.networks?.[0]?.id_network && Number(x.result.networks[0].id_network) === Networks.WTG_MASTER) {
              x.result.networks[0].id_network = String(Networks.WTG);
            }

            // se esiste l'oggetto profile con "network_owner" che corrisponde a "WTG_MASTER" (12), reinizializzo lo stesso con
            // quello "WTG" (16)
            if (x.result.profile?.network_owner && Number(x.result.profile.network_owner) === Networks.WTG_MASTER) {
              x.result.profile.network_owner = String(Networks.WTG);
            }

            // FIXME: parte di codice per far funzionare WTI e Merlinx con un external_id statico definito solo per "vacanzewelcometravel.it"
            //  (rimuovere questo pezzo non appena sto id viene ritornato dal backend per questo stesso sito/project)
            // SOLO se l'id del cliente corrisponde a quello di "vacanzewelcometravel.it" (sito istituzionale WTG) ...
            if (x.result.networks?.[0]?.id_cliente && Number(x.result.networks[0].id_cliente) === WTGNetworkSite.CLIENT_ID) {
              // procedo ad inserire alcuni dati statici in modo da far funzionare l'engine merlinx (e WTI) utilizzando l'external_id
              // dedicato a "vacanzewelcometravel.it"
              if (!x.result.networkProfiles) {
                // creo l'oggetto "networkProfiles" SOLO se questo non è stato ritornato dalla project (ed effettuo un fixArray)
                x.result.networkProfiles = fixArray({});
              }
              x.result.networkProfiles[0].id_network = String(Networks.WTG);
              x.result.networkProfiles[0].external_id = String(WTGNetworkSite.EXTERNAL_ID);

              // procedo anche ad aggiungere il "THIRDPART" tra i "custom_fields" in modo da far visualizzare le tab di merlinx nell'engine
              if (x.result.networkProfiles[0].custom_fields) {
                const customFields = JSON.parse(JSON.stringify(x.result.networkProfiles[0].custom_fields));

                // verifico se è presente il "THIRDPART" nel "custom_fields" altrimenti, lo concateno alla fine
                x.result.networkProfiles[0].custom_fields = (customFields.toLowerCase().includes('thirdpart')) ? customFields : `${customFields},THIRDPART`;
              } else {
                x.result.networkProfiles[0].custom_fields = 'THIRDPART';
              }
            }
            if (x.result?.networks && x.result.networks[0]) {
              this.networkProfile$.next(x.result.networks[0]);
            }
            return x.result;
          }

          return undefined;
        }),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
      ))
    );
  }

  getProjectType(): Observable<ProjectTypes> {
    return this.getProject().pipe(
      map(e => e?.project?.type || ProjectTypes.GEO_BASIC), // FIXME: controllare logica quando avremo piu' network
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  hasOnlyNetQuotes(): Observable<boolean> {
    return forkJoin([
      this._postMessageService.isB2B(),
      this.getProjectType(),
      this.getAgencyNetwork()
    ]).pipe(
      map(e => {
        const [isB2B, projectType, agencyNetwork] = e;
        if (isB2B) {
          return true;
        }
        // se si tratta di un sito GEO oppure di uno WTG, controlla la tipologia del sito con quelle dove impostare "onlyNetQuotes" a true
        if (parseInt(agencyNetwork?.id_network, 10) === Networks.GEO || parseInt(agencyNetwork?.id_network, 10) === Networks.WTG) {
          return Object.values(OnlyNetProjectTypes).includes(projectType);
        }
        return false;
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getQuoteType(): Observable<string | boolean> {
    return this._postMessageService.isB2B().pipe(
        map(isB2B => {
          if (isB2B) {
            return 'both';
          }

          return 'gross';
        }),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
    );
  }

  private getProfileFromProject(): Observable<IRemoteProfile> {
    return this.getProject().pipe(
      map(e => this.formatProfile(e?.profile, e?.networks?.[0])),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getProfileFromApi(): Observable<IRemoteProfile> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this.getAgencyApikey()
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].otoApiUrl}/index.php/configuration/${e[1]}/get/profile`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => this.formatProfile(e.profile, e.networks?.[0])),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getMasterProfileFromApi(): Observable<IRemoteProfile> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._environmentService.getNetworkApikey()
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].otoApiUrl}/index.php/configuration/${e[1]}/get/profile`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => this.formatProfile(e.profile, e.networks?.[0])),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getProfile(): Observable<IRemoteProfile> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(e => e ? this.getProfileFromApi() : this.getProfileFromProject()),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getMasterProfile(): Observable<IRemoteProfile> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(e => e ? this.getMasterProfileFromApi() : this.getProfileFromProject()),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getServiceKey(): Observable<string> {
    return this.getAgencyNetworkProfile().pipe(
      map(e => e ? e.service_id ? e.service_id : e.ref_id ? e.ref_id : e.external_id : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getAgencyNetworkFromProject(): Observable<IRemoteAgencyNetwork> {
    return this.getProject().pipe(
      map(e => (e?.networks) ? e.networks[0] : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getAgencyNetworkFromApi(): Observable<IRemoteAgencyNetwork> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this.getAgencyApikey()
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].otoApiUrl}/index.php/configuration/${e[1]}/get/networks`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => {
        // FIXME: parte di chiamata effettuata solo se "_postMessageService.isB2B" è true (da decidere quindi se rimuovere tutto sto metodo
        //  dato che il B2B è stato scorporato in un progetto separato)
        if (e?.networks?.[0]) {
          if (Number(e.networks[0].id_network) === Networks.WTG_MASTER) {
            e.networks[0].id_network = String(Networks.WTG);
          }

          return e.networks[0];
        }
        return undefined;
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAgencyNetwork(): Observable<IRemoteAgencyNetwork> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(e => e ? this.getAgencyNetworkFromApi() : this.getAgencyNetworkFromProject()),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getWebsiteConfig(): Observable<any[]> {
    return this.getProject().pipe(
      map(e => e.website ? e.website : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getConfigDetailsFromProject(): Observable<any[]> {
    return this.getProject().pipe(
        map(e => (e?.details) ? JSON.parse(e.details) : null),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
    );
  }

  getServicesFromProject(): Observable<IRemoteServicesProject[]> {
    return this.getProject().pipe(
        map(e => e?.services),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
    );
  }

  getWtgOrGeoListeInViaggioUrl(isCustom: boolean = false): Observable<string> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this.getAgencyNetwork()
    ]).pipe(
        mergeMap(e => {
          const [env, agencyNetwork] = e;
          let listeInViaggioUrl: string = '';

          if (agencyNetwork?.id_network && parseInt(agencyNetwork.id_network, 10) === Networks.WTG) {
            listeInViaggioUrl = `${env.wtgListeInViaggioUrl}?codAgency=${agencyNetwork.id_network_agency}`;
          }

          // concateno nell'url anche l'apikey e l'id del network
          // NB: se è stato concatenato il parametro codAgency, aggiungo un "&" nell'url altrimenti un "?"
          if (agencyNetwork?.id_network) {
            listeInViaggioUrl += `${(listeInViaggioUrl.indexOf('codAgency') >= 0) ? '&' : '?'}network=${encodeURIComponent(agencyNetwork.id_network)}`;
          }

          return of(listeInViaggioUrl);
        }),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
    );
  }

  private getAgencyNetworkProfileFromApi(): Observable<IRemoteAgencyNetworkProfile> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this.getAgencyApikey(),
      this.getAgencyNetwork(),
    ]).pipe(
      mergeMap(e => {
        const [env, apikey, agencyNetwork] = e;
        const url = `${env.otoApiUrl}/index.php/configuration/${apikey}/get/affiliateProfile/id/${agencyNetwork.id_network}/${agencyNetwork.id_network_agency}`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => e.affiliateProfile),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getAgencyNetworkProfileFromProject(): Observable<IRemoteAgencyNetworkProfile> {
    return forkJoin([
      this.getProject(),
      this.getAgencyNetwork(),
    ]).pipe(
      map(e => {
        const [project, agencyNetwork] = e;
        if (agencyNetwork) {
          return project.networkProfiles ? project.networkProfiles.find(x => agencyNetwork.id_network === x.id_network) : undefined;
        }
        return undefined;
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAgencyNetworkProfile(): Observable<IRemoteAgencyNetworkProfile> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(e => e ? this.getAgencyNetworkProfileFromApi() : this.getAgencyNetworkProfileFromProject()),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getExtras(): Observable<Extras[]> {
    return this.getAgencyNetworkProfile().pipe(
      map(e => {
        if (e) {
          return e.custom_fields ? e.custom_fields.split(',').map(x => {
            for (const [key, value] of Object.entries(Extras)) {
              if (value === x) {
                return Extras[key];
              }
            }
          }) : [];
        }
        return [];
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getWidgetExtra(): Observable<any> {
    return this.getProject().pipe(
        map(e =>
            e.widgets.filter(widget => widget.id_widget === '-1' || widget.id_widget === '66')
            ?.map(widget => (widget?.extra?.trim()) ? JSON.parse(widget.extra) : '')?.[0] || ''),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
    );
  }

  hasNewsletter(): Observable<boolean> {
    return this.getServicesFromProject().pipe(
      map(e => {
        let hasNewsletter = false;
        if (e) {
          e.forEach(x => {
            if (x.service_code === Extras.NEWSLETTER_B2C_CRM_GEO || x.service_code === Extras.NEWSLETTER_B2C_WELCOME_TRAVEL || x.service_code === Extras.NEWSLETTER_B2C) {
              hasNewsletter = true;
            }
          });
        }
        return hasNewsletter;
      })
    );
  }

  hasWelcomeToItalyTabs(): Observable<boolean> {
    return this.getAgencyNetwork().pipe(
      map(agencyNetworkObj =>
        (Number(agencyNetworkObj.id_network) === Networks.WTG || Number(agencyNetworkObj.id_network) === Networks.GEO)
      )
    );
  }

  hasMerlinxTabs(): Observable<boolean> {
    return this.getAgencyNetwork().pipe(
      mergeMap(e => {
        switch (parseInt(e.id_network, 10)) {
          case Networks.WTG:
            return this.getAgencyNetworkProfile().pipe(
              map(x => !!x?.custom_fields?.toLowerCase().includes('thirdpart'))
            );
          case Networks.GEO:
            return this.getExternalCode(Sources.MerlinxWTG).pipe(
              map(x => !!x)
            );
          default:
            return of(false);
        }
      })
    );
  }

  getMerlinxCode(): Observable<string> {
    return this.getAgencyNetwork().pipe(
      mergeMap(e => {
        switch (parseInt(e.id_network, 10)) {
          case Networks.WTG:
            return this.getAgencyNetworkProfile().pipe(
              map(x => x?.external_id)
            );
          case Networks.GEO:
            return this.getExternalCode(Sources.MerlinxWTG);
          default:
            return of(undefined);
        }
      })
    );
  }

  getExternalCode(source: string | number): Observable<string> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
        const [env, apikey] = e;
        const url = `${env.otoApiUrl}/index.php/customer/getcode/${apikey}/source/${source}`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => e?.Result || undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private getAgencyApikeyFromProject(): Observable<string> {
    return this.getProject().pipe(
      map(e => e.widgets.filter(x => x.id_widget === '-1' || x.id_widget === '66').map(x => x.api_key)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAgencyApikey(): Observable<string> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(x => x ? this._postMessageService.getApi() : this.getAgencyApikeyFromProject()),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getPaymentApikey(): Observable<string> {
    return this.getAgencyApikey();
  }

  getQuotationApikey(): Observable<string> {
    return this._postMessageService.isB2B().pipe(
      mergeMap(e => e ? this.getAgencyNetwork()
        .pipe(
          map(x => x?.id_network ? parseInt(x.id_network, 10) === Networks.GEO : undefined),
          mergeMap(x => x ? this._environmentService.getNetworkApikey() : this.getAgencyApikey())
        ) : this.getAgencyApikey()),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAgencyInfoById(agencyId: number | string, networkId: number | string): Observable<any> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._environmentService.getOtoApikey(),
    ]).pipe(
        mergeMap(e => {
          const [environment, apiKey] = e;
          const url = `${environment.otoApi2UrlDynamic}agency/nauth/${apiKey}/agencyInfoByExternalId/${agencyId}/networkId/${networkId}`;
          if (!this.httpCache.get(url)) {
            this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
          }
          return this.httpCache.get(url);
        }),
        tap(resolved => {
          if (!resolved || (resolved && resolved.error)) {
            throw 'Errore durante chiamata recupero info del network';
          }
        }),
        map(resolved => resolved.result)
    );
  }

  getLoggedUser(token: string): Promise<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });
    const url = `${environment.otoApi2UrlDynamic}users`;
    const key = url + JSON.stringify(token);

    if (!this.httpCache.get(key)) {
      this.httpCache.set(key, this._http.get<any>(url, { headers: httpHeaders }).pipe(shareReplay({bufferSize: 1, refCount: true})));
    }

    return this.httpCache.get(key).pipe(
      map(resp => resp.result)
    ).toPromise();
  }

}
