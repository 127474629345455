import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isWidget } from './helpers/widget-helper';
import {StoreLocatorWidgetGuard} from './modules/shared/guards/store-locator-widget.guard';

const routes: Routes = [
  {
    path: '',
    data: { name: 'Home' },
    loadChildren: () => (isWidget()) ?
        import('./modules/widget/widget.module').then(m => m.WidgetModule) :
        import('./modules/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'smartbox',
    data: { name: 'Smartbox', canNavigate: false },
    loadChildren: () => import('./modules/smartbox/smartbox.module').then(m => m.SmartboxModule)
  },
  {
    path: 'vacanze',
    data: { name: 'Vacanze' },
    loadChildren: () => import('./modules/booking-flow/holiday/holiday.module').then(m => m.HolidayModule)
  },
  {
    path: 'tour',
    data: { name: 'Proposte' },
    loadChildren: () => import('./modules/booking-flow/tour/tour.module').then(m => m.TourModule)
  },
  {
    path: 'bus',
    data: { name: 'Bus' },
    loadChildren: () => import('./modules/booking-flow/bus/bus.module').then(m => m.BusModule)
  },
  {
    path: 'crociere',
    data: { name: 'Crociere' },
    loadChildren: () => import('./modules/booking-flow/cruise/cruise.module').then(m => m.CruiseModule)
  },
  {
    data: { name: 'merlinx', canNavigate: true },
    path: 'm',
    loadChildren: () => import('./modules/merlinx/merlinx.module').then(m => m.MerlinxLandingPageModule)
  },
  {
    path: 'landing',
    data: { name: 'Landing' },
    loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: 'cataloghi',
    data: { name: 'Cataloghi' },
    loadChildren: () => import('./modules/catalog/catalog.module').then(m => m.CatalogModule)
  },
  {
    path: 'store-locator-frame',
    data: { name: 'Store Locator' },
    canActivate: [StoreLocatorWidgetGuard],
    loadChildren: () => import('./modules/pages/store-locator-page/store-locator-page.module').then(m => m.StoreLocatorPageModule)
  },
  {
    path: '**',
    data: { name: '404', canNavigate: false },
    loadChildren: () => import('./modules/pages/notfound-page/notfound-page.module').then(m => m.NotfoundPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
