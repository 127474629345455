<div id="ui-modal" *ngIf="activeModal && activeModal.visible && activeModal.type && activeModal.dataSource">
  <div #uiModalBody class="ui-modal__body {{activeModal.type}}">
    <div class="ui-modal__close" (click)="close()"><i class="icon-close"></i></div>
    <div #uiModalContent class="ui-modal__content">
      <ng-container [ngSwitch]="activeModal.type">

        <ng-container *ngSwitchCase="'experience-detail'">
          <experience-detail [dataSrc]="activeModal.dataSource"></experience-detail>
        </ng-container>

        <ng-container *ngSwitchCase="'image-gallery'">
          <image-gallery [dataSrc]="activeModal.dataSource"></image-gallery>
        </ng-container>

        <ng-container *ngSwitchCase="'room-detail'">
          <room-detail [dataSrc]="activeModal.dataSource"></room-detail>
        </ng-container>

        <ng-container *ngSwitchCase="'structure-detail'">
          <structure-detail [dataSrc]="activeModal.dataSource"></structure-detail>
        </ng-container>

        <ng-container *ngSwitchCase="'bus-stops'">
          <app-bus-stops-modal [dataSrc]="activeModal.dataSource"></app-bus-stops-modal>
        </ng-container>

        <ng-container *ngSwitchCase="'packet-detail'">
          <packet-detail [dataSrc]="activeModal.dataSource"></packet-detail>
        </ng-container>

      </ng-container>

    </div>
  </div>
</div>
