import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from 'src/app/services/modal.service';

@Component({
  selector: 'room-detail',
  templateUrl: './room-detail.component.html',
  styleUrls: ['./room-detail.component.scss']
})
export class RoomDetailComponent implements OnInit {
  @Input() dataSrc;

  constructor(
    private _modalService: ModalService
  ) { }

  ngOnInit(): void {
  }
}
