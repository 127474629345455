<div class="modal-gallery">
  <div class="modal-gallery__header">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-xl-6">
        <h4 class="modal-gallery__title">Galleria</h4>
        <div
          class="modal-gallery__close"
          (click)="activeModal.dismiss('Cross click')"
        >
          <i class="icon-close"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-xl-6">
        <div class="c-gallery pb-3" lightbox-group>
          <div class="c-gallery__item" *ngFor="let chunck of this.chunks">
            <div class="c-gallery__image">
              <a *ngFor="let img of chunck" class="gallery-image">
                <picture>
                  <img
                    lightbox
                    [fullImage]="{ path: img }"
                    [counter]="true"
                    closeButtonText="Chiudi"
                    imageMaxHeight="90"
                    src="{{ img }}"
                    alt=""
                    loading="lazy"
                    (error)="removeImage($event)"
                  />
                </picture>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mb-4 pt-4 pt-xl-7" *ngIf="noImages">
      <div class="col-12 col-md-10 col-xl-6">
        <div class="alert alert-warning" role="alert">
          Nessuna immagine disponibile nella galleria
        </div>
      </div>
    </div>
  </div>
</div>
