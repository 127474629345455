import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from 'rxjs';
import {CruiseBookingService} from '../../booking-flow/cruise/cruise-booking-page/services/cruise-booking.service';
import {ICruiseDetailCabin} from '../../booking-flow/cruise/cruise-detail-page/interfaces/icruise-detail-cabin';

@Pipe({
  name: 'cabinDescriptionByFare'
})
export class CabinDescriptionByFarePipe implements PipeTransform {

  constructor(private _cruiseBookingService: CruiseBookingService) {
  }

  transform(value: ICruiseDetailCabin, tourOperatorId: number): Observable<{ description: string, elements: string[] }> {
    if (value && tourOperatorId) {
      return this._cruiseBookingService.getFareInfo({cabin: value, tourOperatorId});
    }
    return of(null);
  }

}
