<div class="c-image-gallery">
  <div class="c-image-gallery__content">
    <div class="detail-carousel">
      <swiper [config]="config" [(index)]="index" class="c-detail-slider">
        <div class="c-detail-slider-item" *ngFor="let image of dataSrc.elements">
          <div class="c-detail-slider-item__bg c-detail-slider-item__overlay"
               [ngStyle]="{'background-image': 'url(' + (image | cdnify) + ')'}">
          </div>
        </div>
      </swiper>
    </div>
    <div class="detail-carousel-thumbnails">
      <swiper [config]="configThumbs" [(index)]="index" class="c-detail-slider-thumbs">
        <div class="c-detail-slider-thumbs__img"  *ngFor="let image of dataSrc.elements; let i = index" [ngClass]="{'is-selected' : i == this.index}" (click)="changeSlide(image)">
          <img [src]="(image | cdnify)" alt="" >
        </div>
      </swiper>
    </div>
  </div>
</div>
