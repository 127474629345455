import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {

  transform<T>(value: T[], num: number): T[] {
    return value && num ? value.slice(0, num) : value;
  }

}
