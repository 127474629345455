import {Pipe, PipeTransform} from '@angular/core';
import {IHolidayDetailOperative} from '../../booking-flow/holiday/holiday-detail-page/interfaces/iholiday-detail-operative';

@Pipe({
  name: 'operativeDirection'
})
export class OperativeDirectionPipe implements PipeTransform {


  transform(value: IHolidayDetailOperative[], type: 'ANDATA' | 'RITORNO'): IHolidayDetailOperative {
    return value ? value.find(e => e.type === type) : undefined;
  }

}
