import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'diffPrice'
})
export class DiffPricePipe implements PipeTransform {

  transform(value: unknown, otherPrice): unknown {
    let currentPrice = Number(value);
    let cheaper = currentPrice < otherPrice;
    let difference = Math.floor(Math.abs(currentPrice - otherPrice));
    let text = (difference === 0) ? '' : `${cheaper ? '-' : '+' } ${difference} €`;

    return {
      cheaper,
      difference,
      text : Number.isNaN(currentPrice) ? '' : text
    };
  }

}
