export default function uniqBy<T>(inputArray: T[], predicate: ((value: T) => any) | string | number): T[] {
  if (!Array.isArray(inputArray) || inputArray.length < 1) {
    return [];
  }

  const cb = typeof predicate === 'function' ? predicate : (element) => element[predicate];

  const pickedObjects = inputArray
    .filter(item => item)
    .reduce((map, item) => {
      const key = cb(item);
      if (!key) {
        return map;
      }
      return map.has(key) ? map : map.set(key, item);
    }, new Map())
    .values();

  return [...pickedObjects];
}
