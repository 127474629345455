import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TemplatesService} from '../../services/templates.service';
import {CompilerService} from '../../services/compiler.service';
import {MailerService} from '../../services/mailer.service';
import {TemplateTypes} from '../../models/template-types.enum';
import { map, mergeMap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import {ProjectService} from '../../services/project.service';
import {ModalGenericInfoComponent} from '../modal-generic-info/modal-generic-info.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CmsService} from "../../../cms/services/cms.service";
import {NavigationService} from "../../../../services/navigation.service";
import {LandingService} from '../../../landing/landing.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-info-request',
  templateUrl: './info-request.component.html',
  styleUrls: ['./info-request.component.scss']
})
export class InfoRequestComponent implements OnInit, OnDestroy {
  @Input() packetInfo: string;
  @Input() packetUrl: string;
  @Input() isLanding: string;

  profile$: Observable<any>;
  name: string;
  surname: string;
  email: string;
  telephone: number;
  agencyInfoForLanding: any;
  passengers = 2;
  ages: number[] = [35, 35];

  message: string;
  messageClass: string;

  openFormInfo = true;
  loadingStatus = false;
  loadingMessage = 'Invio Richiesta in corso...';

  isWTStore: boolean = false;
  isWTShop: boolean = false;
  isWtgNetworkSite: boolean = false; // sito network WTG

  private agencyInfoSubscription: Subscription;
  private siteUrlSubscription: Subscription;
  private infoSubscription: Subscription;

  constructor(
    private _modalService: NgbModal,
    private _templatesService: TemplatesService,
    private _compilerService: CompilerService,
    private _mailerService: MailerService,
    private _projectService: ProjectService,
    private _cmsService: CmsService,
    private _navigationService: NavigationService,
    private _landingService: LandingService
  ) { }

  ngOnInit(): void {
    this.profile$ = this._projectService.getProfile();
    this.siteUrlSubscription = this._cmsService.siteUrl$.subscribe(siteUrl => {
      if (siteUrl) {
        if (siteUrl.includes('wtstore.it')) {
          this.isWTStore = true;
        }
        if (siteUrl.includes('welcometravelshop.it')) {
          this.isWTShop = true;
        }
        if (siteUrl.includes('vacanzewelcometravel.it')) {
          this.isWtgNetworkSite = true;
        }
      }
    });
    this.agencyInfoSubscription = this._landingService.agencyInfo$.subscribe((agencyInfo) => {
      if (agencyInfo) {
        this.agencyInfoForLanding = agencyInfo;
      }
    })
  }

  setAgesArray($event): void {
    while ($event > this.ages.length) {
      this.ages.push(35);
    }
    while ($event < this.ages.length) {
      this.ages.pop();
    }
  }

  trackByAges(index: number): number {
    return index;
  }

  openPrivacy($event: any): void {
    $event?.preventDefault();
    if (this.isWTStore || this.isWTShop || this.isWtgNetworkSite) {
      this._navigationService.getRedirectUrl('/privacy-policy').then(
          (resolved) => {
            window.open(resolved.url, '_blank');
          }
      );
    } else {
      const templateToLoad = TemplateTypes.GEO_PRIVACY_CONTACTS;
      const title = 'Informativa privacy richiedi informazioni';

      this.openModalPrivacy(title, templateToLoad);
    }
  }

  submitRequest(): void {
    this.loadingStatus = true;
    this.infoSubscription = this._templatesService.getTemplate(TemplateTypes.INFO).pipe(
      map(e => this._compilerService.compile(e, {
        packetInfo: this.packetInfo,
        packetUrl: this.packetUrl,
        paxString: this.ages.join(', '),
        name: this.name,
        surname: this.surname,
        email: this.email,
        telephone: this.telephone
      })),
      mergeMap(e => forkJoin([
        of(e),
        this.profile$
      ])),
      mergeMap(e => this._mailerService.sendEmail({
        from: this.email,
        to: (environment.production) ? ((this.isLanding) ? this.agencyInfoForLanding.email1 : e[1].email_1) : environment.debugAgencyEmail,
        body: e[0],
        title: `Richiesta Informazioni per ${this.packetInfo}`
      }))
    ).subscribe(e => {
      this.loadingStatus = false;
      if (e) {
        this.message = 'Richiesta inviata con successo!';
        this.messageClass = 'alert-success';
        this.resetForm();
      } else {
        this.message = 'Si e\' verificato un\'errore';
        this.messageClass = 'alert-danger';
      }
    });
  }

  resetForm(): void {
    this.name = undefined;
    this.surname = undefined;
    this.telephone = undefined;
    this.email = undefined;
  }

  ngOnDestroy(): void {
    this.agencyInfoSubscription?.unsubscribe();
    this.infoSubscription?.unsubscribe();
    this.siteUrlSubscription?.unsubscribe();
  }

  private openModalPrivacy(title: string, templateToLoad: string) {
    const modalRef = this._modalService.open(ModalGenericInfoComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg'
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.template = templateToLoad;
  }

}
