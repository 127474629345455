<h2>Programma linea Bus</h2>
<p>Compagnia: <b>Flixbus</b></p>

<div class="c-bus-stops">
  <div class="c-bus-stops__header">
    <p>Programma linea Bus</p>
    <small>* gli orari verrano comunicati entro 14 giorni dalla partenza.</small>
  </div>
  <ul class="c-bus-stops__list">
    <li *ngFor="let station of dataSrc.stations" [class.is-active]="dataSrc.activeStation === station.Id">
      <div class="c-bus-stops__point" *ngIf="dataSrc.activeStation === station.Id">Punto selezionato</div>
      <div class="c-bus-stops-row">
        <div class="c-bus-stops-row__number">{{station.Progressive}}</div>
        <picture class="c-bus-stops-row__pic">
          <!-- <source srcset="https://cdn.otosrl.com/resources/zeus/dev/tour/80/e2669b3425a3bdaa4a2965b6b7d386fa97e9c1f0.webp" type="image/webp"> -->
          <source [srcset]="station.Image" type="image/jpeg">
          <img [src]="station.Image" loading="lazy" alt="" height="56" width="56">
        </picture>
        <div class="c-bus-stops-row__titles">
          <span class="c-bus-stops-row__title">{{station.Destination.name}}</span>
          <span class="c-bus-stops-row__info">{{station.Notes}}</span>
        </div>
        <div class="c-bus-stops-row__address">
          <i class="g-icon-place"></i>
          <span>{{station.Name}}</span>
        </div>
        <div class="c-bus-stops-row__time">
          <i class="g-icon-length"></i>
          <span>{{station.DepartureTime && station.DepartureTime.length > 0 ? station.DepartureTime : 'N.D.*'}}</span>
        </div>
      </div>
    </li>
  </ul>
</div>
