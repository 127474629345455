import {Pipe, PipeTransform} from '@angular/core';
import cdnify from '../../../utils/cdnify';

@Pipe({
  name: 'cdnify'
})

export class CdnifyPipe implements PipeTransform {

  transform(value: string): unknown {

    const decodedUrl = decodeURIComponent(value);

    return cdnify(decodedUrl);
  }

}
