import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {BehaviorSubject, Observable, forkJoin, throwError, of, Subscription} from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import { EnvironmentService } from '../../shared/services/environment.service';
import { ProjectService } from '../../shared/services/project.service';
import {Agenzia} from './interfaces/agenzia.interface';

@Injectable({
  providedIn: 'root',
})
export class StoreLocatorPageService implements Resolve<any> {
  storeList: Agenzia[] = [];
  storesList$: BehaviorSubject<Agenzia[]> = new BehaviorSubject<Agenzia[]>(this.storeList);

  selectedStore: Agenzia = null;
  selectedStore$ = new BehaviorSubject<Agenzia>(this.selectedStore);

  // apikey network wtg e geo (vanno utilizzati solo nello store locator in modalità widget)
  private networkKeys: {WTG: string, GEO: string} = {
    WTG: 'a4b5a27b37e815d85ee43db200c683a96a64ec49',
    GEO: 'eba9b9ee-e63b-4ae8-a3b2-26315e8908eb'
  };

  //  ApiKey che fanno riferimento al gruppo Zepponi
  private zepponiKeys: string[] = [
    '48484d89-5327-4bb7-9bf7-05afb1d709a5',
    '962caeb7-600f-4e51-94b2-0d12670623fa',
  ];

  private businessClassViaggiKey: string[] = [
    '50f788d4-5f36-460e-85df-d4b9f110b777',
    '46580f5c-2f34-4ee4-9535-98c8546d2365',
    '32dfffba-a397-4823-9c13-741e4d5e6225']

  private isBCV: boolean = false;
  public isBCV$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isBCV)
  constructor(
    private httpClient: HttpClient,
    private _environmentService: EnvironmentService,
    private _projectService: ProjectService
  ) {}

  resolve(): Observable<any> | Promise<any> | any {}

  getStores(): Subscription {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
    ])
      .pipe(
        mergeMap((e) => {
          // e[1] = '48484d89-5327-4bb7-9bf7-05afb1d709a5'; //  Decommentare per testare funzionamento Zepponi
          //  Zepponi fix
          this.isBCV = this.isBusinessClassViaggi(e[1][0])

          if (this.isZepponi(e[1])) {
            return this.getZepponiStores(e[0].otoApi2UrlDynamic);
          }
          const url = `${e[0].otoApi2UrlDynamic}agency/nauth/${e[1]}/geoPointProfiles`;
          return this.httpClient.get<any>(url);

        }),
        map((e) => e.result),
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError((err) => throwError(err))
      )
      .subscribe((storesList) => {
        // ordino le agenzie per sede operativa/legale
        this.storeList = storesList?.sort((agencyA, agencyB) => agencyB.isMaster - agencyA.isMaster) || [];
        //Se sede business class viaggi tornare solo la prima sede singola
        if(this.isBCV){
          const sede = storesList.find(store => store.apiKey === this.businessClassViaggiKey[0])
          const indexSede = storesList.indexOf(sede)
          this.storeList = storesList.slice(indexSede,1)
          this.isBCV$.next(this.isBCV)
        }
        this.storesList$.next(this.storeList);
      });
  }

  getAllNetworksStores(): Subscription {
    return this._environmentService.getEnvironment().pipe(
        mergeMap((e) => {
          const urlForWtg = `${e.otoApi2UrlDynamic}agency/nauth/${this.networkKeys.WTG}/geoPointProfiles`;
          const urlForGeo = `${e.otoApi2UrlDynamic}agency/nauth/${this.networkKeys.GEO}/geoPointProfiles`;
          return forkJoin([this.httpClient.get<any>(urlForWtg), this.httpClient.get<any>(urlForGeo)]);
        }),
        map((results) => {
            const [wtgResult, geoResult] = results;
            return [...wtgResult.result, geoResult.result].flat();
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError((err) => throwError(err))
    ).subscribe((allNetworkStores) => {
        // ordino le agenzie per sede operativa/legale
        this.storeList = allNetworkStores?.sort((agencyA, agencyB) => agencyB.isMaster - agencyA.isMaster) || [];
        this.storesList$.next(this.storeList);
    });
  }

  //  Controlla se la apiKey passata appartiene al gruppo Zepponi
  isZepponi(apiKey: string[] | string) {
    return this.zepponiKeys?.includes(apiKey[0]);
  }

  //  Per il gruppo Zepponi è necessario applicare una logica su misura: se l'Api Key chiamante è una delle due che fanno riferimeto alle agenzie del network..
  //  ... bisogna chiamarle entrambe, per poi restituire gli stores che fanno riferimento sia all'una che all'altra.
  getZepponiStores(endPoint) {
    return forkJoin([
      this.httpClient.get<any>(
        `${endPoint}agency/nauth/${this.zepponiKeys[0]}/geoPointProfiles`
      ),
      this.httpClient.get<any>(
        `${endPoint}agency/nauth/${this.zepponiKeys[1]}/geoPointProfiles`
      ),
    ]).pipe(
      mergeMap((e) => {
        const mergedResultsObj = {
          result: [...e[0].result, ...e[1].result],
        };
        return of(mergedResultsObj);
      })
    );
  }

  //Controlla se la apiKey passata appartiene al gruppo Business class viaggi
  isBusinessClassViaggi(apyKey: string){
    return this.businessClassViaggiKey.includes(apyKey)
  }
}
