import {Injectable} from '@angular/core';
import {isWidget} from 'src/app/helpers/widget-helper';
import {Observable, of, throwError} from 'rxjs';
import {catchError, shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  private isWidget$ = of(isWidget()).pipe(
    shareReplay({bufferSize: 1, refCount: true}),
    catchError(err => throwError(err))
  );

  constructor() { }

  isWidget(): Observable<boolean> {
    return this.isWidget$;
  }
}
