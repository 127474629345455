//  Passato come value un array di età restituisce un 
//  oggetto conenente un array per le età degli adulti 
//  ed un array per le età dei bambini

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatAges',
  pure: false
})
export class FormatAgesPipe implements PipeTransform {

  transform(value: number[], type: string): any {
    let adults = [];
    let children = [];

    value.forEach(age => {
      if (age < 18) {
        children.push(age);
      } else {
        adults.push(age);
      }
    })

    const adultsNumber = adults.length;
    const childrenNumber = children.length;

    const oneAdult = adultsNumber === 1;
    const oneChild = childrenNumber === 1;

    const adultString = `${adults.length} adult${oneAdult ? 'o' : 'i'}`;
    const childrenString = childrenNumber ? `${childrenNumber} bambin${oneChild ? 'o' : 'i'}` : '';

    let childrenAgesString = ` (`;
    children.forEach((age, index, array) => {
      if (index === 0) {
        childrenAgesString += `${age}`;
      } else if (index === array.length - 1) {
        childrenAgesString += ` e ${age} `;
      } else {
        childrenAgesString += `, ${age}`;
      }
    });
    childrenAgesString +=  ` anni)`;

    const formattedString = {
      'adN_chN': `${adultString} ${ childrenNumber ?  'e' : ''} ${childrenString} `,
      'adN_chN_ages': `${adults.length} adult${oneAdult ? 'o' : 'i'} ${childrenNumber ?  'e ' + childrenString : ''} ${childrenNumber ? childrenAgesString : ''}`,
      'guestN': `${adultsNumber + childrenNumber}`,
      'full_formatted' : `<strong>${adults.length} adult${oneAdult ? 'o' : 'i'}</strong> ${childrenNumber ?  'e <strong>' + childrenString + '</strong>': ''} ${childrenNumber ? childrenAgesString : ''}`,
    }
    return formattedString[type];
    
  }

}
