import { Pipe, PipeTransform } from '@angular/core';
import {ICruiseDetailCabin} from '../../booking-flow/cruise/cruise-detail-page/interfaces/icruise-detail-cabin';

@Pipe({
  name: 'removeDuplicateCabins'
})
export class RemoveDuplicateCabinsPipe implements PipeTransform {

  transform(value: ICruiseDetailCabin[]): ICruiseDetailCabin[] {
    // filtra le cabine in base alla tariffa presente se "geo" o "wel" (per maggiori informazioni vedere il macro ticket #23794)
    // NB: in questo modo, vengono rimosse le stesse cabine ma con le tariffe non dedicate al network (se queste sono presenti)
    if (value.some(cabin => cabin.fare.includes('geo'))){
      return value.filter(cabin => cabin.fare.includes('geo'));
    } else if (value.some(cabin => cabin.fare.includes('wel'))){
      return value.filter(cabin => cabin.fare.includes('wel'));
    }
    return value;
  }

}
