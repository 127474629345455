<div class="c-structure-detail">
  <div class="c-structure-detail__container u-scrollbar">
    <div class="c-structure-detail__header">
      <h4 class="c-structure-detail__title">{{dataSrc.title}}
        <ng-container *ngIf="dataSrc.accomodation && dataSrc.accomodation.category">
          <span>{{dataSrc.accomodation.category.value}}</span>
          <span class="pl-1">
            <i [ngClass]="dataSrc.accomodation.category.icon"></i>
          </span>
          <span>{{dataSrc.accomodation.category.extra}}</span>
        </ng-container>
      </h4>
      <h6><i class="icon-pin"></i> {{(dataSrc.destination | getDataFromMapping:'destination' | async)?.Name}}</h6>
    </div>
    <image-gallery [dataSrc]="dataSrc"></image-gallery>
    <div class="c-structure-detail__content">
      <div *ngIf="!dataSrc.accomodation.texts || (dataSrc.accomodation.texts && dataSrc.accomodation.texts.length === 0)">
        <i>Nessuna descrizione presente</i>
      </div>
      <div *ngIf="dataSrc.accomodation.texts && dataSrc.accomodation.texts.length > 0">
        <div class="row" *ngFor="let text of dataSrc.accomodation.texts | orderBy: 'order'">
          <div class="col-2">
            <h5>{{text.title}}</h5>
          </div>
          <div class="col-10" [innerHtml]="text.description">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
