import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, finalize, map, shareReplay, takeUntil } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal.service';
import { TemplatesService } from '../../services/templates.service';
import { TemplateTypes } from '../../models/template-types.enum';
import { CompilerService } from '../../services/compiler.service';
import { ProjectService } from '../../services/project.service';
import { Subject, Subscription, forkJoin, throwError } from 'rxjs';
import { ContentTypes } from '../../models/content-types.enum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-generic-info',
  templateUrl: './modal-generic-info.component.html',
  styleUrls: ['./modal-generic-info.component.scss']
})
export class ModalGenericInfoComponent implements OnInit {
  @Input() title: string;
  @Input() template: TemplateTypes;
  @Input() selectedAgencyApikey: string;

  public bodyToDisplay: SafeHtml;
  public isLoadingContent: boolean;

  public loadingMessage: string;
  private _unsubscription$ = new Subject();

  // attualmente (06-06-2024) ci interessa il caso particolare della newsletter
  public tempTypes = TemplateTypes;

  private profile$;

  constructor(
    public activeModal: NgbActiveModal,
    private _compilerService: CompilerService,
    private _projectService: ProjectService,
    private _modalService: ModalService,
    private _templateService: TemplatesService,
    private _sanitizer: DomSanitizer
  ) {
    this.profile$ = this._projectService.getProfile();
  }

  ngOnInit() {
    this.loadContent(this.selectedAgencyApikey);
  }

  loadContent(selectedAgency?: string) {
    this.loadingMessage = `Stiamo caricando ${this.title?.toLowerCase()} ...`;
    
    if(this.template !== this.tempTypes.GEO_PRIVACY_NEWSLETTER && 
      this.template !== this.tempTypes.WTISTITUZIONALE_NEWSLETTER) {
      // se il content che dobbiamo caricare nella modale non riguarda la newsletter
      this.isLoadingContent = true;
      forkJoin([
        this._templateService.getTemplate(this.template),
        this.profile$
      ]).pipe(
        map((templateToMap: any[]) => this._compilerService.compile(templateToMap[0], templateToMap[1])),
        finalize(() => this.isLoadingContent = false),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err)),
        takeUntil(this._unsubscription$)
      ).subscribe((template) => {
        this.bodyToDisplay = template;
      });
    } else {
      this.isLoadingContent = true;
      this._modalService.getContent(ContentTypes.NEWSLETTER, selectedAgency || undefined).
      pipe(
        takeUntil(this._unsubscription$)
      )
     .subscribe({
        next: (template: string ) => {
          this.bodyToDisplay = this._sanitizer.bypassSecurityTrustHtml(template);
        },
        error: (err: any) => {
          console.error(err);
        },
        complete: () => {
          this.isLoadingContent = false;
        }
      });

      // assegnazione mockata e simulazione di un loading
/*       setTimeout(() => {
        this.bodyToDisplay = this.newsletterString; // attualmente è quella di geo
        this.isLoadingContent = false;
      }, 2000); */
    }
  }
}
