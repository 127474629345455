import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-element-loader',
  templateUrl: './element-loader.component.html',
  styleUrls: ['./element-loader.component.scss']
})
export class ElementLoaderComponent implements OnInit, OnChanges {

  loadingMessageInner = '';
  loadingStatusInner = false;
  @Input() loadingStatus: boolean;
  @Input() loadingMessage: string;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadingStatusInner = changes.loadingStatus?.currentValue;
    this.loadingMessageInner = changes.loadingMessage?.currentValue;
  }

}
