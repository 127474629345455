import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { CruiseHarbourImagesService } from '../services/cruise-harbour-images.service';
import { HolidayDestinationImagesService } from '../services/holiday-destination-images.service';

@Pipe({
  name: 'imageByCode'
})
export class ImageByCodePipe implements PipeTransform {
  private imageType = {
    harbour: {
      fn: 'getHarboutImageByCode',
      service: this._cruiseHarbourImagesService
    },
    destination: {
      fn: 'getDestinationImageThumbnailByCode',
      service: this._holidayDestinationImagesService
    },
    top: {
      fn: 'getTourOperatorImageByCode',
      service: this
    },
  };

  constructor(private _cruiseHarbourImagesService: CruiseHarbourImagesService,
              private _holidayDestinationImagesService: HolidayDestinationImagesService) { }

  private getTourOperatorImageByCode(code): string {
    return `https://cdn.otosrl.com/images/touroperator/mini/${code}-${code}.png`;
  }

  transform(value: string, type: 'harbour' | 'destination' | 'top'): Observable<string> {
    return value ? this.imageType[type].service[this.imageType[type].fn](value) : undefined;
  }

}
