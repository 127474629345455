import { Injectable, Renderer2, RendererFactory2, Inject, Optional } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Subject} from "rxjs";
import {scriptLinks} from "../../assets/data/script-links";

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  private renderer: Renderer2;
  private scriptCache: Map<string, boolean> = new Map();
  private _scriptElement: HTMLScriptElement;
  private execTimeout: number;
  private execTime = 750;
  private execScript: HTMLScriptElement;

  public isScriptLoaded$ : Subject<boolean> = new Subject<boolean>();

  constructor(
    private rendererFactory: RendererFactory2,
    @Optional() @Inject(DOCUMENT) private document: any) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public loadScript(canLoadStaticScripts: boolean, src?: string, type?: string, location: 'head' | 'body' = 'body', defer?: boolean, async?: boolean, isAnalitics: boolean = false): void {
    if (!canLoadStaticScripts) {
      if (!this.scriptCache.has(src)) {
        this._loadScript(src, type, location, defer, async, isAnalitics);
      }
    } else {
      for (const el of scriptLinks) {
        if (!this.scriptCache.has(el.src)) {
          this._loadScript(el.src, el.scriptType, el.location, el.defer, el.async, el.isAnalitics);
        }
      }
    }
  }

  public loadWtgApplicationScript(scriptSrc: string, scriptType: string, defer: boolean, async: boolean): void {
    const script = this.renderer.createElement('script');
    script.src = scriptSrc;
    script.type = scriptType;
    script.id = "zuc-script";
    script.defer = defer;
    script.async = async;
    const target =  this.document.body;
    this._scriptElement = script;
    this.renderer.appendChild(target, this._scriptElement);

    this.execTimeout = setTimeout(() => {
      this.execScript = this.renderer.createElement('script');
      this.execScript.type = scriptType;
      this.execScript.innerHTML = `
        (() => {
          createRegistrationIFrame()
        })();
      `;
      this.renderer.appendChild(target, this.execScript);
      this.isScriptLoaded$.next(false);
    }, this.execTime);
  }

  public removeScript() {
    if (this._scriptElement && this._scriptElement.parentNode) {
      this._scriptElement.parentNode.removeChild(this._scriptElement);
      this.execScript.parentNode.removeChild(this.execScript);
      this._scriptElement = null;
      this.execScript = null;
      clearTimeout(this.execTimeout);
    }
  }

  private _loadScript(scriptSrc: string, scriptType: string, location: 'head' | 'body', defer: boolean, async: boolean, isAnalitics: boolean = false): void {
    const script = this.renderer.createElement('script');
    script.src = scriptSrc;
    script.type = scriptType;
    script.defer = defer;
    script.async = async;
    script.onload = () => {
      this.scriptCache.set(scriptSrc, true);
    };
    const target = location === 'head' ? this.document.head : this.document.body;

    this.renderer.appendChild(target, script);
    if(isAnalitics){
      this.addScriptGTM(this.extractGtmId(scriptSrc));
    }
  }


  private extractGtmId(scriptSrc: string): string {
    const url = new URL(scriptSrc);
    return url.searchParams.get('id');
  }

  private addScriptGTM(gtmId: string) {
    const script = this.renderer.createElement('script');

    const scriptContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', '${gtmId}');
    `;

    this.renderer.setProperty(script, 'textContent', scriptContent);
    this.renderer.appendChild(document.body, script);
  }

}
