export enum TemplateTypes {
  INFO = 'info-request',
  APPOINTMENT = 'appointment-request',
  QUOTATION = 'quotation-request',
  USER_QUOTATION = 'user-quotation-request',
  WTG_COOKIE = 'wtg-cookie',
  WTG_ISTITUZIONALE_COOKIE = 'wtg-istituzionale-cookie',
  GEO_COOKIE = 'geo-cookie',
  GEO_ISTITUZIONALE_COOKIE = 'geo-istituzionale-cookie',
  GEO_PRIVACY = 'geo-privacy',
  GEO_NETWORK_SITE_PRIVACY = 'geo-network-site-privacy',
  GEO_PRIVACY_CONTACTS = 'geo-privacy-contacts',
  GEO_PRIVACY_APPOINTMENT = 'geo-privacy-appointment',
  WTSHOP_STORE = 'wts-privacy-appointment',
  GEO_PRIVACY_NEWSLETTER = 'geo-privacy-newsletter',
  WTISTITUZIONALE_NEWSLETTER = 'wt-istituzionale-newsletter',
  GEO_PRIVACY_BOOKING = 'geo-privacy-booking',
  GEO_WT_PRIVACY_BOOKING_ADV = 'geo-wt-privacy-booking-adv',
  WTISTITUZIONALE_BOOKING = 'wts-privacy-booking',
  GEO_PRIVACY_PAYMENTS = 'geo-privacy-payments',
  GEO_EMAIL_CLIENT = 'geo-email-client',
  GEO_EMAIL_AGENCY = 'geo-email-agency',
  WTG_PRIVACY = 'wtg-privacy',
  WTG_NETWORK_SITE_PRIVACY = 'wtg-network-site-privacy',
  WTG_WTSUD_NETWORK_SITE_PRIVACY = 'wtg-wtsud-network-site-privacy',
  WTG_STORE_SITE_PRIVACY = 'wtg-store-site-privacy',
  WTG_SHOP_SITE_PRIVACY = 'wtg-shop-site-privacy',
  HOLIDAY_QUTATION = 'holiday-quotation',
  CRUISE_QUOTATION = 'cruise-quotation',
  TOUR_QUOTATION = 'tour-quotation',
  EXPERIENCE_QUOTATION = 'experience-quotation'
}
