import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'dateDifference'
})
export class DateDifferencePipe implements PipeTransform {

  transform(value: Date, secondDate: Date, unit: string = 'd'): number {
    // @ts-ignore
    return value && secondDate ? dayjs(secondDate).diff(value, unit) : undefined;
  }

}
