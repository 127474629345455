export const environment = {
  production: true,
  type: 'master',
  debugAgencyEmail : 'it@otosrl.com',
  cdnUrl: 'https://cdn.otosrl.com',
  otoApiUrl: 'https://api.offertetouroperator.com',
  searchUrl: 'https://api.offertetouroperator.com',
  otoApi2Url: 'https://api2.offertetouroperator.com',
  otoApi2UrlDynamic: 'https://api2.offertetouroperator.com/oto-api/',
  quotation: 'https://api2.offertetouroperator.com/oto-api/quotation',
  pageBuilder: 'https://api2.offertetouroperator.com/oto-api/generic/',
  site: 'https://api2.offertetouroperator.com/oto-api/generic/nauth/',
  prodApi: 'https://api2.offertetouroperator.com/oto-api/generic/nauth/',
  catalog: 'https://api2.offertetouroperator.com/oto-api/catalog-creator/nauth/',
  wtiSearchEngineB2b: 'https://b2b.welcometoitaly.com',
  wtiSearchEngineB2c: 'https://www.welcometoitaly.com',
  wtiHubcoreApiProxy: 'https://websites.offertetouroperator.com/~wtg-hubcore-proxy/dist',
  otoApiShipServiceUrl: 'https://api.offertetouroperator.com',
  geoListeInViaggioUrl: 'https://www.imieieventi.com/',
  wtgListeInViaggioUrl: 'https://listeinviaggio.vacanzewelcometravel.it/'
}
