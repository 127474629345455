import { registerLocaleData } from "@angular/common";
import localeItExtra from '@angular/common/locales/extra/it';
import localeIt from "@angular/common/locales/it";
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BottomBarComponent } from './modules/shared/components/bottom-bar/bottom-bar.component';
import { FooterBarComponent } from './modules/shared/components/footer-bar/footer-bar.component';
import { NavBarComponent } from './modules/shared/components/nav-bar/nav-bar.component';
import { PageLoaderComponent } from './modules/shared/components/page-loader/page-loader.component';
import { TopBarComponent } from './modules/shared/components/top-bar/top-bar.component';
import { SharedModule } from './modules/shared/shared.module';

registerLocaleData(localeIt, 'it', localeItExtra);
@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    NavBarComponent,
    BottomBarComponent,
    FooterBarComponent,
    PageLoaderComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'it'}],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
