import {Pipe, PipeTransform} from '@angular/core';
import chunk from '../../../utils/chunk';

@Pipe({
  name: 'chunk'
})
export class ChunkPipe implements PipeTransform {

  transform<T>(input: T[], size: number): T[][] {
    return input ? chunk(input, size) : null;
  }

}
