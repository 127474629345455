import {Pipe, PipeTransform} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'ngbDate'
})
export class NgbDatePipe implements PipeTransform {

  transform(value: NgbDateStruct, format: string): string {
    if (value) {
      const date = dayjs(`${value.day}-${value.month}-${value.year}`, 'D-M-YYYY');
      return date.format(format);
    }
    return null;
  }

}
