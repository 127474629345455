import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, mergeMap, shareReplay} from 'rxjs/operators';
import {PostMessageService} from '../services/post-message.service';
import * as DomainsForStoreLocatorWidget from '../../../../assets/data/domainsforstorelocatorwidget.json';

@Injectable({
  providedIn: 'root'
})
export class StoreLocatorWidgetGuard implements CanActivate {
  
  constructor(private _postMessageService: PostMessageService) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.isValidDomainForStoreLocatorWidget();
  }
  
  private isValidDomainForStoreLocatorWidget(): Observable<boolean> {
    let currentDomain = '';
    return this._postMessageService.isStoreLocatorWidget().pipe(
        mergeMap((isStoreLocatorWidget) => (isStoreLocatorWidget) ? this._postMessageService.getWebsite() : of(null)),
        mergeMap((hostname) => {
          if (!hostname) {
            return of([]);
          }
          currentDomain = hostname;
          return this.getDomainsForStoreLocatorWidget();
        }),
        map((domains) => (domains?.length) ? domains.includes(currentDomain) : false),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
    );
  }
  
  private getDomainsForStoreLocatorWidget(): Observable<string[]> {
    return of(DomainsForStoreLocatorWidget).pipe(
        map((domainsForStoreLocatorWidget: any) => domainsForStoreLocatorWidget?.default?.domains || []),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
    );
  }
}
