import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PostMessageService } from '../modules/shared/services/post-message.service';
import { WidgetService } from '../modules/shared/services/widget.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    protected _postMessageService: PostMessageService,
    protected _widgetService: WidgetService,
    protected _router: Router
  ) { }

  getBaseUrlSlug(): string {
    return document.querySelector('base').href;
  }

  getRedirectUrl(url: string): Promise<{url: string, isWidget: boolean}> {
    return new Promise<{url: string, isWidget: boolean}>((resolve, reject) => {
      Promise.all([
        this._widgetService.isWidget().toPromise(),
        this._postMessageService.isB2B().toPromise()
      ]).then(
        (resolved) => {
          if (resolved[0] && !resolved[1]) {
            this._postMessageService.getHref().toPromise().then(
              (href) => {
                href = href.split('#')[0];
                resolve({
                  url: url,
                  isWidget: true
                });
              }
            );
          } else {
            resolve({
              url: url.indexOf(this.getBaseUrlSlug()) >= 0 ? url : `${this.getBaseUrlSlug()}${url}`,
              isWidget: false
            });
          }
        }
      );
    });
  }

  public goTo(path) {
    this._router.navigate([path]);
  }
}
