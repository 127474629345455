import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'costaOnTop'
})
export class CostaOnTopPipe implements PipeTransform {
  transform(items: any[], key: string | 'element.value.name'): any[] {
    if (!items || !Array.isArray(items)) {
      return [];
    }
    
    const costaArray = items.filter(elem => this.getKeyValue(elem, key).includes("Costa"));
    const otherArr = items.filter(elem => !this.getKeyValue(elem, key).includes('Costa'));
    return [...costaArray, ...otherArr];
  }
  
  private getKeyValue(elem, key){
    if(key === 'element.value.name'){
      return elem.element.value?.name;
    } else return elem[key];
  }

}

