<div class="c-room-detail">
  <div class="c-room-detail__container u-scrollbar">
    <div class="c-room-detail__header">
      <h4 class="c-room-detail__title">{{dataSrc.title}}</h4>
      <h6>Tipologia: {{dataSrc.typology}}</h6>
    </div>
    <i *ngIf="!dataSrc.elements || (dataSrc.elements && dataSrc.elements.length === 0)">Nessuna foto presente</i>
    <image-gallery
      [dataSrc]="dataSrc"
      *ngIf="dataSrc.elements?.length > 0"
    ></image-gallery>
    <div class="c-room-detail__content">
      <i *ngIf="!dataSrc.description">Nessuna descrizione presente</i>
      <div class="row" *ngIf="dataSrc.description">
        <div class="col-2">
          <h5>Descrizione</h5>
        </div>
        <div class="col-10" [innerHtml]="dataSrc.description"></div>
      </div>
      <hr class="dashed-border">
      <i *ngIf="!dataSrc.specials || (dataSrc.specials && dataSrc.specials.length === 0)">Nessun servizio presente</i>
      <div class="row" *ngIf="dataSrc.specials?.length > 0">
        <div class="col-2">
          <h5>Servizi</h5>
        </div>
        <div class="col-10">
          <div class="e-list-services e-list-services-wrap">
            <ul>
              <li *ngFor="let special of dataSrc.specials">{{special.name}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
