import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import { RemoteMappingItem } from '../interfaces/remote-mapping-item';
import { EnvironmentService } from './environment.service';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  constructor(private _http: HttpClient,
              private _projectService: ProjectService,
              private _environmentService: EnvironmentService) {
  }

  httpCache: Map<string, Observable<any>> = new Map<string, Observable<any>>();

  getHolidayMappings(): Observable<any> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].searchUrl}/index.php/holiday/${e[1]}/mapping/all`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getCruiseMappings(): Observable<any> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].searchUrl}/index.php/cruise/${e[1]}/mapping/all`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getMappings(): Observable<any> {
    return forkJoin([
      this.getHolidayMappings(),
      this.getCruiseMappings(),
    ]).pipe(
      map(e => Object.assign({}, e[0], e[1])),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAccomodationByID(id: number): Observable<RemoteMappingItem> {
    return this.getMappings().pipe(
      map(e => e.accomodations.objects[id]),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAirportByID(id: number | string): Observable<RemoteMappingItem> {
    if (id === 'null') {
      const soloSoggiorno: RemoteMappingItem = {
        Id: undefined,
        Name: 'Solo Soggiorno',
        Code: undefined
      };
      return of(soloSoggiorno);
    }
    return this.getMappings().pipe(
      map(e => {
        if (id < 2000000 && id > 1000000) {
          return e.airports.objects[id];
        } else if (id < 3000000 && id > 2000000) {
          return e.harbours.objects[id];
        }
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAirportByCode(id: string): Observable<RemoteMappingItem> {
    if (id === 'null') {
      const soloSoggiorno: RemoteMappingItem = {
        Id: undefined,
        Name: 'Solo Soggiorno',
        Code: undefined
      };
      return of(soloSoggiorno);
    }
    return this.getMappings().pipe(
      map(e => {
        const aptArray = Object.values(e.airports.objects) as RemoteMappingItem[];
        return aptArray.find(x => x.Code === id);
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getBasisByID(id: number): Observable<RemoteMappingItem> {
    return this.getMappings().pipe(
      map(e => e.basis.objects[id]),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getParentDestinationByID(id: number): Observable<RemoteMappingItem> {
    return this.getMappings().pipe(
      map(e => e.destinations.objects[e.destinations.objects[id].ParentID]),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getDestinationByID(id: number): Observable<RemoteMappingItem> {
    return this.getMappings().pipe(
      map(e => e.destinations.objects[id]),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getHarbourByID(id: number): Observable<RemoteMappingItem> {
    return this.getMappings().pipe(
      map(e => e.harbours.objects[id]),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getHolidayTypeByID(id: number): Observable<RemoteMappingItem> {
    return this.getMappings().pipe(
      map(e => e.holidayTypes.objects[id]),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getHolidaySpecialByID(id: number): Observable<RemoteMappingItem> {
    return this.getMappings().pipe(
      map(e => e.holiday_specials.objects[id]),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getItineraryById(id: number): Observable<RemoteMappingItem> {
    return this.getCruiseMappings().pipe(
      map(e => e.itineraries.objects[id]),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getShipyID(id: number): Observable<RemoteMappingItem> {
    return this.getMappings().pipe(
      map(e => e.ships.objects[id]),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getTourOperatorByID(id: number): Observable<RemoteMappingItem> {
    return this.getMappings().pipe(
      map(e => e.tops.objects[id]),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getTrainByID(id: number): Observable<RemoteMappingItem> {
    return this.getMappings().pipe(
      map(e => e.train.objects[id]),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }
}
