import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  private isMobile$ = new BehaviorSubject<boolean>(false);
  private toggleSideBar = new BehaviorSubject<boolean>(false);
  private isInitialized = false;

  toggleSideBar$ = this.toggleSideBar.asObservable()

  constructor(private breakpointObserver: BreakpointObserver) {
    if (!this.isInitialized) {
      this.isInitialized = true;
      this.viewportListener();
    }
  }

  isMobile(): Observable<boolean> {
    return this.isMobile$;
  }

  viewportListener(): void {
    this.breakpointObserver.observe(['(max-width: 991.98px)']).subscribe((e: BreakpointState) => {
      if (e.matches) {
        this.isMobile$.next(true);
      } else {
        this.isMobile$.next(false);
      }
    });
  }


  updateSidebarToggle( bool : boolean){
    this.toggleSideBar.next(bool)
  }
}
