import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from '../../../../services/modal.service';

@Component({
  selector: 'packet-detail',
  templateUrl: './packet-detail.component.html',
  styleUrls: ['./packet-detail.component.scss']
})
export class PacketDetailComponent implements OnInit {

  @Input() dataSrc;

  constructor(
    private _modalService: ModalService
  ) { }

  ngOnInit(): void {
  }

}
