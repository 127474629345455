import {Injectable} from '@angular/core';
import {EnvironmentService} from './environment.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {IHolidayDestinationImage} from '../interfaces/iholiday-destination-image';

@Injectable({
  providedIn: 'root'
})
export class HolidayDestinationImagesService {

  constructor(private _environmentService: EnvironmentService,
              private _http: HttpClient) {
  }

  httpCache = new Map<string, Observable<any>>();

  getDestinationImageThumbnailByCode(code: number): Observable<string> {
    return this.getFormattedDestinationImages().pipe(
      map(e => {
        const image = e.find(x => x.code === code);
        if (!image) {
          return (`assets/images/placeholders/${Math.floor(Math.random() * 10)}.png`);
        }
        return image.thumbnail;
      }),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  formatDestinationImage(image: string): IHolidayDestinationImage {
    const [code, name] = image.replace(/\.(jpg|png)/ig, '').split('_');
    return {
      code: parseInt(code, 10),
      full: `https://cdn.otosrl.com/images/destinazione/${image}`,
      thumbnail: `https://cdn.otosrl.com/images/destinazione/thumbnails/${image}`
    };
  }

  getFormattedDestinationImages(): Observable<IHolidayDestinationImage[]> {
    return this.getDestinationImages().pipe(
      map(e => e.map(x => this.formatDestinationImage(x))),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  getDestinationImages(): Observable<any> {
    const url = 'https://www.offertetouroperator.com/images/destinazione/list_images.php';
    if (!this.httpCache.get(url)) {
      this.httpCache.set(url, this._http.get<any>(url).pipe(
        map(e => e.images),
        shareReplay({bufferSize: 1, refCount: true})
      ));
    }
    return this.httpCache.get(url);
  }
}
