import { Pipe, PipeTransform } from '@angular/core';
import { IHolidayDetailFlight } from '../../booking-flow/holiday/holiday-detail-page/interfaces/iholiday-detail-flight';

@Pipe({
  name: 'aptDirection'
})
export class AptDirectionPipe implements PipeTransform {


  transform(value: IHolidayDetailFlight[], type: 'ANDATA' | 'RITORNO'): IHolidayDetailFlight {
    return value ? value.find(e => e.type === type) : undefined;
  }

}
