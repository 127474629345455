import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss']
})
export class GalleryModalComponent implements OnInit, AfterViewInit {

  @Input() elements: string[];
  @Input() selectedElement: string;

  noImages : boolean;
  chunks : any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.chunks = this.chunk(this.elements, [1, 2, 2]);
    if( !this.selectedElement) return;
    setTimeout( ()=> {
      const imgs = Array.from(document.querySelectorAll(".lightbox-single") as unknown as HTMLAllCollection);
      const matchingImg = imgs.find(img => img.getAttribute('src') === this.selectedElement) as HTMLElement;
      if(!matchingImg) return;
      matchingImg.click();
    }, 150);
  }

  ngAfterViewInit(): void {
    this.checkImages();
  }

  chunk(cards: string[], chunksizes: number[]) {
    const chunkArray = [];
    let cc = 0;
    let i = 0;
    while (i < cards.length) {
      const csize = chunksizes[cc];
      chunkArray.push(cards.slice(i, i + csize));
      cc = (cc + 1) % chunksizes.length;
      i += csize;
    }
    return chunkArray;
  }

  public removeImage(event: any) {
    event.target.parentNode.parentNode.remove();
    this.checkImages();
  }

  private checkImages() {
    const images = document.querySelectorAll('.gallery-image');
    if (images?.length === 0) {
      this.noImages = true;
    }
  }

}
