import { Injectable, OnDestroy } from '@angular/core';
import { ProjectService } from '../../shared/services/project.service';
import { Observable, BehaviorSubject, throwError, Subject, of } from 'rxjs';
import {catchError, map, takeUntil, shareReplay, finalize} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {LoadingItem} from "../../shared/interfaces/loading-item";
import {LoadingService} from "../../shared/services/loading.service";

@Injectable({
  providedIn: 'root'
})
export class CmsService implements OnDestroy {

  private apiKey;
  private siteUrl;
  private api;

  private unsubscribe$ = new Subject;
  public mobileBreakpoint: number = 600;
  public mobileView$: BehaviorSubject<boolean>;

  public currentPage$: BehaviorSubject<any>;
  public siteMap$: BehaviorSubject<any>;
  public allNews$: BehaviorSubject<any>;
  public siteUrl$: BehaviorSubject<any>;
  public brandConfig$: BehaviorSubject<any>;
  public allNews = null;
  public siteMap = null;
  public hasCmsEnabled$: BehaviorSubject<boolean>;
  public showDevPanel$: BehaviorSubject<boolean>;
  public hasPropostePage$: BehaviorSubject<boolean>;
  public hasConad$: BehaviorSubject<boolean>;
  public hasWelgoService$: BehaviorSubject<boolean>;

  // TEMP
  private networkDomains = 'www.geotn.it';
  private networkKeys: {WTG: string, GEO: string} = {
    WTG: 'a4b5a27b37e815d85ee43db200c683a96a64ec49',
    GEO: 'eba9b9ee-e63b-4ae8-a3b2-26315e8908eb'
  };

  constructor(
    private _projectService: ProjectService,
    public _breakpointObserver: BreakpointObserver,
    private _http: HttpClient,
    private _loadingService: LoadingService
  ) {
    this.showDevPanel$ = new BehaviorSubject<any>(false);
    this.siteUrl$ = new BehaviorSubject<any>(this.siteUrl);
    this.siteMap$ = new BehaviorSubject<any>(this.siteMap);
    this.allNews$ = new BehaviorSubject<any>(this.allNews);
    this.currentPage$ = new BehaviorSubject<any>(null);
    this.hasCmsEnabled$ = new BehaviorSubject<boolean>(false);
    this.brandConfig$ = new BehaviorSubject<any>(null);
    this.hasPropostePage$ = new BehaviorSubject<any>(null);
    this.hasConad$ = new BehaviorSubject<boolean>(null);
    this.hasWelgoService$ = new BehaviorSubject<any>(null);

    this.mobileView$ = new BehaviorSubject<any>(false);

    this._breakpointObserver
      .observe([`(min-width: ${this.mobileBreakpoint}px)`])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileView$.next(false);
        } else {
          this.mobileView$.next(true);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public initialise(): void {
    console.log("=== INITIALISE CMS ===");

    const loadingItem: LoadingItem = { id: 'load-profile-for-cms', message: 'Apertura sito in corso . . .' };
    this._loadingService.addLoad(loadingItem);
    this._projectService.getProject().pipe(
      takeUntil(this.unsubscribe$),
      catchError(err => throwError(err)),
      finalize(() => this._loadingService.removeLoad(loadingItem))
    ).subscribe(x => {
      const hasWelgo = x.services?.some((service) => service.service_type === "Welgo");
      this.hasWelgoService$.next(hasWelgo);

      this.apiKey = x.widgets.filter(x => x.id_widget === '-1' || x.id_widget === '66').map(x => x.api_key);
      this.siteUrl = x.project.domain;
      this.siteUrl$.next(this.siteUrl);
      this.api = `${environment.site}${this.apiKey}`;
      this.getHomePage().pipe(takeUntil(this.unsubscribe$)).subscribe(home => {
        if (home != undefined && !home) {
          console.log("CMS NOT ENABLED");
          this.hasCmsEnabled$.next(false);
        } else {
          console.log("CMS ENABLED");
          this.hasCmsEnabled$.next(true);

          if (!environment.production) {
            this.showDevPanel$.next(true);
          }
          this.getSitemap().pipe(takeUntil(this.unsubscribe$)).subscribe(siteMap => {
            if (!siteMap) {
              console.log("SITEMAP NOT AVAILABLE");
            } else {
              this.siteMap = siteMap;
              const hasProposte = this.siteMap.find(x => x.hierarchyLevel == 1 && x.type.basePath == 'le-nostre-proposte');
              const hasConad = this.siteMap.find(x => x.hierarchyLevel == 2 && x.type.basePath == 'conad');
              this.hasPropostePage$.next(hasProposte != undefined);
              this.hasConad$.next(hasConad != undefined);
              this.siteMap$.next(siteMap);
              const allNews = this.siteMap.filter(x => x.type.id == 2);
              allNews.sort((a, b) => {
                return (a.progressive < b.progressive) ? -1 : (a.progressive > b.progressive) ? 1 : 0;
              }).sort((a, b) => {
                return (a.isNetworkPage > b.isNetworkPage) ? -1 : (a.isNetworkPage < b.isNetworkPage) ? 1 : 0;
              });
              //this.allNews = this.isNetworkDomanin(this.siteUrl) ? allNews.filter(x => !x.isNetworkPage) : allNews;
              this.allNews = allNews;
              this.allNews$.next(this.allNews);
            }
          });
        }
      });


    });
  }

  public getPages(): any {
    if (this.siteMap) {
      return this.siteMap;
    } else {
      this.getSitemap().pipe(takeUntil(this.unsubscribe$)).subscribe(siteMap => {
        if (!siteMap) {
          console.log("SITEMAP NOT AVAILABLE");
          return null;
        } else {
          this.siteMap = siteMap;
          this.siteMap$.next(siteMap);
          return this.siteMap;
        }
      });
    }

  }

  public getHomePage(loadingItemId: string = 'homepage-contents'): Observable<any> {
    const loadingItem: LoadingItem = { id: loadingItemId, message: 'Apertura sito in corso . . .' };
    const siteSlim = `${this.api}/homePageSlim?domain=${this.siteUrl}`;
    this._loadingService.addLoad(loadingItem);
    this._loadingService.addSkeletonLoad(loadingItemId);
    return this._http.get(siteSlim).pipe(
      map((site: any)=> {
        if (site.error) {
          return null;
        }
        const home = site.result.pages.find(x => x.hierarchyLevel == 0);
        this.currentPage$.next(home);
        return home;
      }),
      finalize(() => {
        this._loadingService.removeLoad(loadingItem);
        this._loadingService.removeSkeletonLoad(loadingItemId);
      })
    );
  }

  // TODO
  // public getNews(): Observable<any> {
  //   const pagesApi = `${this.api}/site/page?typeId=${PageType.News}`;
  //   return this._http.get(pagesApi).pipe(
  //     map((news: any)=> {
  //       if (news.error) {
  //         return null;
  //       }
  //       news.result.sort((a, b) => {
  //         return (a.progressive < b.progressive) ? -1 : (a.progressive > b.progressive) ? 1 : 0;
  //       });
  //       return news.result;
  //     })
  //   );
  // }


  public getPageById(id, network?): Observable<any> {
    const loadingItem: LoadingItem = { id: 'page-content', message: 'Apertura pagina in corso . . .' };
    let pageApi = `${this.api}/pageSlim?pageId=${id}`;
    console.log("THIS API", this.api);
    if (this.api == undefined) {
      const networkApiKey = network == 46 ? this.networkKeys.GEO : this.networkKeys.WTG;
      pageApi = `${environment.site}${networkApiKey}/pageSlim?pageId=${id}`;
      console.log("API UNDEFINED NEW API", pageApi);
    }

    this._loadingService.addLoad(loadingItem);
    return this._http.get(pageApi).pipe(
      map((page: any)=> {
        if (page.error) {
          return null;
        }
        return page.result;
      }),
      finalize(() => this._loadingService.removeLoad(loadingItem))
    );
  }

  public getSitemap(): Observable<any> {
    if (this.siteMap) {
      return of(this.siteMap);
    } else {
      const loadingItem: LoadingItem = { id: 'site-map-info', message: 'Stiamo organizzando i contenuti del sito...' };
      this._loadingService.addLoad(loadingItem);
      const sitemapApi = `${this.api}/siteMapInformation?domain=${this.siteUrl}`;

      return this._http.get(sitemapApi).pipe(
        map((sitemap: any)=> {
          if (sitemap.error) {
            return null;
          }
          return sitemap.result;
        }),
        finalize(() => this._loadingService.removeLoad(loadingItem))
      );
    }
  }

  //#region Helpers

  public stripHtml(content) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = content;
    return tmp.textContent || tmp.innerText || "";
  }

  public isNetworkDomanin(site): boolean {
    return this.networkDomains.includes(site);
  }

  //#endregion end of Helpers



}
