import {Injectable} from '@angular/core';
import {forkJoin, Observable, throwError, of, BehaviorSubject} from 'rxjs';
import {catchError, map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ProjectService} from '../../../../shared/services/project.service';
import {EnvironmentService} from '../../../../shared/services/environment.service';
import {ICruiseComponentsParams} from '../interfaces/icruise-components-params';
import {ICruiseComponent} from '../interfaces/icruise-component';
import fixArray from '../../../../../utils/fixArray';
import * as Fares from '../../../../../../assets/data/cruisefares.json';
import {ICruiseDetailSolution} from '../../cruise-detail-page/interfaces/icruise-detail-solution';
import {ICruiseDetailCabin} from '../../cruise-detail-page/interfaces/icruise-detail-cabin';
import {ICruiseInsurance} from '../interfaces/icruise-insurance';
import {ICruiseInsuranceParams} from '../interfaces/icruise-insurance-params';
import {ICruiseLiveCabin} from '../interfaces/icruise-live-cabin';
import {ICruiseLiveCabinParams} from '../interfaces/icruise-live-cabins-params';
import {ICruiseLiveSlim} from '../interfaces/icruise-live-slim';
import {ICruiseLiveSlimParams} from '../interfaces/icruise-live-slim-params';
import {IRemoteCruiseLiveSlimDetail} from '../interfaces/iremote-cruise-live-slim-detail';
import {ICruiseQuotationRequest} from '../interfaces/icruise-quotation-request';
import {IRemoteQuotationResponse} from '../../../../shared/interfaces/iremote-quotation-response';
import uniqBy from '../../../../../utils/uniqBy';
import * as dayjs from 'dayjs';
import {ICruiseCategoryParams} from "../../cruise-detail-page/interfaces/icruise-category-params";
import {ICruiseCategory} from "../../cruise-detail-page/interfaces/icruise-category";
import {ICruiseDetail} from "../../cruise-detail-page/interfaces/icruise-detail";
import {ICruiseBookingPassenger} from '../interfaces/icruise-booking-passenger';
import generateRandomToken from "../../../../../helpers/generateRandomToken";
import {CruiseDetailService} from "../../cruise-detail-page/service/cruise-detail.service";
import {CruiseTopId} from "../../../../shared/models/cruise-top-id.enum";
import minBy from "../../../../../utils/minBy";
import {ICruiseLogin} from '../interfaces/icruise-login';
import {ICruiseLoginParams} from '../interfaces/icruise-login-params';
import {retrieveLoyaltyInfoParams, retrieveLoyaltyInfoResponse} from '../interfaces/icruise-retrieve-loyalty-info-params';

@Injectable({
  providedIn: 'root'
})
export class CruiseBookingService {
  private cruisesRoyalGroup = [CruiseTopId.AZAMARA, CruiseTopId.CELEBRITY, CruiseTopId.ROYAL];

  selectedSolution: ICruiseDetailSolution = null;
  selectedSolution$ = new BehaviorSubject<ICruiseDetailSolution>(this.selectedSolution);

  selectedCabin: ICruiseDetailCabin = null;
  selectedCabin$ = new BehaviorSubject<ICruiseDetailCabin>(this.selectedCabin);

  doUpdateSolutionFromCruiseCategory: boolean = false;
  doUpdateSolutionFromCruiseCategory$ = new BehaviorSubject<boolean>(this.doUpdateSolutionFromCruiseCategory);

  doUpdateCabinFromCruiseCategory: boolean = false;
  doUpdateCabinFromCruiseCategory$ = new BehaviorSubject<boolean>(this.doUpdateCabinFromCruiseCategory);

  isLoadingUpdateSolutionFromCruiseCat: boolean = false;
  isLoadingUpdateSolutionFromCruiseCat$ = new BehaviorSubject<boolean>(this.isLoadingUpdateSolutionFromCruiseCat);

  isLoadingUpdateCabinFromCruiseCategory: boolean = false;
  isLoadingUpdateCabinFromCruiseCategory$ = new BehaviorSubject<boolean>(this.isLoadingUpdateCabinFromCruiseCategory);

  constructor(private _http: HttpClient,
              private _projectService: ProjectService,
              private _environmentService: EnvironmentService,
              private _cruiseDetailService: CruiseDetailService) {
  }

  httpCache: Map<string, Observable<any>> = new Map<string, Observable<any>>();
  updatedSolutionFromCruiseCategoryCache: Map<string, ICruiseDetailSolution> = new Map<string, ICruiseDetailSolution>();

  normalizeFare(cabin: ICruiseDetailCabin): string {
    const originalFare = cabin.fare;
    const cabinType = cabin.type;
    const finalFare = originalFare.toUpperCase().replace('COSTA', 'ALLINCL')
      // .replace(/^(W|G)/, '')
      // .replace(/^E/, 'S');
    //  Se è una suite
    if (cabinType.toLowerCase() === 'suite') {
      // if (finalFare.startsWith('S')) {
        return finalFare + "-SUITE";
      // }
      // return 'DELUXE';
    }
    return finalFare;
  }

  getFareInfo(params: { cabin: ICruiseDetailCabin, tourOperatorId: number }): Observable<{ description: string, elements: string[] }> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
    ]).pipe(
      map(e => {
        const topId = params.tourOperatorId.toString(10);
        const fare = this.normalizeFare(params.cabin);
        const fares = (Fares as any).default;
        return fares ? fares[topId] ? fares[topId][fare] : undefined : undefined;
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getComponents(params: ICruiseComponentsParams): Observable<ICruiseComponent[]> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].searchUrl}/index.php/cruise/${e[1]}/user/book/components`;
        const key = url + JSON.stringify(params);
        if (!this.httpCache.get(key)) {
          this.httpCache.set(key, this._http.post<any>(url, params).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(key);
      }),
      map(e => {
        if (e?.ListAvailableComponents) {
          return fixArray(e.ListAvailableComponents);
        }
        if (e?.errorDetails?.OTOWebServiceFault?.message?.toLowerCase().includes('session')) {
          return [];
        }
        if (e?.error === 'E404') {
          return [];
        }
        return undefined;
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getLogin(params: ICruiseLoginParams): Observable<ICruiseLogin[]> {
      return forkJoin([
          this._environmentService.getEnvironment(),
          this._projectService.getAgencyApikey(),
      ]).pipe(
          mergeMap(e => {
              const url = `${e[0].searchUrl}/index.php/cruise/${e[1]}/user/book/login`;
              const key = url + JSON.stringify(params);
              if (!this.httpCache.get(key)) {
                  this.httpCache.set(key, this._http.post<any>(url, params).pipe(shareReplay({bufferSize: 1, refCount: true})));
              }
              return this.httpCache.get(key);
          }),
          map(e => {
              if (e?.SessionObject) {
                  return fixArray(e.SessionObject);
              }
              if (e?.errorDetails?.OTOWebServiceFault?.message?.toLowerCase().includes('session')) {
                  return [];
              }
              if (e?.error === 'E404') {
                  return [];
              }
              return undefined;
          }),
          shareReplay({bufferSize: 1, refCount: true}),
          catchError(err => throwError(err))
      );
  }

  formatCabin(cabin: any): ICruiseLiveCabin {
    return {
      deck: cabin.Deck,
      description: cabin.Description,
      maxOccupancy: parseInt(cabin.MaxOccupancy, 10),
      minOccupancy: parseInt(cabin.MinOccupancy, 10),
      number: cabin.Number,
      status: cabin.Status
    };
  }

  getCabins(params: ICruiseLiveCabinParams): Observable<ICruiseLiveCabin[]> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].searchUrl}/index.php/cruise/${e[1]}/user/book/cabins`;
        const key = url + JSON.stringify(params);
        if (!this.httpCache.get(key)) {
          this.httpCache.set(key, this._http.post<any>(url, params).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(key);
      }),
      tap((e) => {
        if (!e || (e && e.error)) {
            throw (e.error || 'Errore durante recupero cabine');
        }
      }),
      map(e => e?.CruiseCabin ? fixArray(e.CruiseCabin).map(x => this.formatCabin(x)) : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getLoyaltyInfo(params: retrieveLoyaltyInfoParams): Observable<retrieveLoyaltyInfoResponse> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].otoApi2UrlDynamic}cruise/nauth/${e[1]}/retrieveLoyaltyInfo`;
        const key = url + JSON.stringify(params);
        if (!this.httpCache.get(key)) {
          this.httpCache.set(key, this._http.post<any>(url, params).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(key);
      }),
      tap((e) => {
        if (!e || (e && e.error)) {
          throw (e.error || 'Errore durante recupero loyalityInfo');
        }
      }),
      map(e => e?.result?.retrieveLoyaltyInfoCollection || null),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  retrieveLoyaltyInfo

  formatLiveSlimDetail(detail: any): IRemoteCruiseLiveSlimDetail {
    return detail;
  }

  formatLiveSlim(liveSlim: any): ICruiseLiveSlim {
    return {
      basePrice: parseFloat(liveSlim.BasePrice),
      consumerPrice: parseFloat(liveSlim.ConsumerPrice),
      details: liveSlim.Details?.QuotationDetail ? fixArray(liveSlim.Details.QuotationDetail).map(e => this.formatLiveSlimDetail(e)) : [],
      netPrice: parseFloat(liveSlim.NetPrice),
      onaPrice: parseFloat(liveSlim.OnaPrice),
      supplementi: parseFloat(liveSlim.Supplementi)
    };
  }

  getLiveSlim(params: ICruiseLiveSlimParams): Observable<ICruiseLiveSlim> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].searchUrl}/index.php/cruise/${e[1]}/user/book/liveslim`;
        const key = url + JSON.stringify(params);
        if (!this.httpCache.get(key)) {
          this.httpCache.set(key, this._http.post<any>(url, params).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(key);
      }),
      tap((e) => {
        if (!e || (e && e.error)) {
            throw (e.error || 'Errore durante liveSlim');
        }
      }),
      map(e => e?.Result ? this.formatLiveSlim(e.Result) : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getInsurance(params: ICruiseInsuranceParams, tourOperatorId: number): Observable<any[]> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      this._projectService.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].searchUrl}/index.php/cruise/${e[1]}/user/book/insurances`;
        const key = url + JSON.stringify(params);
        if (!this.httpCache.get(key)) {
          this.httpCache.set(key, this._http.post<any>(url, params).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(key);
      }),
      map(e => e?.ListAvailableInsurances ? fixArray(e.ListAvailableInsurances) : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  quotation(params: ICruiseQuotationRequest, apiKey : string | null = null): Observable<IRemoteQuotationResponse> {
    return forkJoin([
      this._environmentService.getEnvironment(),
      apiKey ? of(apiKey) : this._projectService.getAgencyApikey(),
    ]).pipe(
      mergeMap(e => {
        const url = `${e[0].searchUrl}/index.php/cruise/${e[1]}/user/book/live`;
        const key = url + JSON.stringify(params);
        if (!this.httpCache.get(key)) {
          this.httpCache.set(key, this._http.post<any>(url, params).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(key);
      }),
      map(e => e?.Result || undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  // restituisce i dati di una quotation in base al suo id (servono anche per popolare il template email dedicato)
  getCruiseQuotation(quotationId: number): Observable<IRemoteQuotationResponse> {
    return forkJoin([
        this._environmentService.getEnvironment(),
        of(quotationId),
        this._projectService.getAgencyApikey()
    ]).pipe(
        mergeMap((e) => {
            const url = `${e[0].quotation}/nauth/${e[1]}?key=${e[2]}&type=2`;
            return this._http.get<any>(url);
        }),
        tap((quotationResp) => {
            if (!quotationResp || (quotationResp && quotationResp.error)) {
                throw 'Errore durante chiamata quotation per cruise';
            }
        }),
        map((quotationResp) => quotationResp?.result || undefined),
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError((err) => throwError(err))
    );
  }

    /**
     * Ritorna una promise con la solution con disponibilità e prezzi live aggiornati (dalla "/category")
     *
     * @param cruiseDetail
     * @param selectedSolution
     * @param passengers
     * @param components
     */
  getUpdatedSolutionFromCruiseCategory(
      cruiseDetail: ICruiseDetail,
      selectedSolution: ICruiseDetailSolution,
      passengers: number[],
      components: ICruiseComponent[] = [],
      costaClubPassenger: ICruiseBookingPassenger = null
  ): Promise<ICruiseDetailSolution> {
      if (!this.updatedSolutionFromCruiseCategoryCache) {
          // se è stata svuotata la cache con un undefined, procedo a reinizializzarla prima di proseguire
          this.updatedSolutionFromCruiseCategoryCache = new Map<string, ICruiseDetailSolution>();
      }

      if(!selectedSolution) {
        cruiseDetail.solutions.sort((priceA, priceB) => priceA.price - priceB.price)
        selectedSolution = cruiseDetail.solutions[0]
      }

      const key = JSON.stringify(selectedSolution ? dayjs(selectedSolution.departureDate).format('DD-MM-YYYY') : '') + JSON.stringify(selectedSolution?.id || '') + JSON.stringify(selectedSolution?.source || '') + JSON.stringify(passengers || '') + JSON.stringify(costaClubPassenger?.discountCode || '');

      if (this.updatedSolutionFromCruiseCategoryCache.get(key)) {
          // se esiste già la solution aggiornata, la recupero dalla cache
          return Promise.resolve(this.updatedSolutionFromCruiseCategoryCache.get(key));
      }

      const allCabins = JSON.parse(JSON.stringify(selectedSolution)).cabins
          ?.filter((cabin: ICruiseDetailCabin) => {
            if (this.cruisesRoyalGroup.includes(cruiseDetail.tourOperator.id)) {
              return !!cabin.code;
            }
            return !!cabin.fare;
          }) || [];

      let allFares: string[];
      if (this.cruisesRoyalGroup.includes(cruiseDetail.tourOperator.id)) {
          // NB: le cruise gruppo royal hanno un solo fare statico per tutte le cabine (che non viene ritornato dal dettaglio cruise)
          allFares = ['BESTRATE'];
      } else {
          allFares = uniqBy(allCabins, 'fare')
              ?.map((cabin: ICruiseDetailCabin) => cabin.fare) || [];
      }

      if (selectedSolution && allFares?.length) {
          return Promise.all(allFares.map(
              (fare) => this.getFormattedCruiseCategoryPromise(fare, cruiseDetail, selectedSolution, passengers, components, null, null, costaClubPassenger)
          )).then(
              (resolved) => {
                  if (resolved?.length) {
                      const cabinsWithCategory: ICruiseCategory[] = [...resolved]
                          ?.flat()
                          ?.filter((cabin) => cabin)
                          ?.filter((cabin) => {
                              if (this.cruisesRoyalGroup.includes(cruiseDetail.tourOperator.id) && !cabin.fareCode) {
                                  // NB: se una cruise gruppo royal, controlla se NON esiste il "fareCode" in modo da filtrare le cabin solo con il "code" presente
                                  return !!cabin.code;
                              }
                              return !!cabin.fareCode;
                          }) || [];
                      const cabinsUpdated: ICruiseDetailCabin[] = JSON.parse(JSON.stringify(allCabins || [])).map((cabin) => {
                          const cabinFound = cabinsWithCategory?.find((cabWithCategory) => {
                              if (this.cruisesRoyalGroup.includes(cruiseDetail.tourOperator.id)) {
                                  if (cabWithCategory.type) {
                                      return cabin.code === cabWithCategory.code && cabin.type === cabWithCategory.type;
                                  }
                                  return cabin.code === cabWithCategory.code;
                              }
                              return cabin.code === cabWithCategory.code && cabin.fare === cabWithCategory.fareCode;
                          }) || null;
                          if (cabinFound) {
                              if (this.cruisesRoyalGroup.includes(cruiseDetail.tourOperator.id)) {
                                  cabin.available = true;
                                  cabin.price = cabinFound.totalCabinPrice;
                                  cabin.taxes = cabinFound.taxes;
                              } else {
                                  cabin.available = cabinFound.available;
                                  cabin.price = cabinFound.totalCabinPrice;
                                  cabin.taxes = cabinFound.cabinTaxes;
                              }
                              cabin.categoryDescription = cabinFound.moreDescription?.trim() || '';
                          } else {
                              cabin.available = false;
                          }
                          cabin.isUpdatedFromCategory = !!cabinFound; // serve a indicare se la cabina è stata aggiornata dalla "/category"
                          return cabin;
                      }) || [];
                      const bestCabinUpdated = minBy(cabinsUpdated, 'price');

                      selectedSolution.cabins = cabinsUpdated;
                      selectedSolution.price = bestCabinUpdated?.price;
                      selectedSolution.isPriceUpdatedFromCategory = bestCabinUpdated.isUpdatedFromCategory;
                      selectedSolution.cabinsGroupedByType = this._cruiseDetailService.getCruiseCabinsGroupedByType(cabinsUpdated, selectedSolution);
                  }

                  // salvo la solution aggiornata nella proprietà di cache dedicata
                  this.updatedSolutionFromCruiseCategoryCache.set(key, selectedSolution);

                  return Promise.resolve(selectedSolution);
              },
              (rejected) => Promise.reject(rejected)
          );
      }

      return Promise.resolve(selectedSolution);
  }

  /**
   * Ritorna una promise con la cabin con disponibilità e prezzi live aggiornati (dalla "/category")
   *
   * @param cruiseDetail
   * @param selectedSolution
   * @param selectedCabin
   * @param passengers
   * @param components
   */
  getUpdatedCabinFromCruiseCategory(
      cruiseDetail: ICruiseDetail,
      selectedSolution: ICruiseDetailSolution,
      selectedCabin: ICruiseDetailCabin,
      passengers: number[],
      components: ICruiseComponent[] = [],
      costaClubPassenger: ICruiseBookingPassenger = null
  ): Promise<ICruiseDetailCabin> {
      selectedCabin = JSON.parse(JSON.stringify(selectedCabin));

      // NB: per il gruppo royal, imposto solo l'unica tariffa statica "BESTRATE"
      const selectedFare = (this.cruisesRoyalGroup.includes(cruiseDetail.tourOperator.id)) ?
          'BESTRATE' : selectedCabin?.fare;

      if (selectedSolution && selectedFare) {
          return this.getFormattedCruiseCategoryPromise(selectedFare, cruiseDetail, selectedSolution, passengers, components, null,null,costaClubPassenger)
              .then(
                  (resolved) => {
                      const cabinWithCategory: ICruiseCategory[] = resolved
                          ?.filter((cabin) => cabin)
                          ?.filter((cabin) => !!cabin.code) || [];

                      const updatedselectedCabin = cabinWithCategory
                          .find((cabin) => cabin.code === selectedCabin.code);

                      // se la cabina aggiornata viene ritornata dalla category allora procedo ad aggiornare prezzi e disponibilità di essa
                      if (updatedselectedCabin) {
                          if (this.cruisesRoyalGroup.includes(cruiseDetail.tourOperator.id)) {
                              selectedCabin.available = true;
                              selectedCabin.price = updatedselectedCabin.totalCabinPrice;
                              selectedCabin.taxes = updatedselectedCabin.taxes;
                          } else {
                              selectedCabin.available = updatedselectedCabin.available;
                              selectedCabin.price = updatedselectedCabin.totalCabinPrice;
                              selectedCabin.taxes = updatedselectedCabin.cabinTaxes;
                          }
                          selectedCabin.categoryDescription = updatedselectedCabin.moreDescription?.trim() || '';
                      } else {
                          // ... altrimenti, imposto la disponibilità a false e non aggiorno i prezzi
                          selectedCabin.available = false;
                      }

                      return Promise.resolve(selectedCabin);
                  },
                  (rejected) => Promise.reject(rejected)
              );
      }

      return Promise.resolve(selectedCabin);
  }

  private getFormattedCruiseCategoryPromise(
      cabinFare: string,
      cruiseDetail: ICruiseDetail,
      solution: ICruiseDetailSolution,
      passengers: number[],
      components: ICruiseComponent[] = [],
      forceFlight: boolean = false,
      retryIfRejected: boolean = true,
      costaClubPassenger: ICruiseBookingPassenger = null
  ): Promise<ICruiseCategory[]> {
      return this.getCruiseCategory(
          this.createCategoryParams(cabinFare, cruiseDetail, solution, passengers, components, forceFlight, costaClubPassenger)
      ).then(
          (resolved) => Promise.resolve(resolved),
          (rejected) => {
              if (typeof rejected === 'string' && rejected?.match(/volo/) && components.length === 0) {
                  // rieseguo nuovamente la chiamata solo se nella risposta di rejected è presente una stringa con "volo" e il
                  // "components" risulta vuoto (trattasi di category con solo voli)
                  return this.getFormattedCruiseCategoryPromise(cabinFare, cruiseDetail, solution, passengers, components, true);
              }
              if (retryIfRejected) {
                  // rieseguo nuovamente la chiamata solo se "retryIfRejected" è true (viene poi rieseguita impostandolo a false)
                  return this.getFormattedCruiseCategoryPromise(cabinFare, cruiseDetail, solution, passengers, components, forceFlight, false);
              }
              return Promise.reject(rejected);
          }
      );
  }

  private getCruiseCategory(
      params: ICruiseCategoryParams
  ): Promise<ICruiseCategory[]> {
      return forkJoin([
          this._environmentService.getEnvironment(),
          this._projectService.getAgencyApikey(),
      ]).pipe(
          mergeMap(response => {
              const url = `${response[0].searchUrl}/index.php/cruise/${response[1]}/user/book/category`;
              const key = url + JSON.stringify(params);
              if (!this.httpCache.get(key)) {
                  this.httpCache.set(key, this._http.post<any>(url, params).pipe(shareReplay({bufferSize: 1, refCount: true})));
              }
              return this.httpCache.get(key);
          }),
          tap((response) => {
              if (!response || (response && response.error)) {
                  throw (response.error || 'Errore durante chiamata category. Riprovare.');
              }
          }),
          map(response => response.ListAvailableCategories),
          shareReplay({bufferSize: 1, refCount: true}),
          catchError(err => throwError(err))
      ).toPromise();
  }

  private createCategoryParams(
      cabinFare: string,
      cruiseDetail: ICruiseDetail,
      solution: ICruiseDetailSolution,
      passengers: number[],
      components: ICruiseComponent[] = [],
      forceFlightMandatory: boolean = false,
      costaClubPassenger: ICruiseBookingPassenger = null
  ): ICruiseCategoryParams {
      if ((solution.hasFlight || forceFlightMandatory) && components?.length === 0) {
          let cityCode: string = "MXP";
          let direction: "Both" | "OutBound" | "InBound" | "none" = "Both";
          if (solution.departureHarbour.code == "SVN") {
              cityCode = "FCO";
          }
          if ( solution.arrivalHarbour.code != solution.departureHarbour.code) {
              direction = "OutBound";
          }

          components = [{
              cityCodes: cityCode,
              code: cityCode,
              direction: direction,
              insurance: false,
              mandatory: true,
              type: 'Flight'
          }];
      }

      return {
          quotation: {
              camere: {
                  cabinCode: '',
                  passeggeri: passengers.map(
                      (age, index) => {

                        const fakePassenger = CruiseBookingService.createFakePassenger(solution.departureDate, age, index);

                        if(costaClubPassenger?.discountCode && fakePassenger.first) {
                          fakePassenger.discountCode = costaClubPassenger.discountCode;
                          fakePassenger.nome = costaClubPassenger.nome;
                          fakePassenger.cognome = costaClubPassenger.cognome;
                          fakePassenger.dataNascita = costaClubPassenger.dataNascita;
                        }
                        return fakePassenger;
                      }
                  )
              },
              components: components,
              fareCode: cabinFare,
              idCruise: Number(cruiseDetail.id.substring(2)),
              idSolution: solution.id,
          },
          session_token: generateRandomToken()
      };
  }

  private static createFakePassenger(departureDate: Date, age: number, index: number): ICruiseBookingPassenger {
    return {
      cognome: 'test',
      dataNascita: dayjs(departureDate).subtract(age, 'years').add(1, 'day').toDate(),
      first: index === 0 ? 1 : 0,
      nome: 'test',
      sesso: 'M',
    };
  }
}
