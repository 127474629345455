import {Pipe, PipeTransform} from '@angular/core';
import {HolidayCard} from '../interfaces/holiday-card';
import mapGroupBy from '../../../../../utils/mapGroupBy';

@Pipe({
  name: 'orderByBrandAndPrice'
})
export class OrderByBrandAndPricePipe implements PipeTransform {

  bestPriceComparator(a: HolidayCard, b: HolidayCard): number {
    return a.bestSolution.price > b.bestSolution.price ? 1 : -1;
  }

  transform(value: HolidayCard[]): HolidayCard[] {
    if (value) {
      const ret = [];
      const priceSorted = value.sort(this.bestPriceComparator);
      const groupedMap = mapGroupBy<number, HolidayCard>(priceSorted, (e) => e.tourOperator);
      while (groupedMap.size > 0) {
        const availableKeys = [...groupedMap.keys()];
        availableKeys.forEach(key => {
          const hols = groupedMap.get(key);
          ret.push(hols.shift());
          if (hols.length > 0) {
            groupedMap.set(key, hols);
          } else {
            groupedMap.delete(key);
          }
        });
      }
      return ret;
    }
    return [];
  }

}
