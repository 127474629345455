import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import * as dayjs from 'dayjs';
import { IPassengerForm } from '../interfaces/ipassenger-form';

@Directive({
  selector: '[appGuestBirthdateValidator]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: GuestBirthdateValidatorDirective, multi: true}
  ]
})
export class GuestBirthdateValidatorDirective implements Validator {

  @Input() guest: IPassengerForm;
  @Input() departureDate: string;

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const error: { [key: string]: boolean } = {};
    const inputAge = dayjs(control.value, 'YYYY-MM-DD');
    const diff = dayjs(this.departureDate, 'YYYY-MM-DD').diff(inputAge);
    const diffAges = dayjs(this.departureDate, 'YYYY-MM-DD').diff(inputAge, 'y');
    if (diff < 0 || diffAges > 100) {
      error.invalidBirthDate = true;
    }
    return Object.keys(error)?.length > 0 ? error : null;
  }

}
