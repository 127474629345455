import {Injectable} from '@angular/core';
import {ProjectService} from './project.service';
import {Observable, throwError} from 'rxjs';
import {catchError, map, shareReplay, tap} from 'rxjs/operators';
import {AppointmentProjectTypes} from '../models/appointment-project-types.enum';

@Injectable({
  providedIn: 'root'
})
export class WebsiteConfigService {

  constructor(private _projectService: ProjectService) { }

  formatConfig(configs: any[]): any {
    
    const retObject = {};
    configs.forEach(e => {
      try {
        retObject[e.type] = JSON.parse(e.content);
      } catch (error) {
        retObject[e.type] = e.content;
      }
    });
    return retObject;
  }

  hasAppointments(): Observable<boolean> {
    return this._projectService.getProjectType().pipe(
      map( e => Object.values(AppointmentProjectTypes).includes(e)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAnalytics(): Observable<string> {
    return this.getAll().pipe(
      map(e => e?.analytics),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getFbPixel(): Observable<string> {
    return this._projectService.getProfile().pipe(
        map((resp) => (resp?.fb_pixel) ? WebsiteConfigService.getFbPixelScript(resp?.fb_pixel?.trim()) : null),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => throwError(err))
    );
  }

  getCustomJsHtml(): Observable<string> {
    return this.getAll().pipe(
      map(e => e?.customjshtml),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getExtraFooter(): Observable<string> {
    return this.getAll().pipe(
      map(e => e?.extrafooter),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getLinks(): Observable<any> {
    return this.getAll().pipe(
      map(e => e?.links),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getAll(): Observable<any> {
    return this._projectService.getWebsiteConfig().pipe(
      map(e => e ? this.formatConfig(e) : undefined),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  private static getFbPixelScript(fbPixelId: string = '\'\'') {
    return '\
      <script>\
        !function(f,b,e,v,n,t,s)\
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};\
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\';\
        n.queue=[];t=b.createElement(e);t.async=!0;\
        t.src=v;s=b.getElementsByTagName(e)[0];\
        s.parentNode.insertBefore(t,s)}(window,document,\'script\',\
        \'https://connect.facebook.net/en_US/fbevents.js\');\
        fbq(\'init\', \'' + fbPixelId + '\');\
        fbq(\'track\', \'PageView\');\
      </script>\
      <noscript>\
        <img height="1" width="1" src="https://www.facebook.com/tr?id=' + fbPixelId + '&amp;ev=PageView&amp;noscript=1">\
      </noscript>';
  }
}
