import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'dayjs'
})
export class DayjsPipe implements PipeTransform {

  transform(value: any, format: string, parse: string = null): string {
    return parse ? dayjs(value, parse).format(format) : dayjs(value).format(format);
  }

}
