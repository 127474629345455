import {Pipe, PipeTransform} from '@angular/core';
import {ICruiseDetailCabin} from '../../booking-flow/cruise/cruise-detail-page/interfaces/icruise-detail-cabin';
import {ICruiseDetailCabinByType} from '../../booking-flow/cruise/cruise-detail-page/interfaces/icruise-detail-cabin-by-type';
import mapGroupBy from '../../../utils/mapGroupBy';
import minBy from '../../../utils/minBy';

@Pipe({
  name: 'cabinsByType'
})
export class CabinsByTypePipe implements PipeTransform {

  transform(value: ICruiseDetailCabin[], onlyAvailableBestCabin: boolean = false, hasPromoCosta: boolean = false): ICruiseDetailCabinByType[] {
    if (value) {
      const cabins = value.filter(x => !x.description.toLowerCase().includes('singola'));

      const ret: ICruiseDetailCabinByType[] = [];

      const cabinsByTypeMap = mapGroupBy(cabins, (x) => x.type);

      [...cabinsByTypeMap.keys()].forEach(x => {
        let bestCabin: ICruiseDetailCabin;

        // verifico se devo filtrare solo le bestCabin disponibili altrimenti, prendo a prescindere quella con il prezzo piu basso
        if (onlyAvailableBestCabin) {
          const availableCabins = cabinsByTypeMap.get(x)
            ?.filter((cabin) => cabin.available) || [];
          bestCabin = (availableCabins?.length) ? minBy(availableCabins, 'price') : minBy(cabinsByTypeMap.get(x), 'price');
        } else {
          if(hasPromoCosta){
            cabinsByTypeMap.get(x).forEach(cabin => {
              cabin.disable = cabin.fare === 'myCruise';
            })
            const myAllincCabins = cabinsByTypeMap.get(x).filter(cabin => cabin.fare === 'myAllinc');
            bestCabin = minBy(myAllincCabins, 'price');
          } else {
            bestCabin = minBy(cabinsByTypeMap.get(x), 'price');
          }
        }

        ret.push({
          type: x,
          bestCabin: bestCabin,
          cabins: cabinsByTypeMap.get(x),
          openCabin: false
        });
      });
      return ret.sort((a, b) => {
        return a.bestCabin.price > b.bestCabin.price ? 1 : -1;
      });
    }
    return [];
  }
}
