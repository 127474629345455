import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, Observable, throwError} from 'rxjs';
import {catchError, map, mergeMap, shareReplay} from 'rxjs/operators';
import * as AgenciesStaticInfo from '../../../../assets/data/agenciesstaticinfo.json';
import {Filial} from '../interfaces/filial';
import {IRemoteTheBoxPage} from '../interfaces/iremote-the-box-page';
import {IRemoteTheBoxPageFilial} from '../interfaces/iremote-the-box-page-filial';
import {IRemoteTheBoxPages} from '../interfaces/iremote-the-box-pages';
import {TemplateTypes} from '../models/template-types.enum';
import {CompilerService} from './compiler.service';
import {EnvironmentService} from './environment.service';
import {ProjectService} from './project.service';
import {TemplatesService} from './templates.service';
import {Networks} from '../models/networks.enum';
import {IRemoteProfile} from '../interfaces/iremote-profile';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  // @ts-ignore
  private agenciesStaticInfo: typeof AgenciesStaticInfo = AgenciesStaticInfo.default;

  constructor(private _environmentService: EnvironmentService,
              private _compilerService: CompilerService,
              private _templatesService: TemplatesService,
              private _projectService: ProjectService,
              private _http: HttpClient) {
  }

  httpCache = new Map<string, Observable<any>>();

  formatGeoPageToString(page: IRemoteTheBoxPage): string {
    let retString = '';
    if (page.img_url) {
      retString += `<img alt="pagina" class="img img-fluid" src="${page.img_url}"><br>`;
    }
    retString += page.testo;
    return retString;
  }

  formatGeoFilial(filial: IRemoteTheBoxPageFilial): Filial {
    return {
      address: filial.indirizzo,
      city: filial.comune,
      email: filial.email,
      lat: parseFloat(filial.latitude),
      lng: parseFloat(filial.longitude),
      openingHours: filial.orari,
      postalCode: filial.cap,
      province: filial.provincia,
      telephone: filial.tel
    };
  }

  getAboutUs(): Observable<string> {
    return this.getGeoPages().pipe(
      map(e => this.formatGeoPageToString(e.chisiamo)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getServices(): Observable<string> {
    return this.getGeoPages().pipe(
      map(e => this.formatGeoPageToString(e.servizi)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getContacts(): Observable<string> {
    return this.getGeoPages().pipe(
      map(e => this.formatGeoPageToString(e.contatti)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getFilials(): Observable<Filial[]> {
    return forkJoin([
      this._projectService.getProfile(),
      this.getGeoPages()
    ]).pipe(
      map(e => {
        const [profile, geoPages] = e;
        const filials = geoPages.contatti?.sedi.map(x => this.formatGeoFilial(x));
        if (this.agenciesStaticInfo[profile.id]) {
          filials.push(...this.agenciesStaticInfo[profile.id].contacts.filials);
        }
        return filials;
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getPrivacy(siteUrl: string = '', wtgNetSitePrivacyType: 'wtg' | 'wtsud' = null): Observable<string> {
    let profile: IRemoteProfile;
    return this._projectService.getProfile().pipe(
      mergeMap(remoteProfile => {
        let templateTypes: TemplateTypes;
        if (remoteProfile.network_owner && Number(remoteProfile.network_owner) === Networks.WTG || Number(remoteProfile.network_owner) === Networks.GEO) {
          if (this._templatesService.isIstitutional(siteUrl)) {
            templateTypes = TemplateTypes.GEO_NETWORK_SITE_PRIVACY;
          } else {
            // default privacy WTG
            templateTypes = TemplateTypes.WTG_PRIVACY;
          }
        }
        profile = JSON.parse(JSON.stringify(remoteProfile));
        return this._templatesService.getTemplate(templateTypes);
      }),
      map(template => this._compilerService.compile(template, profile)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getCookie(siteUrl: string = ''): Observable<string> {
    let profile: IRemoteProfile;
    return forkJoin([
      this._projectService.getProfile()
    ]).pipe(
      mergeMap(e => {
        let templateTypes: TemplateTypes;
        profile = JSON.parse(JSON.stringify(e[0]));
        if (e[0].network_owner && Number(e[0].network_owner) === Networks.WTG) {
          if (this._templatesService.isIstitutional(siteUrl)) {
            templateTypes = TemplateTypes.WTG_ISTITUZIONALE_COOKIE;
          } else {
            // default cookie WTG
            templateTypes = TemplateTypes.WTG_COOKIE;
          }
          return this._templatesService.getTemplate(templateTypes);
        } else {
          if (siteUrl?.includes('geotn.it')) {
            templateTypes = TemplateTypes.GEO_ISTITUZIONALE_COOKIE;
          } else {
            // default cookie GEO
            templateTypes = TemplateTypes.GEO_COOKIE;
          }
          return this._templatesService.getTemplate(templateTypes);
        }
      }),
      map(template => this._compilerService.compile(template, profile)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getGeoPages(): Observable<IRemoteTheBoxPages> {
    return forkJoin([
      this._projectService.getServiceKey(),
    ]).pipe(
      mergeMap(e => {
        const url = `https://core2.offertetouroperator.com/~geo-thebox-proxy/dist/index.php/siti/${e[0]}/pagine`;
        if (!this.httpCache.get(url)) {
          this.httpCache.set(url, this._http.get<any>(url).pipe(shareReplay({bufferSize: 1, refCount: true})));
        }
        return this.httpCache.get(url);
      }),
      map(e => e.result),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  hasServices(): Observable<boolean> {
    return this.getServices().pipe(
      map(s => (s && s !== 'null')),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  hasContacts(): Observable<boolean> {
    return this.getContacts().pipe(
      map(c => (c && c !== 'null')),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }
}
