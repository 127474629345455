import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform<T>(value: T[], predicate: (T) => boolean, ...triggers): T[] {
    return value ? value.filter(predicate) : value;
  }

}
