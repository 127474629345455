<div class="modal-generic-info">
  <div class="modal-header modal-generic-info__header">
    <h4 class="modal-title" id="modal-basic-title"><i class="icon-info"></i> {{title}}</h4>
    <button type="button" class="btn btn-close me-2" aria-label="close" (click)="activeModal.dismiss()">
      <span aria-hidden="true"><i class="icon-close"></i></span>
    </button>
  </div>

  <ng-container *ngIf="isLoadingContent">
    <div class="modal-body modal-generic-info__loading">
      <app-element-loader [loadingStatus]="isLoadingContent" 
      [loadingMessage]="loadingMessage"></app-element-loader>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoadingContent && bodyToDisplay">
    <div class="modal-body modal-generic-info__body" [innerHTML]="bodyToDisplay"></div>
  </ng-container>

</div>
