export interface scriptLinks  {
  src: string;
  scriptType?: string,
  async: boolean;
  defer: boolean;
  location: 'head' | 'body';
  isAnalitics: boolean
}

export const  scriptLinks: scriptLinks[] = [
/*   {
    src:'https://www.googletagmanager.com/gtag/js?id=G-S0ZLS9PZ4R',
    scriptType: 'text/javascript',
    async: true,
    defer: false,
    location: 'head',
    isAnalitics: true
  },  */
  {
    src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyClNmY0SgZbrgrzb8ofVxt1d3E9elwCMrc&libraries=places',
    scriptType:'text/javascript',
    async: false,
    defer: true,
    location: 'body',
    isAnalitics: false
  },
  {
    src: 'https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js',
    async: false,
    scriptType:'text/javascript',
    defer: true,
    location: 'body',
    isAnalitics: false
  },
  {
    src: 'https://www.youtube.com/iframe_api',
    scriptType:'text/javascript',
    async: false,
    defer: true,
    location: 'body',
    isAnalitics: false
  }
];
