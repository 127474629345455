<div class="c-structure-detail">
  <div class="c-structure-detail__container u-scrollbar">

    <div class="c-structure-detail__header">
      <h4 class="c-structure-detail__title">Pacchetto: {{dataSrc.title}}</h4>
      <div class="row">
        <div *ngIf="dataSrc.imageBaseUrl && dataSrc.image" class="col-12">
          <img  src="{{dataSrc.imageBaseUrl+dataSrc.image}}" width="80%" height="auto" style="border-radius: 12px">
        </div>
      </div>
      <br><br>
      <div class="row">
        <div class="col-2">
          <h5>Tags</h5>
        </div>

      </div>
      <span class="c-card-tags__item" *ngFor="let el of dataSrc.tags"><i class="{{el.image}}"></i> &nbsp;{{el.description}} &nbsp;</span>
      <br><br>
      <div class="row">
        <div class="col-2">
          <h5>Cosa Include</h5>
        </div>
        <div class="col-10" [innerHtml]="dataSrc.includes">
        </div>
      </div>
    </div>

    <div class="c-structure-detail__content" *ngIf="dataSrc.description">
      <div class="row">
        <div class="col-2">
          <h5>Descrizione</h5>
        </div>
        <div class="col-10" style="word-wrap: break-word" [innerHtml]="dataSrc.description">
        </div>
      </div>
    </div>
  </div>
</div>
