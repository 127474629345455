<div class="c-experience-detail">
  <div class="c-experience-detail__content u-scrollbar">

    <h2>Experience Detail</h2>
    {{dataSrc.id}} - {{dataSrc.title}} - {{dataSrc.content}}
    <br/>  <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
    <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
    <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
    <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
    <button (click)="closeModal()">Close</button>

  </div>
</div>
