import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrerenderService {
  public isPrerender: boolean;
  private readonly userAgentString: string;
  private botsRegex: RegExp;

  constructor() {
    this.userAgentString = window.navigator.userAgent.toLowerCase();
    this.botsRegex = /(googlebot|bingbot|yandex|baiduspider|facebookexternalhit|twitterbot|rogerbot|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest\/0\.|pinterestbot|slackbot|vkShare|W3C_Validator|whatsapp|facebot|facebook|duckduckbot|skype|oto-ng-prerender)/i;

    this.isPrerender = this.botsRegex.test(this.userAgentString);
  }

  public handleMetaLoadedAndCreateElement(meta: Record<string, string>) {
    this.setMeta(meta);
  }

  private checkIfMetaAreUpdated = (meta: Record<string, string>) => {
    for (const key in meta) {
      const value = document.querySelector('meta[property="og:' + key + '"]')
      if (value?.getAttribute('content') !== meta[key]) {
        return false;
      }
    }
    return true;
  };

  public setMeta = (meta: Record<string, string>) => {
    let tries = 0;
    if (!this.checkIfMetaAreUpdated(meta)) {
      const interval = setInterval(() => {
        tries++;
        if (this.checkIfMetaAreUpdated(meta) || tries >= 25) {
          this.setPrerenderLoadingMeta(true);
          clearInterval(interval);
        }
      }, 100);
    } else {
      this.setPrerenderLoadingMeta(true);
    }
  };

  public setPrerenderLoadingMeta = (flag: boolean) => {
    const el = document.querySelector('.prerender-load-meta');
    if (flag) {
      if (!el) {
        const el = document.createElement('div');
        el.className = 'prerender-load-meta'
        document.body.appendChild(el);
      }
    } else {
      if (el) {
        el.remove();
      }
    }
  }

}

