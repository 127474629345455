<div class="footer-main">
  <div class="footer-body">
    <div class="container container-lg">
      <div class="row">
        <div class="col-12 col-md-6" [ngClass]="hasVacanzeAssicurate ? 'col-lg-2' : 'col-lg-3'">
          <h4 class="h4 footer-title">Link Utili</h4>
          <ul class="footer-menu">
            <li class="footer-menu__link">
              <a [routerLink]="'chi-siamo'">Chi Siamo</a>
            </li>
            <li class="footer-menu__link" *ngIf="!hasMultipleStores">
              <a [routerLink]="'contatti'">Contatti</a>
            </li>
            <li class="footer-menu__link" *ngIf="hasMultipleStores">
              <a [routerLink]="'cerca-agenzia'">Cerca Agenzia</a>
            </li>
            <li class="footer-menu__link">
              <a [routerLink]="'cookie-policy'">Cookie Policy</a>
            </li>
            <li class="footer-menu__link" *ngIf="isIstituzionale">
              <a href="javascript:void(0)" (click)="explodeGovernance()">Modelli di governance</a>
              <div class="governance" [ngClass]="{'show': showGovernance}">
                <ul class="governance__list">
                  <li>
                    <a class="doc-links" href="../../../../../assets/documents/codice_etico.pdf" target="_blank">- Codice etico</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" (click)="showOrganization = !showOrganization">- Modello organizzativo</a>
                    <div class="organization" [ngClass]="{'show-organization': showOrganization }">
                      <ul class="organization__list">
                        <li>
                          <a class="doc-links" href="../../../../../assets/documents/modello_di_organizzazione_parte_generale.pdf" target="_blank">- Parte generale</a>
                        </li>
                        <li>
                          <a class="doc-links" href="../../../../../assets/documents/modello_di_organizzazione_parte1.pdf" target="_blank">- Parte speciale 1</a>
                        </li>
                        <li>
                          <a class="doc-links" href="../../../../../assets/documents/modello_di_organizzazione_parte2.pdf" target="_blank">- Parte speciale 2</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="footer-menu__link">
              <a [routerLink]="'privacy-policy'">Privacy Policy</a>
            </li>
            <li class="footer-menu__link" *ngIf="isIstituzionale">
              <a [routerLink]="'privacy-area'">Area Privacy</a>
            </li>
            <li class="footer-menu__link" *ngIf="isIstituzionale">
              <a class="doc-links" href="../../../../../assets/documents/whistleblowing.pdf" target="_blank">Whistleblowing</a>
            </li>
            <!--<li class="footer-menu__link" *ngIf="this.isNetworkSite">
              <a target="_blank" href="https://www.geospace-agenzie.it/">Area Riservata</a>
            </li>-->
          </ul>
        </div>
        <!-- Logo Vacanze Assicurate -->
        <div class="col-12 col-md-6 col-lg-3 text-lg-center" *ngIf="hasVacanzeAssicurate && vacanzeAssicurateImgUrl">
          <h4 class="h4 footer-title">Vacanze Assicurate</h4>
          <div class="footer-vacanze-assicurate__wrapper">
            <img [src]="vacanzeAssicurateImgUrl" alt="vacanze assicurate" class="footer-vacanze-assicurate__logo">
          </div>
        </div>
        <div class="col-12 col-md-6 company-details"
             [class.offset-lg-3]="isWtgNetworkSite"
             [class.col-lg-4]="hasVacanzeAssicurate"
             [class.col-lg-5]="!hasVacanzeAssicurate">
          <h4 *ngIf="!isWTStore" class="h4 footer-title">{{agencyData?.denomination}}</h4>
          <span *ngIf="!isWtgNetworkSite && !isWTStore && !isWTShop && (agencyData?.businessName || agencyData?.address)">Ragione sociale e sede legale:</span>
          <span *ngIf="!isWtgNetworkSite && !isWTShop && agencyData?.businessName">{{agencyData.businessName}}</span>
          <span *ngIf="!isWTShop && agencyData?.address">{{agencyData.address}}</span>
          <span *ngIf="agencyData?.pIva">P.IVA: {{agencyData.pIva}}</span>
          <span *ngIf="hasExtraFooter" class="extra" [innerHTML]="extraFooterTextSanitazer"></span>
        </div>
        <div class="col-12 col-md-6 d-flex flex-column justify-content-between" [ngClass]="hasVacanzeAssicurate ? 'col-lg-3' : 'col-lg-4'">
          <section *ngIf="!isWtgNetworkSite && (agencyData?.contacts?.mail || agencyData?.contacts?.phone || agencyData?.contacts?.fax)">
            <h4 class="h4 footer-title">Contatti</h4>
            <ul class="navbar-nav contacts-menu__navbar" *ngIf="(brand?.networkId === networks.WTG && !hasMultipleStores) || brand?.networkId === networks.GEO">
              <li class="nav-item" *ngIf="agencyData?.contacts?.mail">
                <a class="nav-link nav-item__link nav-item__link--red nav-item__link--bolded nav-item__link--phone"
                  tabindex="-1" [href]="'mailto:' + agencyData?.contacts?.mail" aria-disabled="true">
                  <i class="icon-email"></i>
                  <span>{{agencyData?.contacts?.mail}}</span>
                </a>
              </li>
              <li class="nav-item" *ngIf="agencyData?.contacts?.mail2">
                <a class="nav-link nav-item__link nav-item__link--red nav-item__link--bolded nav-item__link--phone"
                   tabindex="-1" [href]="'mailto:' + agencyData?.contacts?.mail2" aria-disabled="true">
                  <i class="icon-email"></i>
                  <span>{{agencyData?.contacts?.mail2}}</span>
                </a>
              </li>
              <li class="nav-item nav-item__link nav-item__link--red nav-item__link--bolded"
                  *ngIf="agencyData?.contacts?.phone && agencyData?.contacts?.phone.length > 3">
                <a class="nav-link" [href]="'tel:' + agencyData?.contacts?.phone">
                  <i class="icon-phone-shape"></i>
                  <span>{{agencyData?.contacts?.phone}}</span>
                </a>
              </li>
              <li class="nav-item nav-item__link nav-item__link--red nav-item__link--bolded"
                  *ngIf="agencyData?.contacts?.phone2 && agencyData?.contacts?.phone2.length > 3">
                <a class="nav-link" [href]="'tel:' + agencyData?.contacts?.phone2">
                  <i class="icon-phone-shape"></i>
                  <span>{{agencyData?.contacts?.phone2}}</span>
                </a>
              </li>
              <li class="nav-item nav-item__link nav-item__link--red nav-item__link--bolded"
                *ngIf="agencyData?.contacts?.fax">
                <a class="nav-link" [href]="'tel:' + agencyData?.contacts?.fax">
                  <i class="icon-phone-shape"></i>
                  <span>{{agencyData?.contacts?.fax}}</span>
                </a>
              </li>
            </ul>
            <div class="footer-agency-contacts" *ngIf="brand?.networkId === networks.WTG && hasMultipleStores">
              <swiper [config]="config" [(index)]="index">
                <div *ngFor="let agency of storeList" class="navbar-nav contacts-menu__navbar">
                  <div class="nav-item" *ngIf="agency.denomination?.trim()">
                    <div class="nav-link nav-item__link">
                      <span class="u-text--bold">{{agency.denomination?.trim()}}</span>
                    </div>
                  </div>
                  <div class="nav-item" *ngIf="(agency.address?.trim() && agency.city?.trim()) || agency.province?.trim()">
                    <div class="nav-link nav-item__link">
                      <i class="icon-pin"></i>
                      <span>{{agency.address.trim()}}, {{agency.city.trim()}}{{(agency.province?.trim()) ? ' (' + agency.province.trim() + ')' : ''}}</span>
                    </div>
                  </div>
                  <div class="nav-item" *ngIf="agency.email1?.trim()">
                    <a class="nav-link nav-item__link nav-item__link--red nav-item__link--bolded nav-item__link--phone"
                       tabindex="-1" [href]="'mailto:' + agency.email1?.trim()" aria-disabled="true">
                      <i class="icon-email"></i>
                      <span>{{agency.email1?.trim()}}</span>
                    </a>
                  </div>
                  <div class="nav-item" *ngIf="agency.email2?.trim()">
                    <a class="nav-link nav-item__link nav-item__link--red nav-item__link--bolded nav-item__link--phone"
                       tabindex="-1" [href]="'mailto:' + agency.email2?.trim()" aria-disabled="true">
                      <i class="icon-email"></i>
                      <span>{{agency.email2?.trim()}}</span>
                    </a>
                  </div>
                  <div class="nav-item nav-item__link nav-item__link--red nav-item__link--bolded"
                       *ngIf="agency.tel1?.trim()">
                    <a class="nav-link" [href]="'tel:' + agency.tel1?.trim()">
                      <i class="icon-phone-shape"></i>
                      <span>{{agency.tel1?.trim()}}</span>
                    </a>
                  </div>
                  <div class="nav-item nav-item__link nav-item__link--red nav-item__link--bolded"
                       *ngIf="agency.tel2?.trim()">
                    <a class="nav-link" [href]="'tel:' + agency.tel2?.trim()">
                      <i class="icon-phone-shape"></i>
                      <span>{{agency.tel2?.trim()}}</span>
                    </a>
                  </div>
                  <div class="nav-item nav-item__link nav-item__link--red nav-item__link--bolded"
                       *ngIf="agency.fax?.trim()">
                    <a class="nav-link" [href]="'tel:' + agency.fax?.trim()">
                      <i class="icon-phone-shape"></i>
                      <span>{{agency.fax?.trim()}}</span>
                    </a>
                  </div>
                </div>
              </swiper>
            </div>
          </section>

          <section class="footer-social-menu" *ngIf="agencyData?.social">
            <h4 class="h4 footer-title">Social</h4>
            <ul class="navbar-nav top-bar-menu__navbar top-bar-menu__navbar--socials ms-auto">
              <li class="nav-item" *ngIf="agencyData.social.whatsapp">
                <a target="_blank" [href]="sanitize((isMobile$ | async) ? 'whatsapp://send?phone=' + agencyData.social.whatsapp : 'https://wa.me/' + agencyData.social.whatsapp)" class="navbar-brand nav-item__ico-social social-icon">
                  <i class="icon-whatsapp"></i>
                </a>
              </li>
              <li class="nav-item" *ngIf="agencyData.social.facebook">
                <a [href]="agencyData.social.facebook" class="navbar-brand nav-item__ico-social social-icon"
                  target="_blank">
                  <i class="icon-facebook"></i>
                </a>
              </li>
              <li class="nav-item" *ngIf="agencyData.social.twitter">
                <a [href]="agencyData.social.twitter" class="navbar-brand nav-item__ico-social social-icon"
                  target="_blank">
                  <i class="icon-twitter"></i>
                </a>
              </li>
              <li class="nav-item" *ngIf="agencyData.social.youtube">
                <a [href]="agencyData.social.youtube" class="navbar-brand nav-item__ico-social social-icon"
                  target="_blank">
                  <i class="icon-youtube"></i>
                </a>
              </li>
              <li class="nav-item" *ngIf="agencyData.social.instagram">
                <a [href]="agencyData.social.instagram" class="navbar-brand nav-item__ico-social social-icon"
                  target="_blank">
                  <i class="icon-instagram"></i>
                </a>
              </li>
              <li class="nav-item" *ngIf="agencyData.social.skype">
                <a [href]="agencyData.social.skype" class="navbar-brand nav-item__ico-social social-icon"
                  target="_blank">
                  <i class="icon-skype"></i>
                </a>
              </li>
            </ul>
          </section>

        </div>
      </div>
      <div class="row pb-3">
        <div class="col-12">
          <img *ngIf="brand?.networkId === networks.WTG && !isWTStore" src="./assets/images/welcome_logo_negative.png" height="40" alt="Welcome Travel Group">
          <img *ngIf="isWTStore" src="./assets/images/wtstore_logo_negative.png" height="40" alt="Welcome Travel Store">
        </div>
      </div>
    </div>
  </div>
</div>
