import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import sanitizeForUrl from '../../../utils/sanitizeForUrl';
import { ICruiseCard } from '../../booking-flow/cruise/cruise-search-page/interfaces/icruise-card';

@Pipe({
  name: 'cruiseDetailUrl'
})
export class CruiseDetailUrlPipe implements PipeTransform {

  transform(value: ICruiseCard): string {
    if (value && value.bestSolution) {
      const source = value.source;
      const flight = value.bestSolution.hasFlight ? 1 : 0;
      const flightString = flight ? 'SI' : 'NO';
      const packetDescription = sanitizeForUrl(value.title);
      const fromDate = dayjs(value.bestSolution.departureDate).format('DD-MM-YYYY');
      const toDate = dayjs(value.bestSolution.arrivalDate).format('DD-MM-YYYY');
      return `/crociere/dettaglio/${source}/${flight}/pacchetto/${packetDescription}/volo/${flightString}/dal/${fromDate}/al/${toDate}`;
    }
    return null;
  }

}
