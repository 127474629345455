import {Injectable} from '@angular/core';
import {PostMessageService} from './post-message.service';
import {Observable, of, throwError} from 'rxjs';
import {WidgetService} from './widget.service';
import {catchError, mergeMap, shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private _postMessageService: PostMessageService,
              private _widgetService: WidgetService) { }

  getCurrentHref(): Observable<string> {
    return this._widgetService.isWidget().pipe(
      mergeMap(e => e ? this._postMessageService.getHref() : of(location.href)),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }
}
