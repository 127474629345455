import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bus-stops-modal',
  templateUrl: './bus-stops-modal.component.html',
  styleUrls: ['./bus-stops-modal.component.scss']
})
export class BusStopsModalComponent implements OnInit {
  @Input() dataSrc;
  
  constructor() { }

  ngOnInit(): void {
  }

}
