<div class="dev-panel" *ngIf="showDevPanel">
  <div class="test-sites" *ngIf="isPanelVisible">
    <button *ngFor="let site of this.testSites" (click)="showTestSite(site.domain, site.isWidget, site.isWtg)" [ngClass]="{'is-disabled' : !site.isEnabled}" [disabled]="!site.isEnabled">
      {{site.label}}
    </button>
  </div>
  <div class="panel-switch" [ngClass]="{'is-visible' : isPanelVisible}" (click)="isPanelVisible = !isPanelVisible">
    <span *ngIf="isPanelVisible" class="g-icon-chevron-right hide"></span>
    <span *ngIf="!isPanelVisible" class="g-icon-chevron-left show"></span>
  </div>
</div>

<div class="top-bar-menu p-0" >
  <div class="container container-lg">
    <nav *ngIf="!isWtgNetworkSite && !isNetworkSite" class="navbar navbar-expand-lg p-0">
      <div class="top-bar-menu-agency" *ngIf="hasMultipleStores" [ngClass]="{'top-bar-menu-agency__full-width': isWTStore}">
        <swiper [config]="config" [(index)]="index">
          <div *ngFor="let agency of storeList"
               class="navbar-nav top-bar-menu__navbar top-bar-menu__navbar--contacts">
            <div class="nav-item">
              <div class="nav-link nav-item__link nav-item__link--branch">
                <i class="icon-pin"></i>
                <span class="nav-link__info">
                  <small *ngIf="!isWTShop">
                    {{((agency.isMaster && agency.isAgencySite) || (!agency.isMaster && agency.isAgencySite)) ? 'Sede principale' : 'Filiale'}} <span class="branch-name">{{(agency.city?.trim()) ? (' ' + agency.city?.trim()) : ''}}</span>
                  </small>
                  <small *ngIf="isWTShop">Sede:</small>
                  <span [ngClass]="{'branch-name' : isWTShop}" *ngIf="(agency.address?.trim() && agency.city?.trim()) || agency.province?.trim()">
                    {{agency.address.trim()}}, {{agency.city.trim()}}{{(agency.province?.trim()) ? ' (' + agency.province.trim() + ')' : ''}}
                  </span>
                </span>
              </div>
            </div>
            <div *ngIf="agency.email1?.trim() || agency.email2?.trim()"
                 class="nav-item">
              <a class="nav-link nav-item__link"
                 tabindex="-1"
                 [href]="'mailto:' + agency.email1.trim() || agency.email2.trim()"
                 aria-disabled="true">
                <i class="icon-email"></i>
                <span class="nav-link__info">
                  <small>Email <span class="colon">:</span></small>
                  <span>{{agency.email1.trim() || agency.email2.trim()}}</span>
                </span>
              </a>
            </div>
            <div *ngIf="agency.tel1?.trim() || agency.tel2?.trim()"
                 class="nav-item nav-item__link nav-item__link--red nav-item__link--bolded">
              <a [href]="'tel:' + agency.tel1.trim() || agency.tel2.trim()" class="nav-link nav-item__link">
                <i class="icon-phone"></i>
                <span class="nav-link__info">
                    <small>Tel <span class="colon">:</span></small>
                  <span>{{agency.tel1.trim() || agency.tel2.trim()}}</span>
                </span>
              </a>
            </div>
          </div>
        </swiper>
      </div>
      <div class="logo-geo py-1 d-none d-lg-block" *ngIf="!isNetworkSite && !isWTStore && brand?.networkId === networks.WTG">
        <span>Agenzia partner di:</span>
        <img *ngIf="brand?.networkId === networks.WTG" src="./assets/images/welcome_logo_negative.png" height="30" alt="Welcome Travel Group">
      </div>
    </nav>
  </div>
</div>
<div class="top-bar-menu p-0" *ngIf="brand?.networkId === networks.GEO && !isNetworkSite && !hasMultipleStores">
  <div class="container container-lg">
    <nav class="navbar navbar-expand-lg">
      <ul class="navbar-nav top-bar-menu__navbar top-bar-menu__navbar--contacts">
        <li class="nav-item nav-item__link nav-item__link--red nav-item__link--bolded">
          <a tabindex="-1"
             [href]="'mailto:' + (profile$ | async)?.email_1?.trim()"
             aria-disabled="true">
            <i class="icon-email"></i>
            <span>{{(profile$ | async)?.email_1?.trim()}}</span>
          </a>
        </li>
        <li class="nav-item nav-item__link nav-item__link--red nav-item__link--bolded">
          <a [href]="'tel:' + (profile$ | async)?.tel_1?.trim()">
            <i class="icon-phone"></i>
            <span>{{(profile$ | async)?.tel_1?.trim()}}</span>
          </a>
        </li>
      </ul>
      <ul class="navbar-nav top-bar-menu__navbar top-bar-menu__navbar--socials ms-auto">
        <li class="nav-item" *ngIf="(profile$ | async)?.whatsapp">
          <a target="_blank" [href]="sanitize((isMobile$ | async) ? 'whatsapp://send?phone=' + ((profile$ | async)?.whatsapp) : 'https://wa.me/' + ((profile$ | async)?.whatsapp))" class="navbar-brand nav-item__ico-social social-icon">
            <i class="icon-whatsapp"></i>
          </a>
        </li>
        <li class="nav-item" *ngIf="(profile$ | async)?.facebook?.trim()">
          <a [href]="(profile$ | async)?.facebook" class="navbar-brand nav-item__ico-social social-icon" target="_blank">
            <i class="icon-facebook"></i>
          </a>
        </li>
        <li class="nav-item" *ngIf="(profile$ | async)?.twitter?.trim()">
          <a [href]="(profile$ | async)?.twitter" class="navbar-brand nav-item__ico-social social-icon" target="_blank">
            <i class="icon-twitter"></i>
          </a>
        </li>
        <li class="nav-item" *ngIf="(profile$ | async)?.youtube?.trim()">
          <a [href]="(profile$ | async)?.youtube" class="navbar-brand nav-item__ico-social social-icon" target="_blank">
            <i class="icon-youtube"></i>
          </a>
        </li>
        <li class="nav-item" *ngIf="(profile$ | async)?.instagram?.trim()">
          <a [href]="(profile$ | async)?.instagram" class="navbar-brand nav-item__ico-social social-icon" target="_blank">
            <i class="icon-instagram"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
