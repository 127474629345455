import {Pipe, PipeTransform} from '@angular/core';
import {MappingService} from '../services/mapping.service';
import {Observable} from 'rxjs';
import {RemoteMappingItem} from '../interfaces/remote-mapping-item';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'formatPrefetch'
})
export class FormatPrefetchPipe implements PipeTransform {
  private _mappingTypes = {
    airports: 'getHolidayMappings',
    harbours: 'getCruiseMappings',
    ships: 'getCruiseMappings'
  };

  constructor(private _mappingService: MappingService) { }

  transform(value: (number | string)[], typology: 'airports' | 'harbours' | 'ships'): Observable<RemoteMappingItem[]> {
    return value ? this._mappingService[this._mappingTypes[typology]]().pipe(
      map(e => e[typology].objects),
      map(e => value.map(x => x === 'null' ? {Id: 'null', Name: 'Solo Soggiorno'} : e[x]).filter(x => x))
    ) : null;
  }

}
