import {Injectable} from '@angular/core';
import {MetaService} from './meta.service';
import {ProjectService} from './project.service';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {forkJoin, throwError} from 'rxjs';
import {LocationService} from './location.service';
import {EnvironmentService} from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private _metaService: MetaService,
              private _locationService: LocationService,
              private _environmentService: EnvironmentService,
              private _projectService: ProjectService) {
  }

  separator = ' - ';

  setCanonical = (canonical: string) => {
    this.getDefaultCanonical().subscribe(e => {
      this._metaService.changeOGCard('canonical', canonical);
      this._metaService.changeTWCard('canonical', canonical);
    });
  }

  setTitle = (title: string) => {
    this.getDefaultTitle().subscribe(e => {
      document.title = `${title}${this.separator}${e}`;
      this._metaService.changeOGCard('title', title);
      this._metaService.changeTWCard('title', title);
    });
  }

  setDescription = (description: string) => {
    // -- sanitizzo la stringa per evitare di stampare nel content della description meta, un pezzo di html completo di tag --
    const tmpDivElement = document.createElement('div');
    tmpDivElement.innerHTML = description;
    const sanitizedDescription = tmpDivElement.textContent || tmpDivElement.innerText || '';
    // -----------------------------------------------------------------------------------------------------------------------

    this._metaService.changeMeta('description', sanitizedDescription);
    this._metaService.changeOGCard('description', sanitizedDescription);
    this._metaService.changeTWCard('description', sanitizedDescription);
  }

  setImage = (image: string) => {
    this._metaService.changeOGCard('image', image);
    this._metaService.changeTWCard('image', image);
  }

  getDefaultTitle = () => {
    return this._projectService.getProfile().pipe(
      map(e => `${e.denominazione}${this.separator}Agenzia di viaggi ${e.citta}`),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getDefaultCanonical = () => {
    return this._projectService.getProfile().pipe(
      map(e => `${e.denominazione}${this.separator}Agenzia di viaggi ${e.citta}`),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getDefaultDescription = () => {
    return this._projectService.getProfile().pipe(
      map(e => `L'Agenzia Viaggi e Turismo ${e.denominazione}, a ${e.citta}, organizza viaggi di nozze, offerte, vacanze, biglietteria, tour e crociere in tutto il mondo!`),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  getDefaultKeyword = () => {
    return this._projectService.getProfile().pipe(
      map(e => `biglietterie aeree, biglietterie ferroviarie, biglietterie marittime, agenzia, crociere, viaggi di nozze, vacanze, tour, lowcost, organizzazione di soggiorni all'estero, ${e.citta}`),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => throwError(err))
    );
  }

  setDefaults = () => {
    forkJoin([
      this.getDefaultTitle(),
      this.getDefaultDescription(),
      this.getDefaultKeyword()
    ]).subscribe(e => {
      const [title, description, keyword] = e;
      document.title = title;
      this._metaService.changeMeta('description', description);
      this._metaService.changeMeta('keyword', keyword);
      this.setOGCard();
      this.setTWCard();
    });
  }

  public setOpenGraphTags(title: string, description: string, image: string, url: string): void {
    this._metaService.changeOGCard('title', title);
    this._metaService.changeOGCard('url', url);
    this._metaService.changeOGCard('description', description);
    this._metaService.changeOGCard('image', image);
  }

  setOGCard = (title?: string, siteName?: string, description?: string, image?: string, url?: string, type?: string, locale?: string) => {
    forkJoin([
      this._projectService.getProfile(),
      this.getDefaultTitle(),
      this.getDefaultDescription(),
      this._locationService.getCurrentHref()
    ]).subscribe(e => {
      console.log("SETOGCARD", e);
      const [profile$, title$, description$, href$] = e;
      locale = locale || 'it_IT';
      type = type || 'website';
      url = url || href$;
      image = image || profile$.logo;
      description = description || description$;
      siteName = siteName || title$;
      title = title || title$;
      this._metaService.setOGCard(title, siteName, description, image, url, type, locale);
    });
  }

  setTWCard = (title?: string, description?: string, image?: string, url?: string, card?: string, site?: string) => {
    forkJoin([
      this._projectService.getProfile(),
      this.getDefaultTitle(),
      this.getDefaultDescription(),
      this._locationService.getCurrentHref(),
      this._environmentService.getCurrentWebsite()
    ]).subscribe(e => {
      const [profile$, title$, description$, href$, website$] = e;
      card = card || 'summary';
      image = image || profile$.logo;
      description = description || description$;
      title = title || title$;
      url = url || href$;
      site = site || website$;
      this._metaService.setTWCard(title, description, image, url, card, site);
    });
  }

}
