<!--//TODO Componente attualmente utilizzato solo dalle cruise. Bisognerebbe spostarlo nel modulo corretto -->

<!-- <div class="booking-flow__container"> -->
<!-- <div class="booking-flow__title booking-flow__title-steps">
    <h3>Sistemazione</h3>
    <p>Inserisci i dati sul numero di passeggeri per personalizzare il tuo viaggio.</p>
  </div> -->
<!-- <hr class="dashed-border"> -->
<!-- <div class="booking-flow__content"> -->
<!-- <section class="booking-flow__section">
  <h2 class="booking-flow__section-title">Passeggeri</h2>
  <p class="booking-flow__section-description">Indica l'età dei passeggeri al momento della partenza.</p>

  <div class="booking-flow__wrapper"> -->

    <div class="booking-flow__guests">

      <form name="guestsAgeForm">
        <div class="booking-flow__group booking-flow__group-passengers">
          <div class="booking-flow__line">
            <div class="booking-flow__counter">
              <label class="col-form-label booking-flow__label">Viaggiatori
                <!--<span>(da 18 anni)</span>-->
              </label>
              <div class="spinner-counter">
                <span class="spinner-counter__btn" [ngClass]="{'disabled': occupancy.guests <= 1}"
                  (click)="removeGuest()">
                  <i class="icon-minus"></i>
                </span>
                <input class="spinner-counter__count" name="adultsNumber" [(ngModel)]="occupancy.guests" readonly>
                <span class="spinner-counter__btn" [ngClass]="{'disabled': getTotalGuestsNumber() >= maxGuests}"
                  (click)="addGuest()">
                  <i class="icon-plus"></i>
                </span>
              </div>
            </div>
            <div class="booking-flow__age" *ngIf="occupancy.guests > 0">
              <div class="booking-flow__input">
                <div class="form-group form-group-custom"
                  *ngFor="let age of occupancy.ages; let i = index; trackBy: agesTrackBy">
                  <label [for]="'guest_age_'+i" class="form-group-custom__label">Ospite {{i + 1}}
                    <span>Anni (non compiuti)</span>
                  </label>
                  <ng-select [clearable]="false" class="form-ng-select" [(ngModel)]="occupancy.ages[i]"
                    [name]="'guest_age_'+i" placeholder="Seleziona eta'" [items]="agesSelect"></ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  <!-- </div>
</section> -->
<!-- </div> -->
<!-- </div> -->