import { Component, OnInit } from '@angular/core';
import {ProjectService} from '../../services/project.service';
import {Observable} from 'rxjs';
import {IRemoteProfile} from '../../interfaces/iremote-profile';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent implements OnInit {

  constructor(private _projectService: ProjectService) { }

  profile$: Observable<IRemoteProfile>;

  ngOnInit(): void {
    this.profile$ = this._projectService.getProfile();
  }

}
