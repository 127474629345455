import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from 'src/app/services/modal.service';

@Component({
  selector: 'image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {
  @Input() dataSrc;

  config = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
    }
  };
  configThumbs = {
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slidesPerView: 1,
    width: 96,
    height: 64
  };
  index;

  constructor(
    private _modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.index = this.dataSrc.elements?.indexOf(this.dataSrc.selectedElement) || 0;
  }

  changeSlide(image: any): void {
    this.index = this.dataSrc.elements.indexOf(image);
  }
}
