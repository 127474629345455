export enum ProjectTypes {
  GEO_BASIC = 'geo_basic',
  GEO_BASIC_WIDGET = 'geo_basic_widget',
  GEO_ADVANCED = 'geo_advanced',
  GEO_ADVANCED_WIDGET = 'geo_advanced_widget',
  WTG_BASIC = 'wtg_basic',
  WTG_BASIC_WIDGET = 'wtg_basic_widget',
  WTG_ADVANCED = 'wtg_advanced',
  WTG_ADVANCED_WIDGET = 'wtg_advanced_widget',
}
