export enum Networks {
  WTG_MASTER = 12,
  WTG = 16,
  GEO = 46
}

export enum WTGNetworkSite {
  CLIENT_ID = 10568,
  EXTERNAL_ID = 19304
}

export const NetworkConfigs = {
  16 : {
    networkId: 16,
    network: "WTG",
    bodyClass: "brand-wtg",
    cssFile: "brand-wtg.css",
    detailPageConfig: {
      showPriceGrid: true
    }
  },
  46 :  {
    networkId: 46,
    network: "GEO",
    bodyClass: "brand-geo",
    cssFile: "brand-geo.css",
    detailPageConfig: {
      showPriceGrid: false
    }
  }
}
