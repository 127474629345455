import { noFlight, withBus, withFlight, withHarbours } from '../modules/booking-flow/holiday/holiday-search-page/helpers/holiday-types';

export default function getHolidayTypesByAirports(airports: any[]): number[] {
  const holidayTypeList: number[] = [];
  if (!airports || airports.length === 0 || airports.includes('null')) {
    holidayTypeList.push(...noFlight);
  }
  if (airports && airports.length > 0 && airports.some(e => !isNaN(e))) {
    const checkHarbours = airports.find(t => t > 2000000 && t < 3000000);
    const checkAirports = airports.find(t => t > 1000000 && t < 2000000);
    const checkBus = airports.find(t => t < 1000000);

    if (checkHarbours || checkAirports || checkBus) {
      if (checkHarbours) {
        holidayTypeList.push(...withHarbours);
      }
      if (checkAirports) {
        holidayTypeList.push(...withFlight);
      }
      if (checkBus) {
        holidayTypeList.push(...withBus);
      }
    } else {
      holidayTypeList.push(...withFlight);
      holidayTypeList.push(...withHarbours);
      holidayTypeList.push(...withHarbours);
    }
  }
  return holidayTypeList;
}
